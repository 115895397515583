import {
  validateErrors,
  required,
  maxLength,
} from '../validators';


const validate = (values) => {
  const validators = {
    title: [maxLength(200), required],
    message: [maxLength(2000), required],
  };
  return validateErrors(values, validators);
};

export default validate;
