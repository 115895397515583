import {
  GET_HIRING_TEMPLATES_STATES_SUCCESS,
  CREATE_HIRING_STATE_SUCCESS,
  EDIT_HIRING_STATE_SUCCESS,
  DELETE_HIRING_STATE_SUCCESS,
} from '../constants/hiringTemplateConst';
import { createReducer } from '../utils/reducerUtils';


const initialState = [];

const getHiringTemplatesStates = (state, action) => action.hiringTemplatesStates;
const createHiringTemplateState = (state, action) => action.hiringTemplatesStates.states;
const editHiringTemplateState = (state, action) => action.hiringTemplatesStates.states;
const deleteHiringTemplateState = (state, action) => action.hiringTemplatesStates.states;

export default createReducer(initialState, {
  [GET_HIRING_TEMPLATES_STATES_SUCCESS]: getHiringTemplatesStates,
  [CREATE_HIRING_STATE_SUCCESS]: createHiringTemplateState,
  [EDIT_HIRING_STATE_SUCCESS]: editHiringTemplateState,
  [DELETE_HIRING_STATE_SUCCESS]: deleteHiringTemplateState,
});
