import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPencilAlt,
  faTrashAlt,
  faUser,
  faMinus,
  faPlus,
  faFont,
  faPalette,
  faFileAlt,
  faBullhorn,
  faCog,
  faBriefcase,
  faTag,
  faCheck,
  faTimes,
  faShare,
  faCheckSquare,
  faSignOutAlt,
  faUndo,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faQuestionCircle,
  faFilter,
  faEllipsisV,
  faCopy,
  faExchangeAlt,
  faArchive,
  faCrown,
  faUserCog,
  faSearch,
  faCalendar,
  faCheckCircle,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';


library.add(
  faAngleDoubleRight,
  faCheckCircle,
  faCalendar,
  faUserCog,
  faCrown,
  faPencilAlt,
  faTrashAlt,
  faUser,
  faMinus,
  faPlus,
  faFont,
  faPalette,
  faFileAlt,
  faBullhorn,
  faCog,
  faBriefcase,
  faTag,
  faCheck,
  faTimes,
  faShare,
  faCheckSquare,
  faSignOutAlt,
  faUndo,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faArrowLeft,
  faQuestionCircle,
  faFilter,
  faEllipsisV,
  faCopy,
  faExchangeAlt,
  faArchive,
  faSearch,
);
