import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';


class StyledSelect extends Component {
  state = {
    menuIsOpen: false,
  };

  handleOnMenuOpen = () => {
    this.setState({ menuIsOpen: true });
  };

  handleOnMenuClose = () => {
    this.setState({ menuIsOpen: false });
  };

  componentDidUpdate = () => {
    const { menuIsOpen } = this.state;
    const { isDragging, isDisabled } = this.props;
    if (menuIsOpen && (isDragging || isDisabled)) {
      this.handleOnMenuClose();
    }
  }

  render() {
    const { menuIsOpen } = this.state;
    const { className } = this.props;
    return (
      <Select
        {...this.props}
        className={`${className} ${menuIsOpen ? 'react-select-menu-is-open' : 'react-select-menu-is-close'}`}
        menuIsOpen={menuIsOpen}
        classNamePrefix="react-select"
        onMenuOpen={this.handleOnMenuOpen}
        onMenuClose={this.handleOnMenuClose}
        noOptionsMessage={() => ('Ничего не найдено')}
      />
    );
  }
}

StyledSelect.defaultProps = {
  className: '',
  isDragging: false,
};

StyledSelect.propTypes = {
  className: PropTypes.string,
  isDragging: PropTypes.bool,
};

export default StyledSelect;
