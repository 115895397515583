import {
  GET_ALL_VACANCIES_SUCCESS,
  GET_VACANCY_SUCCESS,
} from '../constants/vacanciesConst';
import { createReducer } from '../utils/reducerUtils';


const getAllVacancies = (state, action) => ({ ...state, vacancies: [...action.allVacancies] });

const getVacancy = (state, action) => ({ ...state, activeVacancy: { ...action.activeVacancy } });

const initialState = {};

export default createReducer(initialState, {
  [GET_ALL_VACANCIES_SUCCESS]: getAllVacancies,
  [GET_VACANCY_SUCCESS]: getVacancy,
});
