import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import FieldError from '../../components/FieldError';
import StyledAsyncSelect from '../../components/StyledAsyncSelect';


const renderAsyncSelect = (
  {
    input: { value, onChange, onBlur },
    meta: { touched, error },
    label,
    placeholder,
    componentName,
    noOptionsMessage,
    defaultOptions,
    loadOptions,
    isDisabled,
  },
) => (
  <FormGroup>
    <Label>{label}</Label>
    <StyledAsyncSelect
      onChange={onChange}
      onInputChange={value => value && onChange(value)}
      onBlur={() => onBlur(value)}
      inputValue={value && value.value}
      placeholder={placeholder}
      loadOptions={loadOptions}
      defaultValue={value && value.value}
      defaultOptions={defaultOptions}
      name={componentName}
      noOptionsMessage={() => noOptionsMessage}
      isDisabled={isDisabled}
      blurInputOnSelect={false}
    />
    {touched && error && <FieldError error={error} />}
  </FormGroup>
);

renderAsyncSelect.defaultProps = {
  noOptionsMessage: 'Не найдено',
  defaultOptions: [],
  isDisabled: false,
};

renderAsyncSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
  noOptionsMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultOptions: PropTypes.arrayOf(PropTypes.object),
  loadOptions: PropTypes.func.isRequired,
};

export default renderAsyncSelect;
