import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';

import ModalWindow from './ModalWindow';


const TariffsPage = ({ teams }) => {
  const paymentLinkWithTeams = (
    <div className="test">
      <LinkContainer to="/tariffs/payment">
        <button
          type="button"
          className="button button_size_sm button_color_dark-blue tariff__button"
        >
          Перейти к оплате
        </button>
      </LinkContainer>
      <div className="link-container">
        <Link to="/tariffs/payment" className="link">Применить промокод</Link>
      </div>
    </div>
  );

  const paymentLinkWithoutTeams = (
    <div className="test">
      <button
        type="button"
        className="button button_size_sm button_color_dark-blue tariff__button"
      >
        Перейти к оплате
      </button>
      <div className="link-container">
        <a href="#" className="link">Применить промокод</a>
      </div>
    </div>
  );

  const paymentLinks = (Object.keys(teams).length)
    ? (
      paymentLinkWithTeams
    ) : (
      <ModalWindow
        headerText="Чтобы оплатить тариф вам необходимо создать компанию"
        modalButton={paymentLinkWithoutTeams}
        linkButtonText={<button type="button" className="button">Перейти к созданию компании</button>}
        linkButtonAddress="/companies"
      >
        {}
      </ModalWindow>
    );

  return (
    <div className="tariffs-page section-grey">
      <Helmet>
        <title>Тарифы | Jobs Widget</title>
      </Helmet>
      <div className="page-header-container">
        <h1 className="main-page-title">Тарифы</h1>
      </div>
      <div className="tariffs-container">
        <div className="content-container content-container_for-page-tariffs tariff">
          <div className="content-container__top-line" />
          <div className="title tariff__title">Базовый</div>
          <ul className="list">
            <li className="list__item">Бесплатно</li>
            <li className="list__item">3 вакансии</li>
            <li className="list__item">Неограниченный по времени тариф</li>
            <li className="list__item">Гибкая настройка виджета</li>
            <li className="list__item">Оповещения об откликах</li>
          </ul>
        </div>
        <div className="content-container content-container_for-page-tariffs tariff">
          <div className="content-container__top-line content-container__top-line_color_red" />
          <div className="title tariff__title">Бизнес</div>
          <ul className="list tariff__features-list">
            <li className="list__item">Все опции базового тарифа</li>
            <li className="list__item">Неограниченное количество вакансий</li>
            <li className="list__item">Приоритетная техническая поддержка</li>
          </ul>
          <div className="item-text item-text_color_grey tariff__subtitle">Стоимость</div>
          <div className="tariff__payment-option-container">
            <div className="tariff__payment-option">
              <div className="tariff__price-container">
                <span className="tariff__price">490</span>
                <span className="tariff__currency">&#8381;</span>
              </div>
              <p className="tariff__payment-option-text">
                при оплате
                <br />
                за каждый месяц
              </p>
            </div>
            <div className="tariff__payment-option">
              <div className="tariff__price-container">
                <span className="tariff__price">4900</span>
                <span className="tariff__currency">&#8381;</span>
              </div>
              <p className="tariff__payment-option-text">при оплате за год</p>
            </div>
          </div>
          {paymentLinks}
        </div>
      </div>
    </div>
  );
};

TariffsPage.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
};


const mapStateToProps = (state) => {
  const {
    entities: { teams },
  } = state;
  return {
    teams,
  };
};


export default connect(mapStateToProps, null)(TariffsPage);
