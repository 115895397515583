import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSalaryText } from '../utils';


class AllVacanciesItem extends Component {
  constructor(props) {
    super(props);
    this.x = createRef();
    this.state = { ellipsis: false };
  }

  componentDidMount() {
    if (this.x.current.clientHeight > 60) {
      this.setState({ ellipsis: true });
    }
  }

  render() {
    const {
      vacancy: {
        city,
        title,
        team_title,
        currency,
        id,
        publish_date,
        salary_from,
        description,
        salary_to,
      },
    } = this.props;

    const {
      ellipsis,
    } = this.state;

    const vacancyPublishDate = new Date(publish_date);
    const date = vacancyPublishDate.toLocaleString('ru', { day: 'numeric', month: 'long' });

    return (
      <div className="col-12 allvacancy-item-wrapper p-4">
        <div className="allvacancy-item-title-wrapper">
          <Link target="_blank" to={`/all-vacancies/${id}`}>
            <h2 className="allvacancy-item-title vacancy-item-title">{`${title}`}</h2>
          </Link>
          <span className="allvacancy-item-salary text-color-dark-blue">
            {getSalaryText(salary_from, salary_to, currency)}
          </span>
        </div>
        <div className="mb-2">
          {city && (<p className="mb-1 text-color-dark-blue">{city}</p>)}
          <p className="m-0 text-color-dark-blue">{`${team_title}`}</p>
        </div>
        <div className="allvacancy-item-desc-wrapper d-flex">
          <div className="allvacancy-item-desc text-color-dark-blue" ref={this.x}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {ellipsis
              && (
              <Link target="_blank" to={`/all-vacancies/${id}`} className="allvacancy-item-desc-ellipsis ">
                    &nbsp;
              </Link>
              )
            }
          </div>
          <div className="allvacancy-item-date text-color-dark-blue col-2 p-0">
            {date}
          </div>
        </div>
      </div>
    );
  }
}

AllVacanciesItem.propTypes = {
  vacancy: PropTypes.object.isRequired,
};

export default AllVacanciesItem;
