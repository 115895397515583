import {
  DESTROY_SELECT,
  INITIALIZE_SELECT,
  RESET_SELECTION,
  TOGGLE_SELECTION,
} from '../constants/selectionConst';


export const toggleSelection = (id, name) => ({
  type: TOGGLE_SELECTION,
  id,
  name,
});

export const resetSelection = name => ({
  type: RESET_SELECTION,
  name,
});

export const initializeSelect = name => ({
  type: INITIALIZE_SELECT,
  name,
});

export const destroySelect = name => ({
  type: DESTROY_SELECT,
  name,
});
