import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/paste';


const textAreaStyles = `
  #tinymce {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    color: #354169;
    background-color: transparent;
  }`;

const HTMLTextArea = (
  {
    initialValue,
    name,
    onChange,
    onBlur,
  },
) => (
  <Editor
    style={textAreaStyles}
    initialValue={initialValue}
    init={{
      plugins: 'code fullscreen lists link textcolor colorpicker paste',
      toolbar:
        'undo redo | bold italic underline strikethrough '
        + 'fontsizeselect | numlist bullist | forecolor backcolor removeformat | link code | fullscreen ',
      language: 'ru',
      language_url: '/static/js/lang/ru.js',
      content_style: textAreaStyles,
      paste_auto_cleanup_on_paste: true,
      paste_as_text: true,
    }}
    onChange={e => onChange(e, name)}
    onBlur={onBlur}
    textareaName={name}
  />
);

HTMLTextArea.propTypes = {
  initialValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default HTMLTextArea;
