import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import FieldError from '../../components/FieldError';
import Tooltip from '../../components/Tooltip';


const renderField = (
  {
    input,
    meta: { touched, error },
    label,
    tooltipText,
    placeholder,
    ...rest
  },
) => (
  <FormGroup>
    <Label>{label}</Label>
    {tooltipText && <Tooltip text={tooltipText} />}
    <Input
      {...input}
      {...rest}
      placeholder={placeholder || label}
    />
    {touched && error && <FieldError error={error} />}
  </FormGroup>
);

renderField.defaultProps = {
  placeholder: '',
};

renderField.propType = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
};

export default renderField;
