import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalWindow from '../components/ModalWindow';
import EditHhId from '../forms/EditHhIdForm';
import { editHhId } from '../actions/teams';


class HhModal extends Component {
  modalWindow = React.createRef();

  componentDidUpdate(prevProps) {
    const { editHhIdStatus } = this.props;
    if (prevProps.editHhIdStatus === 'request' && editHhIdStatus === 'success') {
      this.closeModalWindow();
    }
  }

  closeModalWindow = () => {
    this.modalWindow.current.close();
  };

  handleSubmit = ({ hh_employer_url }) => {
    const { editHhId, teamId } = this.props;
    const hhEmployerId = hh_employer_url.match(/\d+\/?$/g)[0];
    if (hhEmployerId) {
      editHhId(teamId, hhEmployerId);
    }
  };

  render() {
    const { hh_employer_url, triggerType, triggerText } = this.props;
    const button = triggerType === 'button'
      ? (
        <button type="button" className="button button_size_sm button_color_dark-blue">
          {triggerText}
        </button>
      ) : (<span>{triggerText}</span>);

    return (
      <ModalWindow
        headerText="Настроить импорт с hh.ru"
        modalButton={button}
        ref={this.modalWindow}
      >
        <EditHhId
          onSubmit={this.handleSubmit}
          initialValues={{ hh_employer_url }}
        />
      </ModalWindow>
    );
  }
}

HhModal.defaultProps = {
  editHhIdStatus: '',
  triggerType: 'button',
  triggerText: 'Импорт с hh.ru',
};

HhModal.propTypes = {
  editHhId: PropTypes.func.isRequired,
  hh_employer_url: PropTypes.string.isRequired,
  editHhIdStatus: PropTypes.string,
  teamId: PropTypes.number.isRequired,
  triggerType: PropTypes.string,
  triggerText: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { teams },
    api: { editHhIdStatus },
  } = state;
  const { teamId } = ownProps;
  return {
    hh_employer_url: teams[teamId]
      && teams[teamId].hh_employer_id
      && `https://hh.ru/employer/${teams[teamId].hh_employer_id}`,
    editHhIdStatus,
  };
};

const mapDispatchToProps = dispatch => ({
  editHhId: (teamId, hhEmployerId) => dispatch(editHhId(teamId, hhEmployerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HhModal);
