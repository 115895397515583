import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import TooltipComponent from './TooltipComponent';


class Tooltip extends PureComponent {
  render() {
    const {
      text,
      className,
      wrapperClassName,
      toolTipWindowSize,
      toolTipArrowPlacement,
    } = this.props;
    return (
      <TooltipComponent
        name={`tooltip-${uuid().slice(0, 8)}`}
        text={text}
        className={className}
        wrapperClassName={wrapperClassName}
        toolTipWindowSize={toolTipWindowSize}
        toolTipArrowPlacement={toolTipArrowPlacement}
      />
    );
  }
}

Tooltip.defaultProps = {
  className: '',
  wrapperClassName: '',
  toolTipWindowSize: 'default',
  toolTipArrowPlacement: 'top',
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  toolTipWindowSize: PropTypes.string,
  toolTipArrowPlacement: PropTypes.string,
};

export default Tooltip;
