import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Collapse, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ResponsesList from '../components/ResponsesList';
import ResponsesPageFilterContainer from '../components/ResponsesPageFilterContainer';
import { deleteResponses, loadResponses, markResponseAsViewed } from '../actions/responses';
import history from '../routes/history';
import Breadcrumbs from '../components/Breadcrumbs';
import NotFoundPage from '../components/NotFoundPage';
import { resetSelection } from '../actions/selection';
import getManagersFromResponse from '../utils/reportUtils';
import ResponsesTitleBlock from '../components/ResponsesTitleBlock';
import { sourceTypeTranslator } from '../utils/responsesUtils';


class ResponsesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      searchInput: '',
      searchInputDelay: null,
    };
  }

  componentDidMount() {
    const {
      teamId,
      searchParams: { pageNumber },
    } = this.props;
    if (teamId) {
      this.getResponses(pageNumber);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchParams,
      vacancyId,
    } = this.props;

    const {
      searchParams: prevSearchParams,
      vacancyId: prevVacancyId,
    } = prevProps;

    const {
      searchInput,
    } = this.state;

    const isFiltersUnchanged = Object.keys(searchParams).every(
      filterName => searchParams[filterName] === prevSearchParams[filterName],
    );

    const { pageNumber } = searchParams;
    if (!isFiltersUnchanged
      || vacancyId !== prevVacancyId) {
      this.getResponses(pageNumber);
    }

    if (prevState.searchInput !== searchInput) {
      const { searchInputDelay } = this.state;

      if (searchInputDelay) {
        clearTimeout(searchInputDelay);
      }

      const inputDelay = setTimeout(() => {
        this.getResponses();
      }, 500);

      this.onSearchDelayUpdate(inputDelay);
    }
  }

  onShowFilterChange = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  onSearchDelayUpdate = (newTimeout) => {
    this.setState({ searchInputDelay: newTimeout });
  }

  getResponses = (pageNumbers) => {
    const { teamId, loadResponses } = this.props;
    if (teamId) {
      loadResponses(teamId, this.getFilters(), pageNumbers);
    }
  }

  getFilters() {
    const {
      searchParams: {
        status,
        viewed,
        unViewed,
        hasCv,
        noCv,
        manager,
        date_to,
        date_from,
        source,
        internal_source,
        vacancy,
      },
    } = this.props;

    const { searchInput } = this.state;
    const isReportPageType = !!date_to;
    const filters = {};

    if (searchInput) {
      filters.substring = searchInput;
    }

    if (!viewed || !unViewed) {
      filters.users_who_viewed__isnull = unViewed;
    }

    if (!hasCv || !noCv) {
      filters.has_cv = hasCv;
    }

    if (isReportPageType) {
      if (status && status !== 'created') {
        filters.response_changes__status__id = status;
        filters.response_changes__is_outdated = false;
      }
    } else if (status) {
      filters.status__id = status;
    }

    if (manager) {
      if (status === 'created') {
        filters.creator__id = manager;
      } else {
        filters.response_changes__user__id = manager;
        filters.response_changes__is_outdated = false;
      }
    }

    if (source && source !== 'created') {
      if (source === 'null') {
        filters.source = null;
      } else {
        filters.source = source;
      }
    }

    if (internal_source) {
      filters.internal_source = internal_source;
    }

    if (vacancy !== 0) {
      filters.vacancy__id = vacancy;
    }

    if (date_to) {
      filters.created__date__lte = date_to;
    }

    if (date_from) {
      filters.created__date__gte = date_from;
    }

    return filters;
  }

  handleFilterChange = (vacancyId, searchParams) => {
    const { match: { params: { shortId } } } = this.props;
    if (vacancyId) {
      history.push({ pathname: `/teams/${shortId}/vacancies/${vacancyId}/responses`, search: `?${searchParams}` });
    } else {
      history.push({ pathname: `/teams/${shortId}/vacancies/responses`, search: `?${searchParams}` });
    }
  };

  onFilterChange = (event) => {
    const { target: { value } } = event;
    this.setState({ searchInput: value });
    resetSelection('selectedResponses');
  };

  getSourceName = (source, internal_source) => {
    const { sources } = this.props;

    if (source && source !== 'null') {
      return sources.find(({ id }) => id === Number(source)).name;
    }

    if (internal_source && internal_source.length === 1) {
      const [vacancyType] = internal_source;
      return sourceTypeTranslator[vacancyType];
    }

    if (source === null && internal_source === null) {
      return null;
    }

    return 'Не указано';
  }

  render() {
    const {
      vacancies,
      vacancyId,
      location,
      match: {
        params: { shortId },
      },
      hiringTemplatesStates,
      teamId,
      searchParams,
      resetSelection,
      deleteResponses,
      markResponseAsViewed,
      totalAmount,
      responsesSizePerPage,
    } = this.props;

    const {
      date_from = null,
      date_to = null,
      pageNumber = 1,
    } = searchParams;

    const { showFilter } = this.state;
    const { responses } = this.props;
    const isReportPageType = date_from && true;

    if (vacancyId && !vacancies.some(vacancy => vacancy.id === vacancyId)) {
      return <NotFoundPage />;
    }

    let titleInformation = {};
    let filteredResponses = [];

    if (!isReportPageType) {
      filteredResponses = responses;
      titleInformation = {
        isReportPageType,
        onShowFilterChange: this.onShowFilterChange,
        showFilter,
        shortId,
      };
    } else {
      const {
        searchParams: {
          status = null,
          manager = null,
          source = null,
          internal_source = null,
          vacancy = null,
        },
      } = this.props;

      const statusNameFromQuery = status === 'created' ? status : hiringTemplatesStates.find(
        item => item.id === +status,
      );

      filteredResponses = responses
        .filter(({ vacancy }) => (
          !vacancies.find(({ id }) => id === vacancy).hidden));

      const managers = getManagersFromResponse(responses);
      const managerInfo = manager && (managers.find(({ id }) => id === manager));
      const vacancyInfo = vacancy && (vacancies.find(({ id }) => id === vacancy));
      const sourceInfo = this.getSourceName(source, JSON.parse(internal_source));

      titleInformation = {
        vacancyInfo,
        isReportPageType,
        statusNameFromQuery,
        manager,
        managerInfo,
        date_from,
        date_to,
        sourceInfo,
      };
    }

    const filteredVacancies = vacancyId ? vacancies.filter(vacancy => vacancy.id === vacancyId) : vacancies;
    const vacancy = vacancies.find(vacancy => vacancy.id === vacancyId);

    const breadcrumbs = [
      { title: 'Вакансии', link: `/teams/${shortId}/vacancies` },
    ];
    if (vacancy) {
      breadcrumbs.push(
        { title: 'Отклики', link: `/teams/${shortId}/vacancies/responses` },
        { title: vacancy.title, link: '' },
      );
    } else if (isReportPageType) {
      breadcrumbs.push(
        { title: 'Отклики', link: `/teams/${shortId}/vacancies/responses` },
        { title: 'Отчётность', link: '' },
      );
    } else {
      breadcrumbs.push(
        { title: 'Отклики', link: '' },
      );
    }

    const title = vacancy ? `Отклики ${vacancy.title} | Jobs Widget` : 'Отклики | Jobs Widget';
    const filters = this.getFilters();
    return (
      <div className="responses-wrap section-grey">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="page-header-container">
          <ResponsesTitleBlock titleInformation={titleInformation} />
        </div>
        <Collapse
          isOpen={showFilter}
        >
          <ResponsesPageFilterContainer
            responses={responses}
            vacancies={vacancies}
            handleFilterChange={this.handleFilterChange}
            hiringTemplatesStates={hiringTemplatesStates.filter(template => template.team === teamId)}
            vacancyId={vacancyId}
            searchParams={searchParams}
          />
        </Collapse>
        <label className="search-response-container">
          <FontAwesomeIcon
            className="item-icon"
            icon="search"
          />
          <Input
            name="response_filter"
            className="search-response__input"
            onChange={this.onFilterChange}
            type="text"
            placeholder="Поиск"
          />
        </label>
        <ResponsesList
          items={filteredResponses}
          vacancies={filteredVacancies}
          responsesSizePerPage={responsesSizePerPage}
          location={location}
          resetSelection={resetSelection}
          deleteResponses={deleteResponses}
          filters={filters}
          pageNumber={pageNumber}
          markResponseAsViewed={markResponseAsViewed}
          totalAmount={totalAmount}
          teamId={teamId}
        />
      </div>
    );
  }
}

ResponsesPage.defaultProps = {
  vacancies: [],
};

ResponsesPage.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  vacancyId: PropTypes.number.isRequired,
  hiringTemplatesStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchParams: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
  resetSelection: PropTypes.func.isRequired,
  deleteResponses: PropTypes.func.isRequired,
  markResponseAsViewed: PropTypes.func.isRequired,
  loadResponses: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired,
  responsesSizePerPage: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    app: { activeTeam, settings: { page_sizes: { responses: responsesSizePerPage } } },
    sources,
    entities: { teams },
    responses: { responses = [], totalAmount = 0 },
    hiringTemplatesStates,
  } = state;

  const team = teams[activeTeam] || {};
  const { location } = ownProps;
  const { vacancies } = team;
  const vacancyId = +ownProps.match.params.vacancyId || 0;
  const locationSearch = new URLSearchParams(location.search);
  const searchParams = {};
  searchParams.status = locationSearch.get('status') || 0;
  searchParams.viewed = locationSearch.get('viewed') === null || locationSearch.get('viewed') === 'true';
  searchParams.unViewed = locationSearch.get('un_viewed') === null || locationSearch.get('un_viewed') === 'true';
  searchParams.hasCv = locationSearch.get('has_cv') === null || locationSearch.get('has_cv') === 'true';
  searchParams.noCv = locationSearch.get('no_cv') === null || locationSearch.get('no_cv') === 'true';
  searchParams.date_from = locationSearch.get('date_from') || null;
  searchParams.size = locationSearch.get('size') || null;
  searchParams.date_to = locationSearch.get('date_to') || null;
  searchParams.manager = +locationSearch.get('manager') || null;
  searchParams.source = locationSearch.get('source') || null;
  searchParams.internal_source = locationSearch.get('internal_source') || null;
  searchParams.pageNumber = +locationSearch.get('page') || 1;
  searchParams.vacancy = +locationSearch.get('vacancy') || vacancyId;

  return {
    sources,
    responses,
    vacancies,
    vacancyId,
    hiringTemplatesStates,
    searchParams,
    teamId: team.id,
    totalAmount,
    responsesSizePerPage,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadResponses: (teamId, filters, pageNumber) => dispatch(loadResponses(teamId, filters, pageNumber)),
  resetSelection: name => dispatch(resetSelection(name)),
  deleteResponses: (teamId, response, vacancyId, filters = ownProps.location.search) => dispatch(
    deleteResponses(teamId, response, vacancyId, filters),
  ),
  markResponseAsViewed: (teamId, response) => dispatch(markResponseAsViewed(teamId, response)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesPage);
export { ResponsesPage as UnconnectedResponsesPage };
