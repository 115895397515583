import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { deleteHiringTemplate } from '../actions/teams';
import HiringTemplatesList from '../components/HiringTemplatesList';


const HiringTemplatesPage = (props) => {
  const {
    templates,
    deleteHiringTemplate,
    defaultTemplate,
    location,
    teamId,
    match: { params: { shortId } },
  } = props;

  return (
    <div className="field-sets-wrap section-grey">
      <Helmet>
        <title>Настройка найма | Jobs Widget</title>
      </Helmet>
      <div className="page-header-container">
        <h1 className="main-page-title">Сценарии найма</h1>
        <Link to={`/teams/${shortId}/templates/create`}>
          <button type="button" className="button button_plus">
            <span className="button-icon">+</span>
            <span className="button-text">Создать сценарий</span>
          </button>
        </Link>
      </div>
      <HiringTemplatesList
        items={templates}
        teamId={teamId}
        deleteHiringTemplate={deleteHiringTemplate}
        defaultTemplate={defaultTemplate}
        location={location}
      />
    </div>
  );
};

HiringTemplatesPage.defaultProps = {
  templates: [],
  defaultTemplate: null,
  teamId: null,
};

HiringTemplatesPage.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object),
  deleteHiringTemplate: PropTypes.func.isRequired,
  defaultTemplate: PropTypes.number,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  teamId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    app: { activeTeam },
    entities: { teams },
  } = state;
  const team = teams[activeTeam] || {};
  return {
    teamId: activeTeam,
    templates: team.hiring_templates,
    defaultTemplate: team.default_hiring_template,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteHiringTemplate: (teamId, template) => dispatch(deleteHiringTemplate(teamId, template)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HiringTemplatesPage),
);
