import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';


const renderTeamNameField = (
  {
    input,
    meta: { asyncValidating, touched, error },
    label,
    type,
  },
) => (
  <FormGroup>
    <div className={asyncValidating ? 'async-validating' : ''}>
      <Input
        {...input}
        type={type}
        placeholder={label}
        autoFocus
      />
      {touched && error && <span className="render-field-error">{error}</span>}
    </div>
  </FormGroup>
);

renderTeamNameField.propType = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default renderTeamNameField;
