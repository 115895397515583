import { createReducer, updateObject } from '../utils/reducerUtils';
import {
  GET_ALL_TEAMS_SUCCESS,
} from '../constants/teamConst';
import {
  CHECK_USER_AUTHORIZATION_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  LOGIN_USER_SUCCESS,
} from '../constants/appConst';


const updateUser = (state, id, updates) => ({
  ...state,
  [id]: {
    ...state[id],
    ...updates,
  },
});

const extractUsers = (state, action) => updateObject(state, action.teams.entities.users);

const editProfileSuccess = (state, action) => updateUser(state, action.user.id, { ...action.user });

const updateAuthorizedUser = (state, action) => updateUser(state, action.user.id, { ...action.user });

const updateLoggedInUser = (state, action) => updateUser(state, action.data.user.id, { ...action.data.user });

const initialState = {};

const usersReducer = createReducer(initialState, {
  [GET_ALL_TEAMS_SUCCESS]: extractUsers,
  [EDIT_PROFILE_SUCCESS]: editProfileSuccess,
  [CHECK_USER_AUTHORIZATION_SUCCESS]: updateAuthorizedUser,
  [LOGIN_USER_SUCCESS]: updateLoggedInUser,
});

export default usersReducer;
