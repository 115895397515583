import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getCountries } from '../actions/app';
import { editVacancy } from '../actions/teams';
import EditVacancyForm from '../forms/EditVacancyForm';
import Breadcrumbs from '../components/Breadcrumbs';
import getAddressOptionsFromTeam from '../utils/vacancyUtils';


class EditVacancy extends Component {
  constructor(props) {
    super(props);
    const { vacancy } = this.props;
    this.state = {
      isAddAddress: false,
      currentImage: vacancy && vacancy.logo,
    };
    this.fileRef = React.createRef();
  }

  componentDidMount() {
    const { getCountries, countries } = this.props;
    if (!countries.length) {
      getCountries();
    }
  }

  toggleAddAddress = () => {
    this.setState(state => ({ isAddAddress: !state.isAddAddress }));
  };

  onImageChange = (newImage) => {
    this.setState({ currentImage: newImage });
  }

  handleSubmit = (values) => {
    const { isAddAddress, currentImage } = this.state;
    const {
      vacancy, editVacancy, activeTeam, team,
    } = this.props;
    const questions = values.questions && values.questions.map(
      question => question && question.title && question.title.value,
    );

    const newValues = { ...values };

    if (newValues.salary_to === '') {
      newValues.salary_to = null;
    }

    if (newValues.salary_from === '') {
      newValues.salary_from = null;
    }

    const addressOptions = getAddressOptionsFromTeam(team);

    const updatedValues = {
      ...newValues,
      id: vacancy.id,
      team: activeTeam,
      country: (isAddAddress || !addressOptions.length) ? (values.country && values.country.value)
        : (values.addressSelect && values.addressSelect.country),
      city: (isAddAddress || !addressOptions.length) ? ((values.city && values.city.value))
        : (values.addressSelect && values.addressSelect.city),
      address: (isAddAddress || !addressOptions.length) ? ((values.address && values.address.value) || '')
        : (values.addressSelect && values.addressSelect.address),
      questions,
    };

    editVacancy(updatedValues, true, currentImage);
  };

  render() {
    const {
      vacancy,
      team,
      countries,
      fieldSets,
      questions,
      match: { params: { shortId } },
      mode,
    } = this.props;

    const { isAddAddress } = this.state;

    if (!vacancy) {
      return <Redirect to={`/teams/${shortId}/vacancies`} />;
    }

    const {
      title,
      specialization,
      salary_from,
      salary_to,
      currency,
      country_name,
      city_name,
      address,
      schedule,
      experience,
      hiring_template,
      jwfield_set,
      description,
      requirements,
      conditions,
      sort_order,
      is_remote,
      questions: vacancyQuestions,
    } = vacancy;

    const { currentImage } = this.state;

    const questionsInitialValues = vacancyQuestions.map((vacancyQuestion) => {
      const questionData = questions
        .filter(question => !question.deleted)
        .find(question => vacancyQuestion === question.id);
      return questionData && { ...questionData, title: { label: questionData.title, value: questionData.id } };
    });

    const initialValues = {
      title,
      specialization,
      salary_from,
      salary_to,
      currency,
      addressSelect: {
        label: `${
          country_name ? `${country_name}` : ''}${city_name ? `, ${city_name}` : ''}${address ? `, ${address}` : ''
        }`,
        value: {
          country_name,
          city_name,
          address,
        },
      },
      schedule,
      experience,
      hiring_template,
      jwfield_set,
      description,
      requirements,
      conditions,
      sort_order,
      is_remote,
      questions: questionsInitialValues,
    };

    const breadcrumbs = mode === 'archive'
      ? [{ title: 'Архив вакансий', link: `/teams/${shortId}/archive` },
        { title: vacancy.title, link: '' }]
      : [{ title: 'Вакансии', link: `/teams/${shortId}/vacancies#${vacancy.id}` },
        { title: vacancy.title, link: '' }];
    const notDeletedQuestions = questions.filter(question => question.deleted === null);
    const pageTitle = `${vacancy.title} редактирование вакансии | Jobs Widget`;
    return (
      <React.Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <EditVacancyForm
          onSubmit={this.handleSubmit}
          team={team}
          fieldSets={fieldSets}
          countries={countries}
          initialValues={initialValues}
          questions={notDeletedQuestions}
          toggleAddAddress={this.toggleAddAddress}
          isAddAddress={isAddAddress}
          currentImage={currentImage}
          onImageChange={this.onImageChange}
        />
      </React.Fragment>
    );
  }
}

EditVacancy.defaultProps = {
  countries: [],
  mode: 'actualVacancies',
};

EditVacancy.propTypes = {
  activeTeam: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  editVacancy: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  vacancy: PropTypes.object.isRequired,
  fieldSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const {
    app: { activeTeam, countries },
    entities: { teams },
    fieldSets,
    questions,
  } = state;
  const team = teams[activeTeam];
  const vacancy = team.vacancies.find(vacancy => vacancy.id === +ownProps.match.params.id);
  return {
    team,
    activeTeam,
    vacancy,
    countries,
    fieldSets,
    questions,
  };
};

const mapDispatchToProps = dispatch => ({
  editVacancy: (vacancy, redirectType, vacancyImage) => (
    dispatch(editVacancy(vacancy, redirectType, vacancyImage))
  ),
  getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVacancy);
