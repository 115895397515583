import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  isDirty,
  reduxForm,
} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import renderField from './render/renderField';
import history from '../routes/history';
import StatusMessage from '../containers/StatusMessage';
import validateHiringTemplateForm from './validate/validateHiringTemplateForm';
import renderActionsSetup from './render/renderActionsSetup';
import EntitiesEditContainer from '../containers/EntitiesEditContainer';
import EditHiringState from '../components/EditHiringState';
import {
  createHiringTemplateState,
  editHiringTemplateState,
  deleteHiringTemplateState,
} from '../actions/hiringTemplatesStates';
import { TOOLTIP_TEXTS } from '../utils';


const HiringTemplateForm = (props) => {
  const {
    handleSubmit,
    dirty,
    submitSucceeded,
    activeTeamShortId,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={dirty && !submitSucceeded}
        message="Изменения шаблона найма не сохранены. Вы уверены, что хотите покинуть страницу?"
      />
      <div className="template-editing-form-heading">
        <Field
          name="name"
          type="text"
          label="Название шаблона"
          tooltipText={TOOLTIP_TEXTS.hiringTemplateName}
          component={renderField}
        />
      </div>
      <FieldArray
        name="actionsSetup"
        component={renderActionsSetup}
      />
      <div className="buttons-container">
        <button type="submit" className="button" disabled={!dirty}>Сохранить изменения</button>
        <button
          type="button"
          className="button button_color_dark-blue"
          onClick={() => history.push(`/teams/${activeTeamShortId}/templates`)}
        >
          Отмена
        </button>
      </div>
      <StatusMessage
        status="editHiringTemplate"
        successMessage="Шаблон найма успешно сохранен"
        errorMessage="Ошибка, шаблон не сохранен"
      />
      <EntitiesEditContainer
        labelName="Название состояния"
        newLabelName="Название нового состояния"
        fieldName="hiringTemplatesStates"
        ItemComponent={EditHiringState}
        title="Редактирование состояний"
        addText="Добавить новое состояние"
        onEdit={{
          createItem: createHiringTemplateState,
          editItem: editHiringTemplateState,
          deleteItem: deleteHiringTemplateState,
        }}
        filterFunction={value => value.is_custom}
        tooltipText={TOOLTIP_TEXTS.editingHiringTemplateState}
      />
    </form>
  );
};

HiringTemplateForm.defaultProps = {
  activeTeamShortId: null,
};

HiringTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  activeTeamShortId: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { app: { activeTeamShortId } } = state;
  const dirty = isDirty('editHiringTemplate')(state);
  return {
    dirty,
    activeTeamShortId,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'editHiringTemplate',
    validate: validateHiringTemplateForm,
    enableReinitialize: true,
  }),
)(HiringTemplateForm);
