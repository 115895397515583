import React from 'react';
import dateformat from 'dateformat';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TOOLTIP_TEXTS } from '../utils';
import Tooltip from './Tooltip';


const ResponsesTitleBlock = ({ titleInformation }) => {
  let titleContainer;

  const { isReportPageType } = titleInformation;
  if (!isReportPageType) {
    const {
      onShowFilterChange,
      showFilter,
      shortId,
    } = titleInformation;
    titleContainer = (
      <>
        <h1 className="main-page-title">Отклики</h1>
        <div
          className="responses-filter-open-box"
          onClick={onShowFilterChange}
        >
          <FontAwesomeIcon
            className="item-icon responses-filter-icon"
            icon="filter"
          />
          Показать фильтры
          {showFilter ? (
            <FontAwesomeIcon
              className="item-icon responses-filter-chevron-icon"
              icon="chevron-up"
            />
          ) : (
            <FontAwesomeIcon
              className="item-icon responses-filter-chevron-icon"
              icon="chevron-down"
            />
          )}
        </div>
        <Link
          to={`/teams/${shortId}/vacancies/responses/create-response`}
          hidden={shortId === null}
        >
          <button type="button" className="button button_plus">
            <span className="button-icon">+</span>
            <span className="button-text">Добавить отклик</span>
          </button>
        </Link>
      </>
    );
  } else {
    let statusTitle = '';
    let toolTip;
    const {
      statusNameFromQuery,
      manager,
      managerInfo,
      date_from,
      date_to,
      vacancyInfo,
      sourceInfo,
    } = titleInformation;

    if (statusNameFromQuery === 'created' || sourceInfo === 'created') {
      statusTitle = 'Добавленные отклики';
      toolTip = (
        <Tooltip
          className="main-page-title__tooltip"
          text={`${TOOLTIP_TEXTS.reportPageNewResponse}`}
        />
      );
    } else if (statusNameFromQuery && statusNameFromQuery.name) {
      toolTip = (
        <Tooltip
          className="main-page-title__tooltip"
          text={`${TOOLTIP_TEXTS.reportPageStatus} '${statusNameFromQuery.name}'.`}
        />
      );
      statusTitle = `Отклики в статусе '${statusNameFromQuery.name}'`;
    }

    titleContainer = (
      <h1 className="main-page-title">
        {statusTitle && statusTitle}
        {statusTitle && toolTip}
        <br />
        {`Период: ${dateformat(date_from.toString(), 'dd.mm.yyyy')} 
              по ${dateformat(date_to.toString(), 'dd.mm.yyyy')}`}
        <br />

        {(manager && managerInfo)
        && `Обработал менеджер: ${managerInfo.first_name} ${managerInfo.last_name}`}

        {(vacancyInfo)
          ? (
            <div>
              {'Вакансия: "'}
              {vacancyInfo.title}
              {'"'}
            </div>
          ) : ''}

        {(sourceInfo && sourceInfo !== 'created' && sourceInfo !== 'null')
          ? `Источник: "${sourceInfo.name || sourceInfo}"`
          : ''}
      </h1>
    );
  }

  return titleContainer;
};

export default ResponsesTitleBlock;
