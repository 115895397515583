import React from 'react';
import PropTypes from 'prop-types';
import ResponseChangesHistoryList from './ResponseChangesHistoryList';


const ResponseHistory = ({ responseChanges }) => (
  <div className="response-history table-responsive">
    <div className="title comments__title">История действий с откликом</div>
    <table className="table table-striped">
      <thead>
        <tr className="content-changes-history-title">
          <th>№</th>
          <th>Сотрудник</th>
          <th>Действие</th>
          <th>Дата изменения</th>
        </tr>
      </thead>
      <tbody>
        <ResponseChangesHistoryList
          responseChanges={responseChanges}
        />
      </tbody>
    </table>
  </div>
);

ResponseHistory.defaultProps = {
  responseChanges: [],
};

ResponseHistory.propTypes = {
  responseChanges: PropTypes.arrayOf(PropTypes.object),
};

export default ResponseHistory;
