import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import QuestionModal from '../containers/QuestionModal';
import ConfirmModal from './ConfirmModal';


class QuestionContainer extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  handleDeleteQuestion = (id) => {
    const { deleteQuestion, teamId } = this.props;
    deleteQuestion(teamId, id);
  };

  render() {
    const { isOpen } = this.state;
    const {
      question,
      question: {
        id,
        title,
        message,
        useInVacancies,
      },
      teamId,
    } = this.props;
    const editIcon = (
      <div className="icon-wrap">
        <FontAwesomeIcon
          className="item-icon"
          icon="pencil-alt"
        />
      </div>
    );
    let warningMessage;
    if (Object.values(useInVacancies).length > 0) {
      warningMessage = [
        'Данный вопрос используется в следующих вакансиях:\n', Object.values(useInVacancies),
      ].join(' ');
    }
    //TODO: need to change CSS class names
    return (
      <div className="content-container content-container-list__item">
        <div className="team-container-toggle questions-page__toggle-flex">
          <div className="team-container-toggle-header">
            <h4>{title}</h4>
          </div>
          <QuestionModal
            modalButton={editIcon}
            question={question}
            headerText="Изменить вопрос"
            teamId={teamId}
          />
          <div className="icon-wrap">
            <ConfirmModal
              type="icon"
              headerText="Удалить вопрос?"
              modalAction={() => this.handleDeleteQuestion(id)}
              warningMessage={warningMessage}
              icon="trash-alt"
            />
          </div>
          <button
            type="button"
            className="button-circle"
            onClick={this.toggle}
          >
            {isOpen
              ? <FontAwesomeIcon icon="minus" />
              : <FontAwesomeIcon icon="plus" />
            }
          </button>
        </div>
        <Collapse
          isOpen={isOpen}
          className="team-container-collapse"
        >
          <div className="questions-page__text" dangerouslySetInnerHTML={{ __html: message }} />
        </Collapse>
      </div>
    );
  }
}

QuestionContainer.defaultProps = {
  teamId: null,
};

QuestionContainer.propTypes = {
  question: PropTypes.object.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  teamId: PropTypes.number,
};

export default QuestionContainer;
