import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { deleteFieldSet, getFieldSets } from '../actions/fieldSets';
import { getFields } from '../actions/fields';
import FieldSetsList from '../components/FieldSetsList';


class FieldSetsPage extends Component {
  componentDidMount() {
    const {
      getFields,
      getFieldSets,
      activeTeam,
    } = this.props;
    getFields(activeTeam);
    getFieldSets(activeTeam);
  }

  render() {
    const {
      fieldSets,
      deleteFieldSet,
      defaultFieldSet,
      activeTeam,
      location,
      activeTeamShortId,
    } = this.props;

    return (
      <div className="field-sets-wrap section-grey">
        <Helmet>
          <title>Настройка полей | Jobs Widget</title>
        </Helmet>
        <div className="page-header-container">
          <h1 className="main-page-title">Наборы полей</h1>
          <Link to={`/teams/${activeTeamShortId}/field-sets/create`}>
            <button type="button" className="button button_plus">
              <span className="button-icon">+</span>
              <span className="button-text">Создать набор</span>
            </button>
          </Link>
        </div>
        <FieldSetsList
          items={fieldSets}
          deleteFieldSet={deleteFieldSet}
          defaultFieldSet={defaultFieldSet}
          activeTeam={activeTeam}
          activeTeamShortId={activeTeamShortId}
          location={location}
        />
      </div>
    );
  }
}

FieldSetsPage.defaultProps = {
  fieldSets: [],
};

FieldSetsPage.propTypes = {
  fieldSets: PropTypes.arrayOf(PropTypes.object),
  deleteFieldSet: PropTypes.func.isRequired,
  getFields: PropTypes.func.isRequired,
  getFieldSets: PropTypes.func.isRequired,
  defaultFieldSet: PropTypes.number.isRequired,
  activeTeam: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    fieldSets,
    app: { activeTeam, activeTeamShortId },
    entities: { teams },
  } = state;
  const { default_field_set } = teams[activeTeam] || {};
  return ({
    fieldSets,
    defaultFieldSet: default_field_set,
    activeTeam,
    activeTeamShortId,
  });
};

const mapDispatchToProps = dispatch => ({
  deleteFieldSet: (teamId, id) => dispatch(deleteFieldSet(teamId, id)),
  getFields: teamId => dispatch(getFields(teamId)),
  getFieldSets: teamId => dispatch(getFieldSets(teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldSetsPage);
