import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './render/renderField';
import htmlTextAreaField from './render/renderHtmlTextArea';
import validateQuestionForm from './validate/validateQuestionForm';


const QuestionForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        type="text"
        component={renderField}
        label="Введите условное название вопроса"
      />
      <Field
        name="message"
        componentName="message"
        component={htmlTextAreaField}
        label="Текст вопроса"
      />
      <button
        type="submit"
        className="button"
      >
        Сохранить изменения
      </button>
    </form>
  );
};

QuestionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'question',
  validate: validateQuestionForm,
})(QuestionForm);
