import { getFormInitialValues } from 'redux-form';
import store from '../../store';


const hasNotUniqueTemplateName = ({ name, team: teamId }) => {
  const state = store.getState();
  const {
    entities: { teams },
  } = state;
  const { hiring_templates } = teams[teamId];
  const initialValues = getFormInitialValues('editHiringTemplate')(state);

  return initialValues.name !== name && hiring_templates.some(hiringTemplate => name === hiringTemplate.name);
};

const getActionsErrors = (values) => {
  const errors = {};
  if (!values.actions || !values.actions.length) {
    errors.actions = { _error: 'Должен быть как минимум один этап' };
  } else {
    const actionsArrayErrors = [];
    values.actions.forEach((action, actionIndex) => {
      const actionErrors = {};
      if (!action || !action.to_state) {
        actionErrors.to_state = 'Обязательное поле';
        actionsArrayErrors[actionIndex] = actionErrors;
      }
      if (!action || !action.button_name) {
        actionErrors.button_name = 'Обязательное поле';
        actionsArrayErrors[actionIndex] = actionErrors;
      }

      const filteredActions = [...values.actions].filter((item, index) => index !== actionIndex);

      if (filteredActions.some(({ button_name }) => button_name === action.button_name)) {
        actionErrors.button_name = 'Кнопка с таким именем уже существует';
        actionsArrayErrors[actionIndex] = actionErrors;
      }
    });
    if (actionsArrayErrors.length) {
      errors.actions = actionsArrayErrors;
    }
  }

  if (Object.entries(errors).length === 0 && errors.constructor === Object) {
    return undefined;
  }
  return errors.actions;
};

const getActionsSetupErrors = (values) => {
  const errors = {};
  if (!values.actionsSetup || !values.actionsSetup.length) {
    errors.actionsSetup = { _error: 'Должен быть как минимум один этап' };
  } else {
    const actionsSetupErrorsArray = [];
    values.actionsSetup.forEach((actionSetup, actionSetupIndex) => {
      const actionsSetupErrors = {};
      if (!actionSetup.from_state) {
        actionsSetupErrors.from_state = 'Обязательное поле';
        actionsSetupErrorsArray[actionSetupIndex] = actionsSetupErrors;
      }
      actionsSetupErrors.actions = getActionsErrors(actionSetup);
      actionsSetupErrorsArray[actionSetupIndex] = actionsSetupErrors;
    });
    if (actionsSetupErrorsArray.length) {
      errors.actionsSetup = actionsSetupErrorsArray;
    }
  }

  // check object is empty
  if (Object.entries(errors).length === 0 && errors.constructor === Object) {
    return undefined;
  }
  return errors.actionsSetup;
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Обязательное поле';
  } else if (hasNotUniqueTemplateName(values)) {
    errors.name = 'Шаблон найма с таким именем уже существует';
  }
  errors.actionsSetup = getActionsSetupErrors(values);
  return errors;
};

export default validate;
