import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import CustomizationItem from './CustomizationItem';
import StatusMessage from '../containers/StatusMessage';
import TeamUrlEditor from './TeamUrlEditor';
import StyledSelect from './StyledSelect';


const languageOptions = [
  { label: 'Русский', value: 'ru' },
  { label: 'Английский', value: 'en' },
];

class CustomizationContainer extends Component {
  constructor(props) {
    super(props);
    const { style } = this.props;
    const { color, size, language } = style;
    this.state = {
      isChanged: false,
      color: { ...color },
      size,
      language,
    };
  }

  componentDidMount() {
    const { getDefaultStyle } = this.props;
    getDefaultStyle();
  }

  componentDidUpdate(prevProps) {
    const { teamId, editStyleStatus } = this.props;
    if (prevProps.teamId !== teamId) {
      this.onUpdate();
    }
    if (prevProps.editStyleStatus !== editStyleStatus) {
      this.setSaveStyleComplete();
    }
  }

  onLanguageChange = ({ value: language }) => {
    this.setState({ language });
  }

  onUpdate = () => {
    const { style: { color, size } } = this.props;
    this.setState({ color, size, isChanged: false });
  };

  saveStyle = () => {
    const { color, size, language } = this.state;
    const { editStyle, teamId, style } = this.props;
    editStyle(teamId, {
      id: style.id, color, size, language,
    });
  };

  handleCaptchaChange = () => {
    const { teamId, isCaptchaOn, changeCaptchaStatus } = this.props;
    changeCaptchaStatus(teamId, !isCaptchaOn);
  }

  setSaveStyleComplete = () => {
    this.setState({ isChanged: false });
  };

  resetStyle = () => {
    const { defaultStyle: { color, size } } = this.props;
    this.setState({ color, size, isChanged: true });
    Object.entries(color).map(color => this.changeIframeStyles('color', ...color));
    Object.entries(size).map(size => this.changeIframeStyles('size', ...size));
  };

  changeIframeStyles = (type, name, value) => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const widget = iframe.contentDocument || iframe.contentWindow.document;
    const cssVarTitle = `--${name.split('_').join('-')}-${type}`;
    const updatedValue = type === 'size' ? `${value}px` : value;
    widget.body.style.setProperty(cssVarTitle, updatedValue);

    if (name === 'background') {
      const cssVarTitle = '--ellipsis-color';
      const ellipsisOpacity = 33;
      widget.body.style.setProperty(cssVarTitle, `${value}${ellipsisOpacity}`);
    }
  };

  handleColorChange = (colorInfo) => {
    const { color } = this.state;
    const { name, value } = colorInfo;
    this.setState({ color: { ...color, [name]: value }, isChanged: true });
    this.changeIframeStyles('color', name, value);
  };

  handleSizeChange = (sizeInfo) => {
    const { size } = this.state;
    const { name, value } = sizeInfo;
    this.setState({ size: { ...size, [name]: value }, isChanged: true });
    this.changeIframeStyles('size', name, value);
  };

  render() {
    const {
      color,
      size,
      isChanged,
      language,
    } = this.state;

    const {
      teamId,
      policyUrl,
    } = this.props;

    return (
      <React.Fragment>
        <Prompt
          when={isChanged}
          message="Настройки виджета не сохранены. Вы уверены, что хотите покинуть страницу?"
        />
        <div className="customization-list">
          <div className="customization-container">
            <h3 className="title customization-container__title">Список вакансий</h3>
            <div className="customization-container-settings-container">
              <CustomizationItem
                title="Заголовок"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'title',
                  value: size.title,
                }}
                colors={{
                  primary: {
                    name: 'title',
                    value: color.title,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Фон"
                handleColorChange={this.handleColorChange}
                colors={{
                  primary: {
                    name: 'background',
                    value: color.background,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Ссылка на вакансию"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'link',
                  value: size.link,
                }}
                colors={{
                  primary: {
                    name: 'link',
                    value: color.link,
                    description: 'основной цвет',
                  },
                  secondary: {
                    name: 'link_hover',
                    value: color.link_hover,
                    description: 'цвет при наведении',
                  },
                }}
              />
              <CustomizationItem
                title="Короткое описание вакансии"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'short_description',
                  value: size.short_description,
                }}
                colors={{
                  primary: {
                    name: 'short_description',
                    value: color.short_description,
                    description: 'основной цвет',
                  },
                }}
              />
            </div>
          </div>
          <div className="customization-container">
            <h3 className="title customization-container__title">Подробное описание вакансии</h3>
            <div className="customization-container-settings-container">
              <CustomizationItem
                title="Основной фон"
                handleColorChange={this.handleColorChange}
                colors={{
                  primary: {
                    name: 'background_primary',
                    value: color.background_primary,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Дополнительный фон"
                handleColorChange={this.handleColorChange}
                colors={{
                  primary: {
                    name: 'background_secondary',
                    value: color.background_secondary,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Заголовок деталей"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'details_title',
                  value: size.details_title,
                }}
                colors={{
                  primary: {
                    name: 'details_title',
                    value: color.details_title,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Описание деталей"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'details_description',
                  value: size.details_description,
                }}
                colors={{
                  primary: {
                    name: 'details_description',
                    value: color.details_description,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Заголовки"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'secondary_title',
                  value: size.secondary_title,
                }}
                colors={{
                  primary: {
                    name: 'secondary_title',
                    value: color.secondary_title,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Текст"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'text',
                  value: size.text,
                }}
                colors={{
                  primary: {
                    name: 'text',
                    value: color.text,
                    description: 'основной цвет',
                  },
                }}
              />
              <CustomizationItem
                title="Поля ввода данных"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'input',
                  value: size.input,
                }}
                colors={{
                  primary: {
                    name: 'input',
                    value: color.input,
                    description: 'основной цвет',
                  },
                  secondary: {
                    name: 'input_focus',
                    value: color.input_focus,
                    description: 'цвет обводки в фокусе',
                  },
                  primaryText: {
                    name: 'input_text',
                    value: color.input_text,
                    description: 'цвет текста',
                  },
                }}
              />
              <CustomizationItem
                title="Ссылка на скачивание файла"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'file_link',
                  value: size.file_link,
                }}
                colors={{
                  primary: {
                    name: 'file_link',
                    value: color.file_link,
                    description: 'основной цвет',
                  },
                  secondary: {
                    name: 'file_link_hover',
                    value: color.file_link_hover,
                    description: 'цвет при наведении',
                  },
                }}
              />
              <CustomizationItem
                title="Кнопка &laquo;Назад&raquo;"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'back_button',
                  value: size.back_button,
                }}
                colors={{
                  primary: {
                    name: 'back_button',
                    value: color.back_button,
                    description: 'основной цвет',
                  },
                  secondary: {
                    name: 'back_button_hover',
                    value: color.back_button_hover,
                    description: 'цвет при наведении',
                  },
                  primaryText: {
                    name: 'back_button_text',
                    value: color.back_button_text,
                    description: 'цвет текста',
                  },
                  secondaryText: {
                    name: 'back_button_hover_text',
                    value: color.back_button_hover_text,
                    description: 'цвет текста при наведении',
                  },
                }}
              />
              <CustomizationItem
                title="Кнопка &laquo;Отправить&raquo;"
                handleSizeChange={this.handleSizeChange}
                handleColorChange={this.handleColorChange}
                sizeInfo={{
                  name: 'submit_button',
                  value: size.submit_button,
                }}
                colors={{
                  primary: {
                    name: 'submit_button',
                    value: color.submit_button,
                    description: 'основной цвет',
                  },
                  secondary: {
                    name: 'submit_button_hover',
                    value: color.submit_button_hover,
                    description: 'цвет при наведении',
                  },
                  primaryText: {
                    name: 'submit_button_text',
                    value: color.submit_button_text,
                    description: 'цвет текста',
                  },
                  secondaryText: {
                    name: 'submit_button_hover_text',
                    value: color.submit_button_hover_text,
                    description: 'цвет текста при наведении',
                  },
                }}
              />
              <div className="customization-item__language-container">
                <span className="customization-item-title">Выбор языка:</span>
                <StyledSelect
                  options={languageOptions}
                  defaultValue={languageOptions.find(({ value }) => value === language)}
                  isSearchable={false}
                  onChange={selectedValue => this.onLanguageChange(selectedValue)}
                  className="customiztion-item__language-select"
                />
                <div />
              </div>
              {/*  TODO Captcha switch for future
              Uncomment when captcha will be added to widget
              <label htmlFor="item-switch-captcha" className="customization-container-captcha-switch">
                <h4 className="team-url-editor-container-title">Защита от автоматического спама</h4>
                <div className="customization-switch-captcha">
                  <Switch
                    onChange={this.handleCaptchaChange}
                    checked={isCaptchaOn}
                    id="item-switch-captcha"
                    height={24}
                    width={44}
                    onColor="#26b9a1"
                    onHandleColor="#fff"
                    offColor="#c3c9d0"
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>
              </label> */}
              <TeamUrlEditor id={teamId} policyUrl={policyUrl} />
            </div>
          </div>
          <div>
            <div className="buttons-container">
              <button
                type="button"
                className="button"
                onClick={this.saveStyle}
              >
                Сохранить изменения
              </button>
              <button
                type="button"
                className="button button_color_dark-blue"
                onClick={this.resetStyle}
              >
                Сбросить
              </button>
            </div>
            <StatusMessage
              status="editStyleStatus"
              successMessage="Изменения успешно сохранены"
              errorMessage="Ошибка сохранения изменений"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CustomizationContainer.propTypes = {
  style: PropTypes.shape({
    id: PropTypes.number.isRequired,
    color: PropTypes.object.isRequired,
    size: PropTypes.object.isRequired,
  }).isRequired,
  defaultStyle: PropTypes.shape({
    color: PropTypes.object,
    size: PropTypes.object,
  }).isRequired,
  policyUrl: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  editStyle: PropTypes.func.isRequired,
  getDefaultStyle: PropTypes.func.isRequired,
  editStyleStatus: PropTypes.string.isRequired,
  isCaptchaOn: PropTypes.bool.isRequired,
  changeCaptchaStatus: PropTypes.func.isRequired,
};

export default CustomizationContainer;
