import axios from 'axios';
import appConfig from './configs/appConfig';


axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

export const hhAxios = axios.create();
export default axios.create({
  baseURL: appConfig.API_URL,
  timeout: 60000,
});
