import {
  validateErrors,
  required,
  maxLength,
  minLength,
  email, equal,
} from '../validators';


const validate = (values) => {
  const validators = {
    firstName: [maxLength(30), required],
    lastName: [maxLength(30), required],
    email: [maxLength(200), email, required],
    password: [minLength(8), required],
    confirmPassword: [
      minLength(8),
      equal('password', 'Подтверждение пароля не совпадает'),
      required,
    ],
  };
  return validateErrors(values, validators);
};

export default validate;
