import {
  validateErrors,
  required,
  maxLength,
  matchPattern,
} from '../validators';


const validate = (values) => {
  const validators = {
    hh_employer_url: [
      maxLength(50),
      matchPattern(/\d+\/?$/, 'В конце ссылки должен быть ID компании'),
      required,
    ],
  };
  return validateErrors(values, validators);
};

export default validate;
