export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAIL = 'ADD_COMMENT_FAIL';

export const EDIT_COMMENT_REQUEST = 'EDIT_COMMENT_REQUEST';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_FAIL = 'EDIT_COMMENT_FAIL';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';

export const GET_RESPONSES_REQUEST = 'GET_RESPONSES_REQUEST';
export const GET_RESPONSES_SUCCESS = 'GET_RESPONSES_SUCCESS';
export const GET_RESPONSES_FAIL = 'GET_RESPONSES_FAIL';

export const GET_RESPONSE_REQUEST = 'GET_RESPONSE_REQUEST';
export const GET_RESPONSE_SUCCESS = 'GET_RESPONSE_SUCCESS';
export const GET_RESPONSE_FAIL = 'GET_RESPONSE_FAIL';

export const MARK_RESPONSE_AS_VIEWED_REQUEST = 'MARK_RESPONSE_AS_VIEWED_REQUEST';
export const MARK_RESPONSE_AS_VIEWED_SUCCESS = 'MARK_RESPONSE_AS_VIEWED_SUCCESS';
export const MARK_RESPONSE_AS_VIEWED_FAIL = 'MARK_RESPONSE_AS_VIEWED_FAIL';

export const EDIT_RESPONSE_REQUEST = 'EDIT_RESPONSE_REQUEST';
export const EDIT_RESPONSE_SUCCESS = 'EDIT_RESPONSE_SUCCESS';
export const EDIT_RESPONSE_FAIL = 'EDIT_RESPONSE_FAIL';

export const DELETE_RESPONSE_REQUEST = 'DELETE_RESPONSE_REQUEST';
export const DELETE_RESPONSE_SUCCESS = 'DELETE_RESPONSE_SUCCESS';
export const DELETE_RESPONSE_FAIL = 'DELETE_RESPONSE_FAIL';

export const DELETE_RESPONSES_REQUEST = 'DELETE_RESPONSES_REQUEST';
export const DELETE_RESPONSES_SUCCESS = 'DELETE_RESPONSES_SUCCESS';
export const DELETE_RESPONSES_FAIL = 'DELETE_RESPONSES_FAIL';

export const CREATE_RESPONSE_REQUEST = 'CREATE_RESPONSE_REQUEST';
export const CREATE_RESPONSE_SUCCESS = 'CREATE_RESPONSE_SUCCESS';
export const CREATE_RESPONSE_FAIL = 'CREATE_RESPONSE_FAIL';

export const MOVE_RESPONSE_REQUEST = 'MOVE_RESPONSE_REQUEST';
export const MOVE_RESPONSE_SUCCESS = 'MOVE_RESPONSE_SUCCESS';
export const MOVE_RESPONSE_FAIL = 'MOVE_RESPONSE_FAIL';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';
