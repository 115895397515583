import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createResponse } from '../actions/responses';
import '../styles/layout/VacancyPage.css';
import VacancyPart from '../components/VacancyPart';
import { experienceOptions } from '../forms/constants/vacancyFormConstants';
import { getSalaryText } from '../utils';
import { getVacancy } from '../actions/vacancies';
import Breadcrumbs from '../components/Breadcrumbs';
import CreateResponse from './CreateResponse';
import preloader from '../assets/img/preloader.gif';
import ErrorMessage from '../components/ErrorMessage';
import PublicHeader from '../components/PublicHeader';
import Footer from '../components/Footer';


class VacancyPage extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { id } } } = props;
    this.state = { currentVacancyId: id };
  }

  componentDidMount() {
    const { currentVacancyId } = this.state;
    const { getVacancy } = this.props;
    getVacancy(currentVacancyId);
  }

  componentDidUpdate(prevProps) {
    const { activeVacancy } = this.props;
    if (activeVacancy !== prevProps.activeVacancy) {
      this.vacancyUpdate(activeVacancy);
    }
  }

  vacancyUpdate(newActiveVacancy) {
    this.setState({ activeVacancy: { ...newActiveVacancy } });
  }

  render() {
    const { activeVacancy } = this.state;
    const { errors } = this.props;

    let vacancyPageContent;

    if (!activeVacancy) {
      vacancyPageContent = (
        <div className="loader">
          <img className="loader__gif" src={preloader} alt="Загрузка..." />
        </div>
      );
    } else {
      const {
        title,
        salary_from,
        salary_to,
        experience,
        address,
        city,
        description,
        requirements,
        conditions,
        currency,
        questions,
        team_title,
      } = activeVacancy;


      const breadcrumbs = [
        { title: 'Все вакансии', link: '/all-vacancies' },
        { title: `${title}`, link: '' },
      ];

      const currentExperience = experienceOptions.find(({ value }) => value === experience);
      const salaryText = getSalaryText(salary_from, salary_to, currency);

      vacancyPageContent = (
        <>
          <Row className="mb-5">
            <Col xs="12" className="vacancy-page-main-info">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <h1 className="vacancy-item-title mb-3 mt-4">{title}</h1>
              <Row className="vacancy-page-required mb-4">
                <Col col="6">{salaryText}</Col>
                <Col col="6" className="vacancy-page-desc">{team_title}</Col>
              </Row>
              <Row>
                <Col xs="6">
                  <div className="vacancy-page-required">Требуемый опыт</div>
                  <div className="vacancy-page-desc">{currentExperience.label}</div>
                </Col>
                <Col xs="6">
                  <div className="vacancy-page-required">Адрес</div>
                  <div className="vacancy-page-desc">
                    {city && <span>{`${city} `}</span>}
                    {address && <span>{`${address}`}</span>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {description && <VacancyPart title="Описание вакансии" description={description} />}
          {requirements && <VacancyPart title="Требования" description={requirements} />}
          {conditions && <VacancyPart title="Условия" description={conditions} />}

          <CreateResponse
            vacancies={[{ ...activeVacancy }]}
            questions={questions}
          />
        </>
      );
    }

    if (errors.hasOwnProperty('getVacancyError')) {
      vacancyPageContent = <ErrorMessage errorName="getVacancyError" />;
    }

    return (
      <>
        <PublicHeader />
        <div className="vacancy-content container mt-4">
          {vacancyPageContent}
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    entities: { allVacancies: { activeVacancy } },
    questions,
    errors,
  } = state;
  return {
    activeVacancy,
    questions,
    errors,
  };
};

VacancyPage.defaultProps = {
  activeVacancy: {},
};

VacancyPage.propTypes = {
  match: PropTypes.object.isRequired,
  getVacancy: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  activeVacancy: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  createResponse: response => dispatch(createResponse(response)),
  getVacancy: id => dispatch(getVacancy(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacancyPage);
