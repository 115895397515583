import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './render/renderField';
import ErrorMessage from '../components/ErrorMessage';
import validateChangeTeamUrlForm from './validate/validateChangeTeamUrlForm';


const ChangeTeamUrlForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="teamUrl"
        type="text"
        component={renderField}
        label="Ссылка на соглашение о персональных данных"
      />
      <ErrorMessage errorName="editTeamUrlError" />
    </form>
  );
};

ChangeTeamUrlForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  validate: validateChangeTeamUrlForm,
})(ChangeTeamUrlForm);
