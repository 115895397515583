import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createOption, TOOLTIP_TEXTS } from '../../utils';
import { getHiringTemplatesStates } from '../../actions/hiringTemplatesStates';
import renderActions from './renderActions';
import renderSelect from './renderSelect';
import FieldError from '../../components/FieldError';
import Tooltip from '../../components/Tooltip';


class renderActionsSetup extends React.Component {
  state = {
    options: [],
  };

  componentDidMount() {
    const { getHiringTemplatesStates, teamId } = this.props;
    getHiringTemplatesStates(teamId);
  }

  handleCreate = (newOption) => {
    this.setState(state => ({ options: [...state.options, newOption] }));
  };

  removeItem = (index) => {
    const { fields } = this.props;
    if (index) {
      fields.splice(index, 1);
    }
  };

  getCreatableSelectOptions = () => {
    const { options } = this.state;
    const { states } = this.props;
    const statesOptions = states.map(({ name }) => createOption(name));
    return [...statesOptions, ...options];
  };

  getSelectOptions = () => {
    const { actionsSetup } = this.props;
    const statesValuesSet = new Set();
    actionsSetup.forEach((values) => {
      if (values) {
        if (values.from_state) {
          statesValuesSet.add(values.from_state.value);
        }
        if (values.actions) {
          values.actions.forEach((action) => {
            if (action.to_state) {
              statesValuesSet.add(action.to_state.value);
            }
          });
        }
      }
    });
    return [...statesValuesSet].map(state => createOption(state));
  };

  render() {
    const {
      fields,
      meta: { error },
    } = this.props;
    const creatableSelectOptions = this.getCreatableSelectOptions();
    const selectOptions = this.getSelectOptions();
    return (
      <div className="render-actions-wrap">
        <div className="render-actions">
          {fields.map((actionSetup, index) => (
            <div
              key={actionSetup}
              className="render-actions-setup-container"
            >
              {index === 0 && (
                <span className="render-actions-title">
                  Начальное состояние
                  <Tooltip text={TOOLTIP_TEXTS.hiringTemplateStartState} />
                </span>
              )}
              <div className={`render-actions-setup-header ${index === 0 ? 'initial-actions' : ''}`}>
                <Field
                  name={`${actionSetup}.from_state`}
                  type="text"
                  label="Откуда"
                  tooltipText={index === 0 ? TOOLTIP_TEXTS.hiringTemplateFrom : undefined}
                  component={renderSelect}
                  options={index === 0 ? creatableSelectOptions : selectOptions}
                  noOptionsMessage="Нет доступных состояний"
                  placeholder="Выберите состояние"
                />
                {index !== 0 && (
                  <button
                    type="button"
                    className="button-circle"
                    onClick={() => this.removeItem(index)}
                  >
                    <FontAwesomeIcon
                      icon="times"
                    />
                  </button>
                )}
              </div>
              <FieldArray
                name={`${actionSetup}.actions`}
                component={renderActions}
                options={creatableSelectOptions}
                handleCreate={this.handleCreate}
                actionSetupIndex={index}
              />
            </div>
          ))}
        </div>
        {error && <FieldError error={error} />}
        <button
          type="button"
          className="button-frame"
          onClick={() => fields.push({ actions: [{}] })}
        >
          Добавить этап
        </button>
      </div>
    );
  }
}

renderActionsSetup.defaultProps = {
  actionsSetup: [],
  teamId: null,
};

renderActionsSetup.propTypes = {
  fields: PropTypes.object.isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  getHiringTemplatesStates: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  teamId: PropTypes.number,
  actionsSetup: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  const {
    hiringTemplatesStates: states,
    app: { activeTeam },
  } = state;
  const selector = formValueSelector('editHiringTemplate');
  const actionsSetup = selector(state, 'actionsSetup');
  return {
    actionsSetup,
    states,
    teamId: activeTeam,
  };
};

const mapDispatchToProps = dispatch => ({
  getHiringTemplatesStates: teamId => dispatch(getHiringTemplatesStates(teamId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(renderActionsSetup),
);
