import { WITH_LOADING_OFF, WITH_LOADING_ON } from '../constants/index';

import {
  CREATE_NEW_TEAM_SUCCESS,
  GET_DEFAULT_STYLE_SUCCESS,
  EDIT_VACANCY_FAIL,
  EDIT_VACANCY_SUCCESS,
  CREATE_NEW_TEAM_REQUEST,
  PUBLISH_VACANCY_RESTRICT,
} from '../constants/teamConst';

import {
  CHECK_USER_AUTHORIZATION_FAIL,
  CHECK_USER_AUTHORIZATION_REQUEST,
  CHECK_USER_AUTHORIZATION_SUCCESS,
  LOAD_INITIAL_DATA_FAIL,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOAD_INITIAL_DATA_SUCCESS,
  CHANGE_ACTIVE_TEAM_SUCCESS,
  CHANGE_ACTIVE_TEAM_REQUEST,
  GET_COUNTRIES_SUCCESS,
  EDIT_USER_SETTINGS_SUCCESS,
  CHANGE_ACTIVE_TEAM_ON_INIT_SUCCESS,
  RESET_ACTIVE_TEAM,
} from '../constants/appConst';

import { createReducer, updateObject } from '../utils/reducerUtils';
import { setAuthorizationToken } from '../utils';
import {
  CREATE_SOURCES_FAIL, CREATE_SOURCES_SUCCESS, EDIT_SOURCES_FAIL, EDIT_SOURCES_SUCCESS,
} from '../constants/sourcesConst';


const initialState = {
  authInProgress: false,
  authComplete: false,
  dataLoaded: false,
  isLoading: false,
};

const loadInitialDataSuccess = state => updateObject(state, {
  dataLoaded: true,
});

const loadInitialDataFail = (state, action) => updateObject(state, {
  dataLoaded: false,
  error: action.error,
});

const checkUserAuthorizationRequest = state => updateObject(state, {
  authInProgress: true,
  authComplete: false,
});

const checkUserAuthorizationSuccess = (state, action) => updateObject(state, {
  authInProgress: false,
  authComplete: true,
  user: action.user.id,
  activeTeam: action.user.active_team,
  activeTeamShortId: action.user.active_team_short_id,
  settings: action.user.settings,
});

const checkUserAuthorizationFail = (state) => {
  setAuthorizationToken(null);
  return updateObject(state, {
    authInProgress: false,
    authComplete: false,
    dataLoaded: false,
  });
};

const loginUserRequest = state => updateObject(state, {
  authInProgress: true,
  authComplete: false,
});

const loginUserSuccess = (state, action) => updateObject(state, {
  authInProgress: false,
  authComplete: true,
  user: action.data.user.id,
  activeTeam: action.data.user.active_team,
  activeTeamShortId: action.data.user.active_team_short_id,
  settings: action.data.user.settings,
});

const loginUserFail = state => updateObject(state, {
  authInProgress: false,
  authComplete: false,
});

const createNewTeamRequest = state => updateObject(state, {
  dataLoaded: false,
});

const createNewTeamSuccess = (state, action) => updateObject(state, {
  activeTeam: action.team.result,
  activeTeamShortId: action.team.entities.teams[action.team.result].short_id,
});

const changeActiveTeamRequest = state => updateObject(state, {
  dataLoaded: false,
});

const changeActiveTeamSuccess = (state, action) => updateObject(state, {
  activeTeam: action.team.id,
  activeTeamShortId: action.team.short_id,
});

const changeActiveTeamOnInitSuccess = (state, action) => updateObject(state, {
  activeTeam: action.team.id,
  activeTeamShortId: action.team.short_id,
});

const getCountries = (state, action) => updateObject(state, {
  countries: action.countries,
});

const getDefaultStyle = (state, action) => updateObject(state, {
  defaultStyle: action.team.style,
});

const lastEditSuccessVacancy = state => updateObject(state, {
  lastEditFailedVacancy: undefined,
});

const lastEditFailedVacancy = (state, action) => updateObject(state, {
  lastEditFailedVacancy: action.vacancy.id,
});

const lastCreatedFailedSource = (state, { tempId }) => updateObject(state, {
  lastCreatedFailedSource: tempId,
});

const lastCreatedSuccessSource = state => updateObject(state, {
  lastCreatedFailedSource: undefined,
});

const lastEditFailedSource = (state, { id }) => updateObject(state, {
  lastEditFailedSource: id,
});

const lastEditSuccessSource = state => updateObject(state, {
  lastEditFailedSource: undefined,
});

const editUserSettings = (state, action) => updateObject(state, {
  settings: action.user.settings,
});

const resetActiveTeam = state => updateObject(state, { activeTeam: null, activeTeamShortId: null });

const loadingOn = state => updateObject(
  state, { isLoading: true },
);

const loadingOff = state => updateObject(
  state, { isLoading: state.isLoading && false },
);

export default createReducer(initialState, {
  [LOAD_INITIAL_DATA_SUCCESS]: loadInitialDataSuccess,
  [LOAD_INITIAL_DATA_FAIL]: loadInitialDataFail,
  [CHECK_USER_AUTHORIZATION_REQUEST]: checkUserAuthorizationRequest,
  [CHECK_USER_AUTHORIZATION_SUCCESS]: checkUserAuthorizationSuccess,
  [CHECK_USER_AUTHORIZATION_FAIL]: checkUserAuthorizationFail,
  [LOGIN_USER_REQUEST]: loginUserRequest,
  [LOGIN_USER_SUCCESS]: loginUserSuccess,
  [LOGIN_USER_FAIL]: loginUserFail,
  [CREATE_NEW_TEAM_REQUEST]: createNewTeamRequest,
  [CREATE_NEW_TEAM_SUCCESS]: createNewTeamSuccess,
  [CHANGE_ACTIVE_TEAM_REQUEST]: changeActiveTeamRequest,
  [CHANGE_ACTIVE_TEAM_SUCCESS]: changeActiveTeamSuccess,
  [CHANGE_ACTIVE_TEAM_ON_INIT_SUCCESS]: changeActiveTeamOnInitSuccess,
  [GET_COUNTRIES_SUCCESS]: getCountries,
  [GET_DEFAULT_STYLE_SUCCESS]: getDefaultStyle,
  [EDIT_VACANCY_SUCCESS]: lastEditSuccessVacancy,
  [EDIT_VACANCY_FAIL]: lastEditFailedVacancy,
  [EDIT_SOURCES_FAIL]: lastEditFailedSource,
  [EDIT_SOURCES_SUCCESS]: lastEditSuccessSource,
  [CREATE_SOURCES_SUCCESS]: lastCreatedSuccessSource,
  [CREATE_SOURCES_FAIL]: lastCreatedFailedSource,
  [PUBLISH_VACANCY_RESTRICT]: lastEditFailedVacancy,
  [EDIT_USER_SETTINGS_SUCCESS]: editUserSettings,
  [RESET_ACTIVE_TEAM]: resetActiveTeam,
  [WITH_LOADING_ON]: loadingOn,
  [WITH_LOADING_OFF]: loadingOff,
});
