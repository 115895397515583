import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Spinner from './Spinner';


const withLoading = (WrappedComponent, spinnerType) => class extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { isLoading, ...restProps } = this.props;
    const renderComponent = !isLoading
      ? <WrappedComponent {...restProps} />
      : <Spinner spinnerType={spinnerType} />;
    return renderComponent;
  }
};

const mapStateToProps = state => ({
  isLoading: state.app.isLoading,
});

export default compose(
  connect(mapStateToProps, null),
  withLoading,
);
