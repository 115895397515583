import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import Tooltip from '../components/Tooltip';


class EntitiesEditContainer extends React.Component {
  constructor(props) {
    super(props);
    const { isAlwaysOpen } = this.props;
    this.state = {
      isShowList: isAlwaysOpen,
      newElements: [],
      lastCreateFieldId: '',
    };
  }

  componentDidUpdate(prevProps) {
    const prevLength = prevProps.itemsData.length;
    const { itemsData } = this.props;
    const currentLength = itemsData.length;
    if (currentLength > prevLength) {
      const { lastCreateFieldId } = this.state;
      this.onCancelCreateItem(lastCreateFieldId);
    }
  }

  onShowListHandler = () => {
    const {
      isShowList,
    } = this.state;
    this.setState({ isShowList: !isShowList });
  }

  onAddNewField = () => {
    let { newElements } = this.state;
    newElements = [...newElements, uuid()];
    this.setState({ newElements });
  }

  onCreateItem = (params) => {
    const { createItem } = this.props;
    const { tempId } = params;
    this.setState({ lastCreateFieldId: tempId });
    createItem(params);
  }

  onEditItem = (params) => {
    const { editItem } = this.props;
    editItem(params);
  }

  onDeleteItem = (params) => {
    const { deleteItem } = this.props;
    deleteItem(params);
  }

  onCancelCreateItem = (deleteItemId) => {
    const { newElements } = this.state;
    const filteredItems = newElements.filter(itemId => itemId !== deleteItemId);
    this.setState({ newElements: filteredItems });
  }

  render() {
    const {
      title,
      ItemComponent,
      addText,
      itemsData,
      filterFunction,
      requestStatus,
      tooltipText,
      isAlwaysOpen,
      ...specificParams
    } = this.props;
    const {
      isShowList,
      newElements,
    } = this.state;

    const itemManagments = {
      createItem: this.onCreateItem,
      editItem: this.onEditItem,
      deleteItem: this.onDeleteItem,
      cancelCreateItem: this.onCancelCreateItem,
    };

    return (
      <div className="entities-container">
        {!isAlwaysOpen && (
        <div>
          <label className="entities-edit-chevron-container">
            <button type="button" onClick={this.onShowListHandler} className="button-add-item">
              {title}
            </button>
            { isShowList ? (
              <FontAwesomeIcon
                className="entities-edit-chevron-icon"
                icon="chevron-up"
              />
            ) : (
              <FontAwesomeIcon
                className="entities-edit-chevron-icon"
                icon="chevron-down"
              />
            )}
          </label>
          {tooltipText && <Tooltip text={tooltipText} />}
        </div>
        )}
        <Collapse
          isOpen={isShowList}
        >
          <ul className="entities-container-edit">
            { itemsData
              .sort((item_first, item_second) => item_first.id - item_second.id)
              .filter(itemData => filterFunction(itemData))
              .map((itemData, i) => (
                <li key={itemData.id} className="create-element-container">
                  <ItemComponent
                    {...itemData}
                    {...specificParams}
                    {...itemManagments}
                    requestStatus={requestStatus}
                    isDefaultField={i === 0}
                  />
                </li>))}
            { newElements
              .map(elementId => (
                <li key={elementId} className="create-field-form-new-container margin-low-vertical">
                  <ItemComponent
                    tempId={elementId}
                    {...specificParams}
                    {...itemManagments}
                    requestStatus={requestStatus}
                  />
                </li>))}
          </ul>
          <button type="button" className="button-add-item margin-low" onClick={this.onAddNewField}>{addText}</button>
        </Collapse>
      </div>
    );
  }
}

EntitiesEditContainer.defaultProps = {
  isAlwaysOpen: false,
  responses: [],
};

EntitiesEditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  ItemComponent: PropTypes.func.isRequired,
  addText: PropTypes.string.isRequired,
  isAlwaysOpen: PropTypes.bool,
  responses: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state, ownProps) => {
  const {
    fieldName,
  } = ownProps;
  const {
    app: { activeTeam },
    [fieldName]: itemsData,
    api: requestStatus,
  } = state;
  return ({
    teamId: activeTeam,
    itemsData,
    requestStatus,
  });
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    onEdit: {
      createItem, editItem, deleteItem,
    },
  } = ownProps;
  return {
    createItem: itemData => dispatch(createItem(itemData)),
    editItem: itemData => dispatch(editItem(itemData)),
    deleteItem: itemData => dispatch(deleteItem(itemData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesEditContainer);
