import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CreateTeamModal from '../components/CreateTeamModal';
import {
  sendInvite,
  createNewTeam,
  removeUserFromTeam,
  deleteTeam,
  toggleAdminRights,
  deleteInvite,
} from '../actions/teams';
import TeamsList from '../components/TeamsList';


const TeamsPage = (props) => {
  const {
    currentUser,
    teams,
    users,
    invitedUsers,
    teamsIds,
    sendInvite,
    createNewTeam,
    removeUserFromTeam,
    inviteStatus,
    createNewTeamStatus,
    location,
    deleteTeam,
    toggleAdminRights,
    deleteInvite,
  } = props;

  return (
    <div className="teams-wrap section-grey">
      <Helmet>
        <title>Компании | Jobs Widget</title>
      </Helmet>
      <div className="teams-heading page-header-container">
        <h1 className="main-page-title">Ваши компании</h1>
        <Link to="/companies">
          <CreateTeamModal
            buttonLabel="Создать компанию"
            createNewTeam={createNewTeam}
            createNewTeamStatus={createNewTeamStatus}
          />
        </Link>
      </div>
      <TeamsList
        currentUser={currentUser}
        teams={teams}
        users={users}
        invitedUsers={invitedUsers}
        items={teamsIds}
        sendInvite={sendInvite}
        deleteInvite={deleteInvite}
        removeUserFromTeam={removeUserFromTeam}
        inviteStatus={inviteStatus}
        location={location}
        deleteTeam={deleteTeam}
        toggleAdminRights={toggleAdminRights}
      />
    </div>
  );
};

TeamsPage.defaultProps = {
  inviteStatus: '',
  createNewTeamStatus: '',
};

TeamsPage.propTypes = {
  currentUser: PropTypes.number.isRequired,
  teams: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  invitedUsers: PropTypes.object.isRequired,
  teamsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sendInvite: PropTypes.func.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  createNewTeam: PropTypes.func.isRequired,
  removeUserFromTeam: PropTypes.func.isRequired,
  inviteStatus: PropTypes.string,
  createNewTeamStatus: PropTypes.string,
  location: PropTypes.object.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  toggleAdminRights: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.app.user,
  teams: state.entities.teams,
  users: state.entities.users,
  invitedUsers: state.entities.invitedUsers,
  teamsIds: state.entities.teamsIds,
  inviteStatus: state.api.inviteStatus,
  createNewTeamStatus: state.api.createNewTeamStatus,
});

const mapDispatchToProps = dispatch => ({
  sendInvite: (email, teamId) => dispatch(sendInvite(email, teamId)),
  deleteInvite: (email, teamId) => dispatch(deleteInvite(email, teamId)),
  createNewTeam: teamName => dispatch(createNewTeam(teamName)),
  removeUserFromTeam: (teamId, userId) => dispatch(removeUserFromTeam(teamId, userId)),
  deleteTeam: team => dispatch(deleteTeam(team)),
  toggleAdminRights: (teamId, userId, isAdmin) => dispatch(toggleAdminRights(teamId, userId, isAdmin)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsPage);
