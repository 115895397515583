import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from './ConfirmModal';


class TeamItems extends React.Component {
  handleDelete = (userId) => {
    const { team, removeUserFromTeam } = this.props;
    removeUserFromTeam(team.id, userId);
  };

  handleToggleRights = (userId, isAdmin) => {
    const { team, toggleAdminRights } = this.props;
    toggleAdminRights(team.id, userId, isAdmin);
  }

  render() {
    const {
      currentUser,
      team,
      team: { users: usersIds, owner },
      users,
    } = this.props;

    if (usersIds.length === 0) {
      return null;
    }

    const isOwner = (id, ownerId = owner) => id === ownerId;
    const isAdmin = (id, adminIds = team.admins) => adminIds.length && adminIds.some(admin => admin === id);
    const getStatusById = (userId) => {
      let status;

      const isUserOwner = isOwner(userId);
      const isUserAdmin = isAdmin(userId);

      if (isUserOwner) {
        status = <FontAwesomeIcon icon="crown" color="orange" />;
      } else if (userId === currentUser) {
        status = <FontAwesomeIcon icon="user-cog" color="orange" />;
      } else {
        status = (
          <ConfirmModal
            type="admin"
            title="owner"
            headerText={`${!isUserAdmin ? 'Вы действительно желаете выдать права администратора сотруднику?'
              : 'Вы действительно желаете забрать права администратора у сотрудника?'}`}
            buttonText={isUserAdmin ? 'Забрать' : 'Выдать'}
            modalAction={() => this.handleToggleRights(userId, isUserAdmin)}
            active={(isUserAdmin || isUserOwner)}
          />
        );
      }
      return status;
    };

    const isCurrentUserOwner = isOwner(currentUser);
    const isCurrentUserAdmin = isAdmin(currentUser);

    const employees = usersIds.map((userId) => {
      const user = users[userId];

      return (
        <tr key={userId}>
          {(isCurrentUserOwner || isCurrentUserAdmin) && (
            <td className="teams-table_status-row">
              {getStatusById(userId)}
            </td>
          )
          }
          <th scope="row">{`${user.last_name} ${user.first_name}`}</th>
          <td>{user.position || 'Менеджер'}</td>
          <td>
            {user.phone
              ? <a href={`tel:${user.phone}`} className="default-text">{user.phone}</a>
              : '—'}
          </td>
          <td><a href={`mailto:${user.email}`} className="default-text">{user.email}</a></td>
          <td className="teams-table-delete">
            {((userId !== currentUser) && (isCurrentUserOwner || (isCurrentUserAdmin && userId !== owner)))
            && (
              <ConfirmModal
                type="icon"
                icon="trash-alt"
                headerText="Удалить пользователя из компании?"
                modalAction={() => this.handleDelete(userId)}
              />
            )
            }
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <Table className="teams-table">
          <thead>
            <tr>
              {(isOwner(currentUser) || isAdmin(currentUser)) && (<th>Статус</th>)}
              <th>ФИО</th>
              <th>Должность</th>
              <th>Телефон</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {employees}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

TeamItems.propTypes = {
  currentUser: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  removeUserFromTeam: PropTypes.func.isRequired,
  toggleAdminRights: PropTypes.func.isRequired,
};

export default TeamItems;
