import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import ConfirmModal from './ConfirmModal';


function InvitedTeamItems(props) {
  const {
    team: { users: employeeIds, invited_users: usersIds, id },
    invitedUsers,
    users,
    deleteInvite,
    canUserDeleteInvite,
  } = props;
  const employeeEmail = employeeIds.map(employeeId => users[employeeId].email);
  const employees = usersIds
    .filter((userId) => {
      const user = invitedUsers[userId];
      return !user.applied;
    })
    .filter(userId => !employeeEmail.includes(invitedUsers[userId].email))
    .map((userId) => {
      const user = invitedUsers[userId];
      return (
        <tr key={userId}>
          <th scope="row"><a href={`mailto:${user.email}`} className="default-text">{user.email}</a></th>
          <td>
            {user.accepted
              ? 'Принято'
              : 'Не принято'
            }
          </td>
          {canUserDeleteInvite
            ? (
              <td className="team-table-td-with-delete">
                <ConfirmModal
                  type="icon"
                  icon="trash-alt"
                  headerText="Удалить приглашение?"
                  modalAction={() => deleteInvite(user.email, id)}
                />
              </td>
            )
            : <td className="team-table-td-without-delete" />
          }
        </tr>
      );
    });
  if (usersIds.some(userId => invitedUsers[userId]) && employees.length) {
    return (
      <React.Fragment>
        <h4 className="customization-form-row">Отправленные приглашения</h4>
        <Table className="teams-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            {employees}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
  return null;
}

InvitedTeamItems.propTypes = {
  team: PropTypes.object.isRequired,
  invitedUsers: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  canUserDeleteInvite: PropTypes.bool.isRequired,
};

export default InvitedTeamItems;
