import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import ArchiveItem from './ArchiveItem';
import withPagination from '../containers/withPagination';
import withSelection from '../containers/withSelection';
import ConfirmModal from './ConfirmModal';
import StyledSelect from './StyledSelect';


class ArchiveList extends React.Component {
  state = {
    activatedMenu: false,
  };

  componentDidUpdate = (prevProps) => {
    const { selectedItems } = this.props;
    const { activatedMenu } = this.state;
    if (activatedMenu && (!selectedItems || selectedItems.length !== prevProps.selectedItems.length)) {
      this.handleActivatedMenu();
    }
  };

    handleActivatedMenu = () => {
      this.setState(state => ({ activatedMenu: !state.activatedMenu }));
    };

  resetSelection = () => {
    const { resetSelection, selectedItems } = this.props;
    if (selectedItems && selectedItems.length) {
      resetSelection('selectedVacancies');
    }
  };

  deleteMultipleVacancies = () => {
    const {
      deleteVacancies,
      resetSelection,
      selectedItems,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      deleteVacancies(selectedItems);
      resetSelection('selectedVacancies');
    }
  };

  archiveMultipleVacancies = () => {
    const {
      editVacancies,
      resetSelection,
      selectedItems,
      allVacancies,
      teamId,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      const archiveVacancies = selectedItems
        .map(vacancyId => ({
          id: vacancyId,
          hidden: !allVacancies
            .find(vacancy => vacancyId === vacancy.id).hidden,
          is_published: false,
        }));
      editVacancies(teamId, archiveVacancies);
      resetSelection('selectedVacancies');
    }
  };

  render() {
    const {
      editVacancy,
      deleteVacancy,
      teamId,
      lastEditFailedVacancy,
      items: vacancies,
      toggleSelection,
      selectedItems,
      handleSortChange,
      sortValue,
      shortId,
    } = this.props;

    const { activatedMenu } = this.state;

    const selectAllItemsOnPage = () => {
      const vacanciesIds = vacancies.map(vacancy => vacancy.id);
      const notSelectedItemsOnPage = vacanciesIds.filter(id => !selectedItems.includes(id));
      if (notSelectedItemsOnPage.length) {
        notSelectedItemsOnPage.forEach(id => toggleSelection(id));
      } else {
        vacanciesIds.forEach(id => toggleSelection(id));
      }
    };

    const selectAllButton = (
      <button
        type="button"
        className="button-add-item span vacancies-icons-wrap-button"
        onClick={selectAllItemsOnPage}
      >
        <span className="button-text">Выбрать все</span>
      </button>
    );

    const multipleChoicePanel = (
      <React.Fragment>
        <ConfirmModal
          type="button-icon"
          buttonText="удалить"
          icon="trash-alt"
          headerText={`При удалении вакансий (${selectedItems.length}), отклики данных вакансии
          также будут удалены.`}
          modalAction={this.deleteMultipleVacancies}
        />
        <ConfirmModal
          type="button-icon"
          icon="undo"
          buttonText="перенести в актуальные"
          headerText={`Перенести в актуальные выбранные вакансии (${selectedItems.length})?`}
          modalAction={this.archiveMultipleVacancies}
        />
      </React.Fragment>
    );
    const sortOptions = [
      { label: 'по дате', value: 'date' },
      { label: 'по архивации', value: 'archiveDate' },
    ];
    const vacanciesPageSort = (
      <React.Fragment>
        <StyledSelect
          options={sortOptions}
          value={sortValue}
          onChange={handleSortChange}
          isSearchable={false}
        />
      </React.Fragment>
    );

    const isVacanciesNotEmpty = vacancies && vacancies
      .filter(vacancy => !vacancy.deleted)
      .length;
    const vacancyItems = isVacanciesNotEmpty
      ? vacancies
        .filter(vacancy => !vacancy.deleted)
        .map(vacancy => (
          <ArchiveItem
            key={vacancy.id}
            teamId={teamId}
            vacancy={vacancy}
            editVacancy={editVacancy}
            publishVacancy={this.publishLimitHandler}
            deleteVacancy={deleteVacancy}
            lastEditFailedVacancy={lastEditFailedVacancy}
            toggleSelection={toggleSelection}
            isSelected={selectedItems.includes(vacancy.id)}
          />
        ))
      : (
        <div className="content-container__empty">
          {teamId
            ? 'На данный момент нет открытых вакансий'
            : 'Создайте компанию для которой вы хотите создать вакансии в разделе "Компании"'}
        </div>
      );

    return (
      <React.Fragment>
        <div className="vacancies-page-sort archive">
          <div className="d-flex align-items-center vacancies-page-selected-actions">
            <div className="button-select-all">
              {selectAllButton}
            </div>
            {selectedItems.length !== 0 && (
              <React.Fragment>
                <span className="icons-wrap-button multiple-select">
                  {selectedItems.length}
                  {' '}
                  выбрано
                </span>
                <button
                  type="button"
                  className="button-add-item span button-multiple"
                  onClick={this.resetSelection}
                >
                  <FontAwesomeIcon
                    className="button-text"
                    icon="times"
                  />
                </button>
                <div className="mobile-button-multiple-menu">
                  <button
                    type="button"
                    className="button-add-item span"
                    onClick={this.handleActivatedMenu}
                  >
                    <span className="vacancy-item-description-elem">действия</span>
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="ellipsis-v"
                    />
                  </button>
                  <Collapse
                    isOpen={activatedMenu}
                    className="mobile-multiple-menu"
                  >
                    {selectAllButton}
                    {multipleChoicePanel}
                  </Collapse>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="vacancies-page-sort-container">
            {vacanciesPageSort}
          </div>
        </div>
        {selectedItems.length !== 0 && (
          <div className="vacancies-page-multiple archive-page-multiple">
            {multipleChoicePanel}
          </div>
        )}
        <div className="vacancies-list content-container content-container_padding_sm">
          {vacancyItems}
        </div>
        <Link to={`/teams/${shortId}/vacancies/`}>
          <button
            type="button"
            className="button-add-item span vacancies-icons-wrap-button"
          >
            <span className="button-text">Список вакансий</span>
          </button>
        </Link>
      </React.Fragment>
    );
  }
}

ArchiveList.defaultProps = {
  lastEditFailedVacancy: null,
  teamId: null,
  shortId: null,
};

ArchiveList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  editVacancies: PropTypes.func.isRequired,
  deleteVacancies: PropTypes.func.isRequired,
  editVacancy: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  lastEditFailedVacancy: PropTypes.number,
  toggleSelection: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetSelection: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  sortValue: PropTypes.object.isRequired,
  allVacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  shortId: PropTypes.string,
};

export default withPagination(withSelection(ArchiveList, { name: 'selectedVacancies' }), 'archive');
