import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorPicker from './ColorPicker';
import SizeSelector from './SizeSelector';


const CustomizationItem = (
  {
    title,
    sizeInfo,
    colors,
    handleSizeChange,
    handleColorChange,
  },
) => {
  const interfaceFontControl = sizeInfo && sizeInfo.value
    ? (
      <div className="customization-item-container">
        <div className="customization-item-description">
          <FontAwesomeIcon
            className="item-icon"
            icon="font"
          />
          <span className="customization-item-description-text">размер шрифта</span>
        </div>
        <SizeSelector
          sizeInfo={sizeInfo}
          handleSizeChange={handleSizeChange}
        />
      </div>
    )
    : null;
  const interfaceColorControls = Object.keys(colors)
    .map(name => (
      <div
        key={name}
        className="customization-item-container"
      >
        <div className="customization-item-description">
          <FontAwesomeIcon
            className="item-icon"
            icon="palette"
          />
          <span className="customization-item-description-text">
            {colors[name].description}
          </span>
        </div>
        <ColorPicker
          colorInfo={{
            name: colors[name].name,
            value: colors[name].value,
          }}
          handleColorChange={handleColorChange}
        />
      </div>
    ));

  return (
    <div className="customization-item">
      <div className="customization-item-title">{title}</div>
      <div>
        {interfaceFontControl}
        {interfaceColorControls}
      </div>
    </div>
  );
};

CustomizationItem.defaultProps = {
  sizeInfo: {
    name: '',
    value: 0,
  },
  handleSizeChange: () => {},
};

CustomizationItem.propTypes = {
  title: PropTypes.string.isRequired,
  sizeInfo: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }),
  colors: PropTypes.object.isRequired,
  handleColorChange: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func,
};

export default CustomizationItem;
