import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import uuid from 'uuid/v4';
import withLoading from './withLoading';
import {
  REPORT_BY_MANAGERS,
  REPORT_BY_SOURCES,
} from '../utils/reportUtils';


const ReportTable = (props) => {
  const {
    report,
    activeTeamShortId,
    startInputDate,
    endInputDate,
    onSort,
    reportType,
  } = props;

  const queryParamName = reportType === REPORT_BY_MANAGERS ? 'manager' : 'vacancy';

  const withLink = (value, searchParams, vacancyId = null) => {
    const vacancy = (reportType !== REPORT_BY_MANAGERS && vacancyId) ? `/${vacancyId}/` : '/';
    return (
      <Link
        to={`teams/${activeTeamShortId}/vacancies${vacancy}responses/?${searchParams.toString()}`}
      >
        {value}
      </Link>
    );
  };

  const getAdditionalParams = (itemInfo) => {
    if (reportType === REPORT_BY_SOURCES) {
      const { id = null, internal_source = null } = itemInfo;
      const additionalParams = { source: id };
      if (internal_source) {
        return { ...additionalParams, internal_source: JSON.stringify(internal_source) };
      }
      return additionalParams;
    }

    return { status: itemInfo.id || 'created' };
  };

  const getSearchParams = (queryParams) => {
    const searchParams = new URLSearchParams({
      date_from: startInputDate.toISOString().split('T')[0],
      date_to: endInputDate.toISOString().split('T')[0],
      ...queryParams,
    });
    return searchParams;
  };

  const renderColumnTitle = report.length && report[0].items
    .map(({ name }) => (
      <th
        key={name}
        className="report-table-hiring-title text-center align-middle"
        onClick={() => onSort(name)}
      >
        {name}
      </th>
    ));

  const renderTableRows = report && report
    .filter(({ id }) => id)
    .map((reportItem) => {
      const rowData = reportItem.items.map((itemInfo) => {
        const queryParams = getSearchParams({ [queryParamName]: reportItem.id, ...getAdditionalParams(itemInfo) });
        return (
          <td className="text-center align-middle" key={uuid()}>
            {withLink(itemInfo.amount, queryParams)}
          </td>
        );
      });

      return (
        <tr key={reportItem.id}>
          <th className="report-table-hiring-title text-center align-middle">
            {reportItem.title}
          </th>
          {rowData}
        </tr>
      );
    });

  const renderSumRow = () => {
    const sumInfo = report && report.reduce((acc, reportItem) => {
      reportItem.items.forEach(({
        name, amount, id = null, internal_source = null,
      }) => {
        if (!acc.hasOwnProperty(name)) {
          acc[name] = amount;
          acc.id = id;
          acc.internal_source = internal_source;
        } else {
          acc[name] += amount;
        }
      });
      return acc;
    }, {});

    const sumRow = report.length && report[0].items.map(itemInfo => (
      <td className="text-center" key={`${sumInfo[itemInfo.name]}${itemInfo.name}`}>
        {withLink(sumInfo[itemInfo.name], getSearchParams(getAdditionalParams(itemInfo)))}
      </td>
    ));

    return (
      <tr>
        <th className="report-table-hiring-title text-center align-middle">
            Всего
        </th>
        {sumRow}
      </tr>
    );
  };

  return (
    report.length
      ? (
        <div className="content-container report-table-wrap">
          <Table className="teams-table report-table" responsive>
            <thead>
              <tr>
                <th className="report-table-hiring-title text-center align-middle">
                  {reportType === REPORT_BY_SOURCES ? 'Источник' : 'Статус'}
                </th>
                {renderColumnTitle}
              </tr>
            </thead>
            <tbody>
              {renderTableRows}
              {renderSumRow()}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="content-container content-container_padding_sm">
          <div className="content-container__empty">За данный период времени отклики не изменялись</div>
        </div>
      )
  );
};

ReportTable.propTypes = {
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
  startInputDate: PropTypes.object.isRequired,
  endInputDate: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
};

export default withLoading(ReportTable);
