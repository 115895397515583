export const GET_FIELDS_REQUEST = 'GET_FIELDS_REQUEST';
export const GET_FIELDS_SUCCESS = 'GET_FIELDS_SUCCESS';
export const GET_FIELDS_FAIL = 'GET_FIELDS_FAIL';

export const CREATE_FIELD_REQUEST = 'CREATE_FIELD_REQUEST';
export const CREATE_FIELD_SUCCESS = 'CREATE_FIELD_SUCCESS';
export const CREATE_FIELD_FAIL = 'CREATE_FIELD_FAIL';

export const EDIT_FIELD_REQUEST = 'EDIT_FIELD_REQUEST';
export const EDIT_FIELD_SUCCESS = 'EDIT_FIELD_SUCCESS';
export const EDIT_FIELD_FAIL = 'EDIT_FIELD_FAIL';

export const DELETE_FIELD_REQUEST = 'DELETE_FIELD_REQUEST';
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS';
export const DELETE_FIELD_FAIL = 'DELETE_FIELD_FAIL';
