import { normalize } from 'normalizr';
import { WITH_LOADING_OFF, WITH_LOADING_ON } from '../constants';


const callApiMiddleware = ({ dispatch, getState }) => next => (action) => {
  const {
    types,
    callApi,
    name = 'data',
    normalizeSchema,
    afterSuccessCall = () => {}, // arguments: (response, dispatch, getState)
    afterRequestCall = () => {},
    shouldCallApi = () => true,
    payload = {},
    withLoading = false,
  } = action;

  if (!types) {
    return next(action);
  }

  if (
    !Array.isArray(types)
    || (types.length !== 3)
    || !types.every(type => typeof type === 'string')
  ) {
    throw new Error('Expected an array of three string types.');
  }

  if (typeof callApi !== 'function') {
    throw new Error('Expected callAPI to be a function.');
  }

  if (!shouldCallApi(getState())) {
    return false;
  }

  const [requestType, successType, failureType] = types;

  if (withLoading) {
    dispatch({ type: WITH_LOADING_ON });
  }

  dispatch(
    { ...payload, type: requestType },
  );
  return callApi()
    .then((response) => {
      const responseData = normalizeSchema ? normalize(response.data, normalizeSchema) : response.data;
      dispatch({ type: successType, ...payload, [name]: responseData });
      afterSuccessCall(response.data, dispatch, getState);
    })
    .catch((error) => {
      dispatch({ type: failureType, ...payload, error: error.response && error.response.data });
      if (process.env.NODE_ENV !== 'production') {
        console.log(error.response);
      }
    })
    .finally(() => {
      if (withLoading) {
        dispatch({ type: WITH_LOADING_OFF });
      }
      afterRequestCall();
    });
};

export default callApiMiddleware;
