export const GET_SOURCES_REQUEST = 'GET_SOURCES_REQUEST';
export const GET_SOURCES_SUCCESS = 'GET_SOURCES_SUCCESS';
export const GET_SOURCES_FAIL = 'GET_SOURCES_FAIL';

export const CREATE_SOURCES_REQUEST = 'CREATE_SOURCES_REQUEST';
export const CREATE_SOURCES_SUCCESS = 'CREATE_SOURCES_SUCCESS';
export const CREATE_SOURCES_FAIL = 'CREATE_SOURCES_FAIL';

export const EDIT_SOURCES_SUCCESS = 'EDIT_SOURCES_SUCCESS';
export const EDIT_SOURCES_REQUEST = 'EDIT_SOURCES_REQUEST';
export const EDIT_SOURCES_FAIL = 'EDIT_SOURCES_FAIL';

export const DELETE_SOURCES_REQUEST = 'DELETE_SOURCES_REQUEST';
export const DELETE_SOURCES_SUCCESS = 'DELETE_SOURCES_SUCCESS';
export const DELETE_SOURCES_FAIL = 'DELETE_SOURCES_FAIL';

export const MANAGE_HIRING_STATE_FAIL = 'MANAGE_HIRING_STATE_FAIL';
