import React from 'react';
import PropTypes from 'prop-types';


const ResponseQuestionsList = (props) => {
  const { questions, response } = props;
  return questions && response && response.question_answers && response.question_answers.map(
    (answer) => {
      const question = questions.find(question => answer.question === question.id) || {};
      return (
        <div className="response-question" key={answer.id}>
          <div className="label response-question__label">Вопрос</div>
          <div dangerouslySetInnerHTML={{ __html: question.message }} />
          <div className="label response-question__label">Ответ</div>
          <div>{answer.text}</div>
        </div>
      );
    },
  );
};

ResponseQuestionsList.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  response: PropTypes.object.isRequired,
};

export default ResponseQuestionsList;
