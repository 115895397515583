import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../containers/Login';
import SignUp from '../containers/SignUp';
import ConsentToPersonalDataProcessing from '../components/ConsentToPersonalDataProcessing';
import SignUpSuccessPage from '../components/SignUpSuccessPage';
import PasswordRestore from '../containers/PasswordRestore';
import RestoreSuccessPage from '../components/RestoreSuccessPage';
import ChangePasswordAfterRestorePage from '../containers/ChangePasswordAfterRestorePage';
import AllVacanciesPage from '../containers/AllVacanciesPage';
import VacancyPage from '../containers/VacancyPage';


const PublicRoutes = () => (
  <div className="container-fluid p-0">
    <Switch>
      <Route
        path="/all-vacancies"
        exact
        component={AllVacanciesPage}
      />
      <Route
        path="/all-vacancies/:id"
        exact
        component={VacancyPage}
      />
      <Route
        path="/login"
        exact
        component={Login}
      />
      <Route
        path="/signup"
        exact
        component={SignUp}
      />
      <Route
        path="/signup/success"
        exact
        component={SignUpSuccessPage}
      />
      <Route
        path="/contest-to-personal-data-processing"
        exact
        component={ConsentToPersonalDataProcessing}
      />
      <Route
        path="/restore"
        exact
        component={PasswordRestore}
      />
      <Route
        path="/restore/success"
        exact
        component={RestoreSuccessPage}
      />
      <Route
        path="/password/reset/:uid/:token"
        exact
        component={ChangePasswordAfterRestorePage}
      />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  </div>
);

export default PublicRoutes;
