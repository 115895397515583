import React, { createRef } from 'react';
import PropsTypes from 'prop-types';
import { Input, Collapse } from 'reactstrap';


class AddComment extends React.Component {
  constructor(props) {
    super(props);
    this.commentInputRef = createRef();
  }

  state = {
    message: '',
    activated: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { activated } = this.state;
    if (activated && !prevState.activated) {
      setTimeout(() => {
        this.commentInputRef.focus();
      }, 200);
    }
  }

  handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleClick = () => {
    this.setState(state => ({ activated: !state.activated }));
  };

  addComment = () => {
    const { message } = this.state;
    const { addComment, response } = this.props;
    addComment(response, { message });
    this.setState({
      activated: false,
      message: '',
    });
  };

  handleCancel = () => {
    this.setState({
      activated: false,
      message: '',
    });
  };

  render() {
    const { activated, message } = this.state;
    return (
      <React.Fragment>
        <Collapse
          isOpen={activated}
          className="team-container-collapse"
        >
          <Input
            className="comment-textarea"
            type="textarea"
            name="message"
            value={message}
            onChange={this.handleInputChange}
            innerRef={(inputRef) => {
              this.commentInputRef = inputRef;
            }}
          />
          <div className="buttons-container">
            <button
              onClick={this.addComment}
              type="button"
              className="button"
            >
              Сохранить
            </button>
            <button
              onClick={this.handleCancel}
              type="button"
              className="button button_color_dark-blue"
            >
              Отмена
            </button>
          </div>
        </Collapse>
        <Collapse
          isOpen={!activated}
          className="team-container-collapse"
        >
          <button
            onClick={this.handleClick}
            className="button-add-item"
            type="button"
          >
            <span>+</span>
            Добавить комментарий
          </button>
        </Collapse>
      </React.Fragment>
    );
  }
}

AddComment.propTypes = {
  response: PropsTypes.object.isRequired,
  addComment: PropsTypes.func.isRequired,
};

export default AddComment;
