import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import renderField from './render/renderField';
import validateSignUpForm from './validate/validateSignUpForm';


const SignUpForm = (props) => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="firstName"
        type="text"
        component={renderField}
        label="Имя"
      />
      <Field
        name="lastName"
        type="text"
        component={renderField}
        label="Фамилия"
      />
      <Field
        name="email"
        type="email"
        component={renderField}
        label="Email"
      />
      <Field
        name="password"
        type="password"
        component={renderField}
        label="Пароль"
      />
      <Field
        name="confirmPassword"
        type="password"
        component={renderField}
        label="Подтвердите пароль"
      />
      <div className="auth-btns-wrap">
        <button
          type="submit"
          className="button button_color_dark-blue"
          disabled={submitting}
        >
          Зарегистрироваться
        </button>
        {' '}
        <Link to="/login">Войти</Link>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'signUp',
  validate: validateSignUpForm,
})(SignUpForm);
