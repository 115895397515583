import React from 'react';
import { change, Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderField from '../forms/render/renderField';
import ConfirmModal from './ConfirmModal';
import StatusMessage from '../containers/StatusMessage';
import ErrorMessage from './ErrorMessage';
import { manageHiringStateError } from '../actions/hiringTemplatesStates';


class EditSources extends React.Component {
  constructor(props) {
    super(props);
    const { id, name } = props;
    const isNew = id === -1;
    this.state = {
      isNew,
      id,
      fieldName: name || '',
    };
  }

  createHandler = (id) => {
    const { createItem, teamId } = this.props;
    const { fieldName } = this.state;
    createItem({
      name: fieldName, id, tempId: id, team: teamId,
    });
  }

  editHandler = () => {
    const { editItem, teamId } = this.props;
    const { id, fieldName } = this.state;
    editItem({ id, name: fieldName, team: teamId });
  }

  deleteHandler = () => {
    const { deleteItem, teamId } = this.props;
    const { id } = this.state;
    deleteItem({ id, team: teamId });
  }

  cancelCreateHandler = () => {
    const { cancelCreateItem, tempId, change } = this.props;
    change(`source-name-${tempId}`);
    cancelCreateItem(tempId);
  }

  onNameChange = event => this.setState({ fieldName: event.target.value });

  render() {
    const {
      labelName,
      isLabelNeed,
      newLabelName,
      tempId,
      requestStatus,
      manageHiringStateError,
      sources,
      lastEditFailedSource,
      lastCreatedFailedSource,
      valueFromForm,
      is_default,
      responses,
    } = this.props;

    const { fieldName } = this.state;
    const { id, isNew } = this.state;
    const label = isNew ? newLabelName : labelName;

    const currentId = id !== -1 ? id : tempId;

    const isFieldAlreadyExistCreate = sources
      .filter(state => state.name === fieldName).length > 0 && isNew;

    const isFieldAlreadyExistEdit = sources
      .filter(state => state.name === fieldName && currentId !== state.id).length > 0 && !isNew;

    const errorCreateMessage = isFieldAlreadyExistCreate
      ? 'Данное состояние уже существует, вы не можете его добавить'
      : '';

    const errorEditMessage = isFieldAlreadyExistEdit
      ? 'Данное состояние уже существует, вы не можете использовать данное название'
      : '';

    const responsesOfSource = responses
      .filter(({ source }) => source && source.id === currentId)
      .map(({ name }) => name);

    const deleteModalText = responsesOfSource.length > 0
      ? 'Данный источник используеться в откликах, информация в откликах об источнике будет так же удалена'
      : '';

    const isFieldNotEmpty = valueFromForm && valueFromForm.trim() !== '';

    const sourceValue = !isNew && sources.find(({ id: sourcesId }) => sourcesId === id);
    const isValuesSame = valueFromForm === sourceValue.name;

    const isSourceWasEdited = requestStatus.editSourceDescription && requestStatus.editSourceDescription.data
      && requestStatus.editSourceDescription.data.id === currentId;

    const isSourceWasCreated = requestStatus.createSourceDescription && requestStatus.createSourceDescription.data
    && requestStatus.createSourceDescription.data.id === currentId;

    const isSourceWasDeleted = requestStatus.deleteSourceDescription
    && requestStatus.deleteSourceDescription.id === currentId ? 'deleteSource' : '';

    return (
      <div className="create-hiring-state-layout">
        <div className="create-hiring-state-layout-main">
          <div className="create-hiring-state-input">
            <Field
              name={`source-name-${currentId}`}
              label={isLabelNeed ? label : ''}
              placeholder="Источник"
              type="text"
              onChange={this.onNameChange}
              component={renderField}
              disabled={is_default}
            />
          </div>
          <div className="buttons-circle-container">
            {!isNew
              ? (
                !is_default && (
                <React.Fragment>
                  <button
                    type="button"
                    className={
                      `button-circle ${(!isFieldNotEmpty || isValuesSame) ? '' : 'button-circle_color_light-green'}`
                    }
                    onClick={errorEditMessage
                      ? () => manageHiringStateError(currentId, errorEditMessage)
                      : this.editHandler}
                    disabled={!isFieldNotEmpty || isValuesSame}
                  >
                    <FontAwesomeIcon
                      icon="check"
                    />
                  </button>
                  <ConfirmModal
                    modalAction={this.deleteHandler}
                    type="circle-button-icon"
                    buttonText="Удалить поле"
                    headerText={`Вы действительно хотите удалить данное поле?
                      ${responsesOfSource.length ? deleteModalText : ''}`}
                  />
                </React.Fragment>
                ))
              : (
                <React.Fragment>
                  <button
                    type="button"
                    className={`button-circle ${!isFieldNotEmpty ? '' : 'button-circle_color_light-green'}`}
                    onClick={errorCreateMessage
                      ? () => manageHiringStateError(currentId, errorCreateMessage)
                      : () => this.createHandler(currentId)
                    }
                    disabled={!isFieldNotEmpty}
                  >
                    <FontAwesomeIcon
                      icon="check"
                    />
                  </button>
                  <button
                    type="button"
                    className="button-circle"
                    onClick={this.cancelCreateHandler}
                  >
                    <FontAwesomeIcon
                      icon="trash-alt"
                    />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
        {(isSourceWasCreated && !isSourceWasEdited) && (
          <StatusMessage
            errorMessage={errorCreateMessage}
            successMessage="Источник успешно создан"
            status="createSource"
          />
        )}

        {isSourceWasEdited && (
          <StatusMessage
            status="editSource"
            successMessage="Источник успешно изменен"
          />
        )}

        <StatusMessage
          status={isSourceWasDeleted}
          errorMessage="Произошла ошибка, источник не удалён"
        />

        {lastEditFailedSource === id && <ErrorMessage errorName="editSourceError" />}
        {isFieldAlreadyExistEdit && <ErrorMessage errorName="managmentHiringState" />}
        {isFieldAlreadyExistCreate && <ErrorMessage errorName="managmentHiringState" />}
        {lastCreatedFailedSource === currentId && <ErrorMessage errorName="createSourceError" />}
      </div>);
  }
}

EditSources.defaultProps = {
  labelName: 'название',
  createItem: () => {},
  deleteItem: () => {},
  editItem: () => {},
  name: '',
  isLabelNeed: true,
  id: -1,
  newLabelName: 'название',
  cancelCreateItem: () => {},
  tempId: '',
  requestStatus: {},
  lastEditFailedSource: null,
  lastCreatedFailedSource: null,
  valueFromForm: null,
  is_default: false,
  teamId: null,
};

EditSources.propTypes = {
  labelName: PropTypes.string,
  createItem: PropTypes.func,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
  name: PropTypes.string,
  isLabelNeed: PropTypes.bool,
  id: PropTypes.number,
  newLabelName: PropTypes.string,
  cancelCreateItem: PropTypes.func,
  tempId: PropTypes.string,
  requestStatus: PropTypes.object,
  manageHiringStateError: PropTypes.func.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueFromForm: PropTypes.string,
  lastEditFailedSource: PropTypes.number,
  lastCreatedFailedSource: PropTypes.string,
  is_default: PropTypes.bool,
  change: PropTypes.func.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamId: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const { sources, app: { lastEditFailedSource, lastCreatedFailedSource, activeTeam } } = state;
  const { id, tempId } = ownProps;
  const fieldId = id || tempId;
  const selector = formValueSelector('editSourcesForm');
  const valueFromForm = selector(state, `source-name-${fieldId}`);
  return {
    teamId: activeTeam,
    sources,
    lastEditFailedSource,
    lastCreatedFailedSource,
    valueFromForm,
  };
};

const mapDispatchToProps = dispatch => ({
  manageHiringStateError: (stateId, errorMessage) => dispatch(manageHiringStateError(stateId, errorMessage)),
  change: (fieldName, newValue = '') => dispatch(change('editSourcesForm', fieldName, newValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSources);
