import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import StyledSelect from '../../components/StyledSelect';
import FieldError from '../../components/FieldError';
import Tooltip from '../../components/Tooltip';


class renderSelect extends Component {
  handleChange = (newValue) => {
    const { input: { onChange } } = this.props;
    onChange(newValue);
  };

  render() {
    const {
      input: { value },
      meta: { touched, error },
      label,
      options,
      noOptionsMessage,
      placeholder,
      disabled,
      tooltipText,
      ...rest
    } = this.props;
    return (
      <FormGroup className="render-creatable-select-container">
        <Label>{label}</Label>
        {tooltipText && <Tooltip text={tooltipText} />}
        <StyledSelect
          onChange={this.handleChange}
          options={options}
          value={value}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          isDisabled={disabled}
          {...rest}
        />
        {touched && error && <FieldError error={error} />}
      </FormGroup>
    );
  }
}

renderSelect.defaultProps = {
  noOptionsMessage: 'Не найдено',
  placeholder: 'Выберите...',
  disabled: false,
  tooltipText: '',
};

renderSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]).isRequired,
    }).isRequired,
  ).isRequired,
  tooltipText: PropTypes.string,
};

export default renderSelect;
