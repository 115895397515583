export const GET_PAYMENT_INFO_REQUEST = 'GET_PAYMENT_INFO_REQUEST';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';
export const GET_PAYMENT_INFO_FAIL = 'GET_PAYMENT_INFO_FAIL';

export const UPDATE_PAYMENT_INFO_REQUEST = 'UPDATE_PAYMENT_INFO_REQUEST';
export const UPDATE_PAYMENT_INFO_SUCCESS = 'UPDATE_PAYMENT_INFO_SUCCESS';
export const UPDATE_PAYMENT_INFO_FAIL = 'UPDATE_PAYMENT_INFO_FAIL';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAIL = 'APPLY_COUPON_FAIL';
