import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './render/renderField';
import validateChangeEmailForm from './validate/validateChangeEmailForm';
import StatusMessage from '../containers/StatusMessage';


const ChangeEmailForm = (props) => {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="email"
        component={renderField}
        label="Email"
      />
      <button
        type="submit"
        className="button"
        disabled={pristine || submitting}
      >
        Сменить
      </button>
      <StatusMessage
        status="changeEmailStatus"
        successMessage="Письмо успешно отправлено"
        errorMessage="Данный email уже используется."
      />
    </form>
  );
};

ChangeEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'changeEmail',
  validate: validateChangeEmailForm,
})(ChangeEmailForm);
