import axios from '../api';
import {
  CREATE_SOURCES_FAIL,
  CREATE_SOURCES_REQUEST,
  CREATE_SOURCES_SUCCESS,
  DELETE_SOURCES_FAIL,
  DELETE_SOURCES_REQUEST,
  DELETE_SOURCES_SUCCESS,
  EDIT_SOURCES_FAIL,
  EDIT_SOURCES_REQUEST,
  EDIT_SOURCES_SUCCESS,
  GET_SOURCES_FAIL,
  GET_SOURCES_REQUEST,
  GET_SOURCES_SUCCESS,
} from '../constants/sourcesConst';


export const getSources = teamId => ({
  types: [GET_SOURCES_REQUEST, GET_SOURCES_SUCCESS, GET_SOURCES_FAIL],
  callApi: () => axios.get(`team/${teamId}/sources/`),
});

export const createSource = ({ name, tempId, team }) => ({
  types: [CREATE_SOURCES_REQUEST, CREATE_SOURCES_SUCCESS, CREATE_SOURCES_FAIL],
  callApi: () => axios.post(`team/${team}/sources/`, { name }),
  payload: { tempId },
});

export const editSource = ({ id, name, team }) => ({
  types: [EDIT_SOURCES_REQUEST, EDIT_SOURCES_SUCCESS, EDIT_SOURCES_FAIL],
  payload: { id },
  callApi: () => axios.patch(`team/${team}/sources/${id}/`, { name }),
});

export const deleteSource = ({ id, team }) => ({
  types: [DELETE_SOURCES_REQUEST, DELETE_SOURCES_SUCCESS, DELETE_SOURCES_FAIL],
  payload: { id },
  callApi: () => axios.delete(`team/${team}/sources/${id}/`),
});
