import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import FieldError from '../../components/FieldError';


const renderCheckbox = (
  {
    input,
    meta: { touched, error },
    label,
    tooltipText,
    classNames,
    ...rest
  },
) => (
  <React.Fragment>
    <div className={`render-checkbox-container ${classNames}`}>
      <Label className="checkbox-container">
        {label}
        <Input
          {...input}
          {...rest}
          className="render-checkbox-input checkbox-hidden"
          type="checkbox"
        />
        <span className="render-checkbox-text checkbox-mark" />
      </Label>
      {touched && error && <FieldError error={error} />}
    </div>
  </React.Fragment>
);

renderCheckbox.defaulProps = {
  classNames: '',
};

renderCheckbox.propType = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  classNames: PropTypes.string,
};

export default renderCheckbox;
