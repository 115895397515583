import React from 'react';
import PropTypes from 'prop-types';


const TariffText = (props) => {
  const getTariffText = () => {
    const {
      team: {
        payment_status: paymentStatus,
        subscription_end_date,
      },
    } = props;
    if (paymentStatus === 'PAYED') {
      const MILLISECONDS_IN_DAY = 1000 * 3600 * 24;
      const daysLeft = Math.ceil(
        (Date.parse(subscription_end_date) - Date.now()) / MILLISECONDS_IN_DAY,
      );
      return `Тариф: Бизнес (осталось дней: ${daysLeft})`;
    }
    return 'Тариф: Базовый';
  };

  return <div className="teams-item-tariff">{getTariffText()}</div>;
};

TariffText.propTypes = {
  team: PropTypes.object.isRequired,
};

export default TariffText;
