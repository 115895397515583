import React from 'react';
import PropTypes from 'prop-types';

import CommentItem from './CommentItem';


const CommentsList = (props) => {
  const {
    comments,
    response,
    editComment,
    deleteComment,
    userId,
    teamAdmins,
  } = props;
  if (comments.length) {
    return props.comments.map(comment => (
      <CommentItem
        userId={userId}
        teamAdmins={teamAdmins}
        key={comment.id}
        comment={comment}
        response={response}
        editComment={editComment}
        deleteComment={deleteComment}
      />
    ));
  }
  return <div className="no-comments">Нет комментариев</div>;
};

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  response: PropTypes.object.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  teamAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CommentsList;
