import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';

import Breadcrumbs from './Breadcrumbs';
import Tooltip from './Tooltip';
import WebSocketInstance from '../utils/websocket';
import withSelection from '../containers/withSelection';
import { getHhVacancies } from '../actions/teams';
import { TOOLTIP_TEXTS } from '../utils';
import ImportVacanciesList from './ImportVacanciesList';
import { resetSelection } from '../actions/selection';
import HhModal from '../containers/HhModal';


class ImportHhVacancies extends Component {
  state = {
    hhVacancies: [],
  }

  componentDidMount() {
    const {
      getHhVacancies, hhEmployerId, teamId, currentPage,
    } = this.props;
    if (hhEmployerId) {
      getHhVacancies(hhEmployerId, teamId, currentPage - 1);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      hhVacancies: { HhVacancies = [] },
      currentPage,
      hhEmployerId,
      teamId,
      getHhVacancies,
    } = this.props;

    if (!isEqual(HhVacancies, prevState.hhVacancies)) {
      this.onHhVacanciesUpdate(HhVacancies);
    }

    if (currentPage !== prevProps.currentPage) {
      getHhVacancies(hhEmployerId, teamId, currentPage - 1);
    }
  }

  onHhVacanciesUpdate = (newHhVacancies) => {
    this.setState({ hhVacancies: newHhVacancies });
  }

  onShowListHandler = (listName) => {
    const { [listName]: currentListNameState } = this.state;
    this.setState({ [listName]: !currentListNameState });
  }

  getVacancies = () => {
    const {
      vacancies,
      selectedItems,
      toggleSelection,
      activeTeamShortId,
    } = this.props;

    const { hhVacancies } = this.state;

    const alreadyExistHhVacancyIds = vacancies && vacancies
      .map(({ hh_id }) => hh_id);
    return hhVacancies && hhVacancies
      .map(({ id, name }) => {
        const isExist = alreadyExistHhVacancyIds.includes(id);
        let jwVacancyId = null;
        if (isExist) {
          jwVacancyId = vacancies.find(({ hh_id }) => id === hh_id).id;
        }

        return (
          <li key={id} className="d-flex mb-1">
            <span className="container-title-checkbox">
              <label className="checkbox-container align-items-center">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(id)}
                  onChange={() => toggleSelection(id)}
                  className="checkbox-hidden"
                />
                <span className="checkbox-mark" />
              </label>
            </span>
            {isExist
              ? (
                <Link
                  to={`/teams/${activeTeamShortId}/vacancies/${jwVacancyId}`}
                  className="import-old-vacancy-item"
                  target="_blank"
                >
                  {name}
                </Link>
              ) : (
                <span className="import-new-vacancy-item">{name}</span>
              )}
          </li>
        );
      });
  }

  onImportHandler = () => {
    const { selectedItems, teamId, resetSelection } = this.props;
    WebSocketInstance.importHhVacancies(teamId, selectedItems);
    resetSelection('selectedHhVacancies');
  }

  render() {
    const {
      selectedItems,
      location,
      teamId,
      hhVacancies: {
        totalAmount,
        currentPage,
      },
      activeTeamShortId,
      hhEmployerId,
    } = this.props;

    const breadcrumbs = [
      { title: 'Вакансии', link: `/teams/${activeTeamShortId}/vacancies` },
      { title: 'Импорт', link: '' },
    ];

    return (
      <div className="vacancies-wrap section-grey import-vacancies-wrapper">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <span className="page-header-container justify-content-start">
          <h1 className="main-page-title">
            Импорт вакансий
            <Tooltip
              text={TOOLTIP_TEXTS.importHhVacancies}
              className="main-page-title__tooltip"
            />
          </h1>
        </span>
        <div className="d-flex import-vacancies-import-button-wrapper">
          <button
            type="button"
            className="button mb-3 vacancies-import-button"
            onClick={this.onImportHandler}
            disabled={!selectedItems.length}
          >
            Импортировать
            {selectedItems.length > 0 && (
              <span>{`(${selectedItems.length})`}</span>
            )}
          </button>
          <button
            type="button"
            className="button button_color_dark-blue vacancies-import-button"
            onClick={this.onImportHandler}
            disabled={!hhEmployerId}
          >
              Импортировать все вакансии
          </button>
        </div>
        {hhEmployerId
          ? (
            <ImportVacanciesList
              title="Новые вакансии"
              items={this.getVacancies()}
              totalAmount={totalAmount}
              currentPage={currentPage}
              tooltipText={TOOLTIP_TEXTS.importNewVacanciesFromHh}
              location={location}
            />
          ) : (
            <div className="vacancies-list content-container">
              <span>Для импорта необходимо</span>
              <button className="button-add-item" type="button">
                <HhModal
                  teamId={teamId}
                  triggerType="string"
                  triggerText="&nbsp;указать&nbsp;"
                />
              </button>
              <span>id компании из Head Hunter.</span>
            </div>
          )}
      </div>
    );
  }
}

ImportHhVacancies.defaultProps = {
  hhEmployerId: null,
  hhVacancies: null,
};

ImportHhVacancies.propTypes = {
  hhEmployerId: PropTypes.string,
  location: PropTypes.object.isRequired,
  hhVacancies: PropTypes.arrayOf(PropTypes.object),
  getHhVacancies: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleSelection: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    api: { importHhVacanciesStatus },
    app: {
      activeTeam, activeTeamShortId, lastEditFailedVacancy,
    },
    entities: { teams },
  } = state;

  const team = teams[activeTeam] || {};
  const { hh_employer_id = null, hhVacancies = {} } = team;

  const { location } = ownProps;
  const locationSearch = new URLSearchParams(location.search);
  const currentPage = +locationSearch.get('page') || 1;

  return {
    hhEmployerId: hh_employer_id,
    teamId: activeTeam,
    vacancies: team.vacancies && team.vacancies
      .filter(vacancy => !vacancy.deleted)
      .filter(vacancy => !vacancy.hidden),
    lastEditFailedVacancy,
    importHhVacanciesStatus,
    activeTeamShortId,
    hhVacancies,
    currentPage,
  };
};

const mapDispatchToProps = dispatch => ({
  getHhVacancies: (teamHhId, activeTeam, page) => dispatch(getHhVacancies(teamHhId, activeTeam, page)),
  resetSelection: () => dispatch(resetSelection('selectedHhVacancies')),
});

export default withRouter(withSelection(
  connect(mapStateToProps, mapDispatchToProps)(ImportHhVacancies),
  { name: 'selectedHhVacancies' },
));
