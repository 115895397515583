import {
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  EDIT_COMMENT_SUCCESS,
  EDIT_RESPONSE_SUCCESS,
  GET_RESPONSES_SUCCESS,
  MARK_RESPONSE_AS_VIEWED_SUCCESS,
  CREATE_RESPONSE_SUCCESS,
  GET_RESPONSE_SUCCESS,
  DELETE_RESPONSES_SUCCESS,
  MOVE_RESPONSE_SUCCESS,
} from '../constants/responsesConst';

import { createReducer } from '../utils/reducerUtils';


const createResponse = (state, action) => {
  const { responses } = state;
  let { totalAmount = 0 } = state;
  totalAmount += 1;
  return { ...state, totalAmount, responses: [...responses, action.response] };
};

const getResponses = (state, { data: { results, total_amount, has_not_viewed = null } }) => {
  const editedState = { ...state, responses: [...results], totalAmount: total_amount };
  if (has_not_viewed !== null) {
    editedState.hasUnviewedResponse = has_not_viewed;
  }

  return { ...editedState };
};

const getResponse = (state, { data }) => ({ ...state, responses: [{ ...data }] });

const editResponse = (state, action) => {
  const { responses } = state;
  const editedResponses = responses.slice();
  const responseIndex = responses.findIndex(
    response => response.id === action.data.id,
  );
  editedResponses[responseIndex] = action.data;
  return { ...state, responses: editedResponses };
};

const markResponseAsViewed = (state, action) => {
  const { responses } = state;
  const editedResponses = responses.slice();
  const responseIndex = responses.findIndex(
    response => response.id === action.responseId,
  );
  editedResponses[responseIndex] = { ...editedResponses[responseIndex], viewed: true };
  const hasUnviewedResponse = !editedResponses.every(({ viewed }) => viewed);
  return { ...state, responses: editedResponses, hasUnviewedResponse };
};


const addComment = (state, action) => {
  const { responses } = state;
  const editedResponses = responses.slice();
  editedResponses.forEach((response) => {
    if (response.id === action.response.id) {
      response.comments = [...response.comments, action.data];
    }
  });

  return { ...state, responses: editedResponses };
};

const editComment = (state, action) => {
  const { responses } = state;
  const editedResponses = [...responses].map((response) => {
    if (response.id === action.response.id) {
      response.comments = response.comments.map(
        comment => (comment.id === action.comment.id ? action.comment : comment),
      );
    }
    return response;
  });

  return { ...state, responses: editedResponses };
};

const deleteComment = (state, action) => {
  const { responses } = state;
  const editedResponses = [...responses].map((response) => {
    if (response.id === action.response.id) {
      response.comments = response.comments.filter(
        comment => action.comment.id !== comment.id,
      );
    }
    return response;
  });

  return { ...state, responses: editedResponses };
};

const deleteResponses = (state, action) => {
  const { responses } = state;
  const { ids } = action;
  const editedResponses = responses.filter((({ id }) => !ids.includes(id)));
  return { ...state, responses: editedResponses, totalAmount: editedResponses.length };
};

const moveResponse = (state, action) => {
  const { responses } = state;
  const { data: newResponse } = action;
  const editedResponses = responses.map((response) => {
    if (response.id === newResponse.id) {
      response.vacancy = newResponse.vacancy;
    }
    return response;
  });

  return { ...state, responses: [...editedResponses] };
};


const initialState = [];

export default createReducer(initialState, {
  [GET_RESPONSES_SUCCESS]: getResponses,
  [CREATE_RESPONSE_SUCCESS]: createResponse,
  [EDIT_RESPONSE_SUCCESS]: editResponse,
  [MARK_RESPONSE_AS_VIEWED_SUCCESS]: markResponseAsViewed,
  [ADD_COMMENT_SUCCESS]: addComment,
  [EDIT_COMMENT_SUCCESS]: editComment,
  [DELETE_COMMENT_SUCCESS]: deleteComment,
  [GET_RESPONSE_SUCCESS]: getResponse,
  [DELETE_RESPONSES_SUCCESS]: deleteResponses,
  [MOVE_RESPONSE_SUCCESS]: moveResponse,
});
