import React from 'react';
import PropTypes from 'prop-types';

import ResponseChangesHistoryItem from './ResponseChangesHistoryItem';


const ResponseChangesHistoryList = (props) => {
  const {
    responseChanges,
  } = props;
  if (responseChanges.length) {
    return responseChanges.map((change, index) => (
      <ResponseChangesHistoryItem
        key={`change_${index + 1}`}
        change={change}
        changeNumber={index + 1}
      />
    ));
  }
  return <td colSpan="4" className="no-comments">Нет изменений</td>;
};

ResponseChangesHistoryList.propTypes = {
  responseChanges: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ResponseChangesHistoryList;
