export const PUBLISH_VACANCY_RESTRICT = 'PUBLISH_VACANCY_RESTRICT';

export const EDIT_TEAM_NAME_REQUEST = 'EDIT_TEAM_NAME_REQUEST';
export const EDIT_TEAM_NAME_SUCCESS = 'EDIT_TEAM_NAME_SUCCESS';
export const EDIT_TEAM_NAME_FAIL = 'EDIT_TEAM_NAME_FAIL';

export const EDIT_TEAM_CAPTCHA_REQUEST = 'EDIT_TEAM_CAPTCHA_REQUEST';
export const EDIT_TEAM_CAPTCHA_SUCCESS = 'EDIT_TEAM_CAPTCHA_SUCCESS';
export const EDIT_TEAM_CAPTCHA_FAIL = 'EDIT_TEAM_CAPTCHA_FAIL';

export const EDIT_STYLE_REQUEST = 'EDIT_STYLE_REQUEST';
export const EDIT_STYLE_SUCCESS = 'EDIT_STYLE_SUCCESS';
export const EDIT_STYLE_FAIL = 'EDIT_STYLE_FAIL';

export const EDIT_TEAM_URL_REQUEST = 'EDIT_TEAM_URL_REQUEST';
export const EDIT_TEAM_URL_SUCCESS = 'EDIT_TEAM_URL_SUCCESS';
export const EDIT_TEAM_URL_FAIL = 'EDIT_TEAM_URL_FAIL';

export const GET_DEFAULT_STYLE_REQUEST = 'GET_DEFAULT_STYLE_REQUEST';
export const GET_DEFAULT_STYLE_SUCCESS = 'GET_DEFAULT_STYLE_SUCCESS';
export const GET_DEFAULT_STYLE_FAIL = 'GET_DEFAULT_STYLE_FAIL';

export const EDIT_VACANCY_REQUEST = 'EDIT_VACANCY_REQUEST';
export const EDIT_VACANCY_SUCCESS = 'EDIT_VACANCY_SUCCESS';
export const EDIT_VACANCY_FAIL = 'EDIT_VACANCY_FAIL';

export const EDIT_VACANCIES_REQUEST = 'EDIT_VACANCIES_REQUEST';
export const EDIT_VACANCIES_SUCCESS = 'EDIT_VACANCIES_SUCCESS';
export const EDIT_VACANCIES_FAIL = 'EDIT_VACANCIES_FAIL';

export const DELETE_VACANCIES_REQUEST = 'DELETE_VACANCIES_REQUEST';
export const DELETE_VACANCIES_SUCCESS = 'DELETE_VACANCIES_SUCCESS';
export const DELETE_VACANCIES_FAIL = 'DELETE_VACANCIES_FAIL';

export const DELETE_VACANCY_REQUEST = 'DELETE_VACANCY_REQUEST';
export const DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS';
export const DELETE_VACANCY_FAIL = 'DELETE_VACANCY_FAIL';

export const CREATE_VACANCY_REQUEST = 'CREATE_VACANCY_REQUEST';
export const CREATE_VACANCY_SUCCESS = 'CREATE_VACANCY_SUCCESS';
export const CREATE_VACANCY_FAIL = 'CREATE_VACANCY_FAIL';

export const CREATE_HIRING_TEMPLATE_REQUEST = 'CREATE_HIRING_TEMPLATE_REQUEST';
export const CREATE_HIRING_TEMPLATE_SUCCESS = 'CREATE_HIRING_TEMPLATE_SUCCESS';
export const CREATE_HIRING_TEMPLATE_FAIL = 'CREATE_HIRING_TEMPLATE_FAIL';

export const EDIT_HIRING_TEMPLATE_REQUEST = 'EDIT_HIRING_TEMPLATE_REQUEST';
export const EDIT_HIRING_TEMPLATE_SUCCESS = 'EDIT_HIRING_TEMPLATE_SUCCESS';
export const EDIT_HIRING_TEMPLATE_FAIL = 'EDIT_HIRING_TEMPLATE_FAIL';

export const DELETE_HIRING_TEMPLATE_REQUEST = 'DELETE_HIRING_TEMPLATE_REQUEST';
export const DELETE_HIRING_TEMPLATE_SUCCESS = 'DELETE_HIRING_TEMPLATE_SUCCESS';
export const DELETE_HIRING_TEMPLATE_FAIL = 'DELETE_HIRING_TEMPLATE_FAIL';

export const GET_ACTIVE_TEAM_REQUEST = 'GET_ACTIVE_TEAM_REQUEST';
export const GET_ACTIVE_TEAM_SUCCESS = 'GET_ACTIVE_TEAM_SUCCESS';
export const GET_ACTIVE_TEAM_FAIL = 'GET_ACTIVE_TEAM_FAIL';

export const GET_ALL_TEAMS_REQUEST = 'GET_ALL_TEAMS_REQUEST';
export const GET_ALL_TEAMS_SUCCESS = 'GET_ALL_TEAMS_SUCCESS';
export const GET_ALL_TEAMS_FAIL = 'GET_ALL_TEAMS_FAIL';

export const CREATE_NEW_TEAM_REQUEST = 'CREATE_NEW_TEAM_REQUEST';
export const CREATE_NEW_TEAM_SUCCESS = 'CREATE_NEW_TEAM_SUCCESS';
export const CREATE_NEW_TEAM_FAIL = 'CREATE_NEW_TEAM_FAIL';

export const DELETE_TEAM_REQUEST = 'DELETE_TEAM_REQUEST';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAIL = 'DELETE_TEAM_FAIL';

export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAIL = 'SEND_INVITE_FAIL';

export const DELETE_INVITE_REQUEST = 'DELETE_INVITE_REQUEST';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAIL = 'DELETE_INVITE_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL';

export const REMOVE_USER_FROM_TEAM_REQUEST = 'REMOVE_USER_FROM_TEAM_REQUEST';
export const REMOVE_USER_FROM_TEAM_SUCCESS = 'REMOVE_USER_FROM_TEAM_SUCCESS';
export const REMOVE_USER_FROM_TEAM_FAIL = 'REMOVE_USER_FROM_TEAM_FAIL';

export const EDIT_HH_ID_REQUEST = 'EDIT_HH_ID_REQUEST';
export const EDIT_HH_ID_SUCCESS = 'EDIT_HH_ID_SUCCESS';
export const EDIT_HH_ID_FAIL = 'EDIT_HH_ID_FAIL';

export const IMPORT_HH_VACANCIES_REQUEST = 'IMPORT_HH_VACANCIES_REQUEST';
export const IMPORT_HH_VACANCIES_SUCCESS = 'IMPORT_HH_VACANCIES_SUCCESS';
export const IMPORT_HH_VACANCIES_FAIL = 'IMPORT_HH_VACANCIES_FAIL';

export const TOGGLE_ADMIN_RIGHTS_REQUEST = 'TOGGLE_ADMIN_RIGHTS_REQUEST';
export const TOGGLE_ADMIN_RIGHTS_SUCCESS = 'TOGGLE_ADMIN_RIGHTS_SUCCESS';
export const TOGGLE_ADMIN_RIGHTS_FAIL = 'TOGGLE_ADMIN_RIGHTS_FAIL';

export const GET_VACANCIES_FROM_HH_REQUEST = 'GET_VACANCIES_FROM_HH_REQUEST';
export const GET_VACANCIES_FROM_HH_SUCCESS = 'GET_VACANCIES_FROM_HH_SUCCESS';
export const GET_VACANCIES_FROM_HH_FAIL = 'GET_VACANCIES_FROM_HH_FAIL';

export const EDIT_VACANCIES_DISPLAY_REQUEST = 'EDIT_VACANCIES_DISPLAY_REQUEST';
export const EDIT_VACANCIES_DISPLAY_SUCCESS = 'EDIT_VACANCIES_DISPLAY_SUCCESS';
export const EDIT_VACANCIES_DISPLAY_FAIL = 'EDIT_VACANCIES_DISPLAY_FAIL';
