import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import HTMLTextArea from '../../components/HTMLTextArea';
import FieldError from '../../components/FieldError';


const renderHtmlTextArea = (
  {
    input: { value, onChange, onBlur },
    meta: { touched, error },
    label,
    componentName,
  },
) => {
  const handleInput = (event) => {
    onChange(event.target.getContent());
  };
  return (
    <FormGroup>
      <Label>{label}</Label>
      <HTMLTextArea
        name={componentName}
        initialValue={value}
        onChange={handleInput}
        onBlur={onBlur}
      />
      {touched && error && <FieldError error={error} />}
    </FormGroup>
  );
};


renderHtmlTextArea.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  componentName: PropTypes.string.isRequired,
};

export default renderHtmlTextArea;
