import { schema } from 'normalizr';


const userSchema = new schema.Entity('users');
const invitedUserSchema = new schema.Entity('invited_users');
const teamSchema = new schema.Entity('teams', {
  admins: [userSchema],
  users: [userSchema],
  invited_users: [invitedUserSchema],
});

const teamsSchema = [teamSchema];

export {
  userSchema, invitedUserSchema, teamSchema, teamsSchema,
};
