export const CHECK_USER_AUTHORIZATION_REQUEST = 'CHECK_USER_AUTHORIZATION_REQUEST';
export const CHECK_USER_AUTHORIZATION_SUCCESS = 'CHECK_USER_AUTHORIZATION_SUCCESS';
export const CHECK_USER_AUTHORIZATION_FAIL = 'CHECK_USER_AUTHORIZATION_FAIL';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';

export const LOAD_INITIAL_DATA_SUCCESS = 'LOAD_INITIAL_DATA_SUCCESS';
export const LOAD_INITIAL_DATA_FAIL = 'LOAD_INITIAL_DATA_FAIL';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAIL = 'RESTORE_PASSWORD_FAIL';

export const CHANGE_PASSWORD_AFTER_RESTORE_REQUEST = 'CHANGE_PASSWORD_AFTER_RESTORE_REQUEST';
export const CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS = 'CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS';
export const CHANGE_PASSWORD_AFTER_RESTORE_FAIL = 'CHANGE_PASSWORD_AFTER_RESTORE_FAIL';

export const CHANGE_ACTIVE_TEAM_REQUEST = 'CHANGE_ACTIVE_TEAM_REQUEST';
export const CHANGE_ACTIVE_TEAM_SUCCESS = 'CHANGE_ACTIVE_TEAM_SUCCESS';
export const CHANGE_ACTIVE_TEAM_FAIL = 'CHANGE_ACTIVE_TEAM_FAIL';

export const CHANGE_ACTIVE_TEAM_ON_INIT_REQUEST = 'CHANGE_ACTIVE_TEAM_REQUEST';
export const CHANGE_ACTIVE_TEAM_ON_INIT_SUCCESS = 'CHANGE_ACTIVE_TEAM_SUCCESS';
export const CHANGE_ACTIVE_TEAM_ON_INIT_FAIL = 'CHANGE_ACTIVE_TEAM_FAIL';

export const RESET_API_STATUS = 'RESET_API_STATUS';

export const RESET_ERROR = 'RESET_ERROR';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

export const EDIT_USER_SETTINGS_REQUEST = 'EDIT_USER_SETTINGS_REQUEST';
export const EDIT_USER_SETTINGS_SUCCESS = 'EDIT_USER_SETTINGS_SUCCESS';
export const EDIT_USER_SETTINGS_FAIL = 'EDIT_USER_SETTINGS_FAIL';

export const RESET_ACTIVE_TEAM = 'RESET_ACTIVE_TEAM';
