import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';

import renderField from './render/renderField';
import validateEditHhIdForm from './validate/validateEditHhIdForm';
import ErrorMessage from '../components/ErrorMessage';
import { TOOLTIP_TEXTS } from '../utils';


const EditHhId = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="hh_employer_url"
      type="text"
      component={renderField}
      tooltipText={TOOLTIP_TEXTS.hhEmployerUrl}
      label="Ссылка на компаню на hh.ru"
      placeholder="https://hh.ru/employer/XXX"
    />
    <button
      type="submit"
      className="button"
    >
      Сохранить
    </button>
    <ErrorMessage errorName="editHhIdError" />
  </form>
);

EditHhId.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: 'editHhId',
    validate: validateEditHhIdForm,
  }),
)(EditHhId);
