export default function getManagersFromResponse(responses) {
  const managersWhoChanged = [...new Set(
    responses
      .map(({ response_changes }) => response_changes.map(({ user }) => user))
      .flat(),
  )];

  const managersWhoCreated = responses.map(
    ({ creator }) => creator,
  ).filter(managerInfo => managerInfo);

  return [...managersWhoChanged, ...managersWhoCreated]
    .filter((user, index, arr) => {
      const indexOfSameManager = arr
        .map(user => user.id)
        .lastIndexOf(user.id);
      return indexOfSameManager === index;
    });
}

export const REPORT_BY_SOURCES = 'by_sources';
export const REPORT_BY_VACANCIES = 'by_vacancies';
export const REPORT_BY_MANAGERS = 'by_managers';

export const REPORT_VALUE_ARRAY_NAME = {
  [REPORT_BY_SOURCES]: 'sources',
  [REPORT_BY_VACANCIES]: 'statuses',
  [REPORT_BY_MANAGERS]: 'statuses',
};

export const ROW_VALUE_TYPES = {
  [REPORT_BY_SOURCES]: 'source',
  [REPORT_BY_VACANCIES]: 'vacancy',
  [REPORT_BY_MANAGERS]: 'manager',
};

export const ASC_SORT_TYPE = 'ASC';
export const DESC_SORT_TYPE = 'DESC';
