import { createReducer } from '../utils/reducerUtils';
import { CREATE_NEW_TEAM_SUCCESS, DELETE_TEAM_SUCCESS, GET_ALL_TEAMS_SUCCESS } from '../constants/teamConst';


const extractTeamsIds = (state, action) => action.teams.result;

const addTeamId = (state, action) => [...state, action.team.result];

const deleteTeamId = (state, action) => state.filter(teamId => teamId !== action.team.id);

const initialState = [];

const teamsIdsReducer = createReducer(initialState, {
  [GET_ALL_TEAMS_SUCCESS]: extractTeamsIds,
  [CREATE_NEW_TEAM_SUCCESS]: addTeamId,
  [DELETE_TEAM_SUCCESS]: deleteTeamId,
});

export default teamsIdsReducer;
