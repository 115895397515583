import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledSelect from './StyledSelect';


class ResponsesPageFilterContainer extends Component {
  constructor(props) {
    super(props);

    const {
      vacancies,
      vacancyId,
      hiringTemplatesStates,
      searchParams: {
        status,
        viewed,
        unViewed,
        hasCv,
        noCv,
      },
    } = this.props;

    const vacancyFilterOptions = [
      { label: 'Все вакансии', value: 0 },
      ...vacancies.map(({ id, title }) => (
        { label: title, value: id }
      )),
    ];

    const statusFilterOptions = [
      { label: 'Любой статус', value: '', id: 0 },
      ...hiringTemplatesStates.map(
        hiringTemplate => ({ label: hiringTemplate.name, value: hiringTemplate.name, id: hiringTemplate.id }),
      ),
    ];

    this.state = {
      filters: {
        vacancyFilter: {
          options: vacancyFilterOptions,
          value: vacancyFilterOptions.find(option => option.value === vacancyId),
        },

        statusFilter: {
          options: statusFilterOptions,
          value: statusFilterOptions.find(option => option.id === status),
        },
      },

      checkboxes: {
        viewedCheckbox: {
          title: 'viewed',
          label: 'Прочитанные',
          value: viewed,
        },
        unViewedCheckbox: {
          title: 'un_viewed',
          label: 'Непрочитанные',
          value: unViewed,
        },

        hasCvCheckbox: {
          title: 'has_cv',
          label: 'Есть резюме',
          value: hasCv,
        },
        noCvCheckbox: {
          title: 'no_cv',
          label: 'Без резюме',
          value: noCv,
        },

      },
    };
  }

  componentWillReceiveProps(newProps) {
    const { vacancyFilter, statusFilter } = this.state;
    if (vacancyFilter && statusFilter) {
      const {
        vacancyId,
        searchParams: {
          status,
          viewed,
          unViewed,
          hasCv,
          noCv,
        },
      } = newProps;

      this.setState(prevState => ({
        filters: {
          vacancyFilter: {
            options: prevState.vacancyFilter.options,
            value: prevState.vacancyFilter.options.find(option => option.value === vacancyId),
          },

          statusFilter: {
            options: prevState.statusFilter.options,
            value: prevState.statusFilter.options.find(option => option.id === status),
          },
        },

        checkboxes: {
          viewedCheckbox: {
            ...prevState.checkboxes.viewedCheckbox,
            value: viewed,
          },
          unViewedCheckbox: {
            ...prevState.checkboxes.unViewedCheckbox,
            value: unViewed,
          },

          hasCvCheckbox: {
            ...prevState.checkboxes.hasCvCheckbox,
            value: hasCv,
          },
          noCvCheckbox: {
            ...prevState.checkboxes.noCvCheckbox,
            value: noCv,
          },
        },
      }));
    }
  }

  onSelectChange = (selectValue, filterKey) => {
    const { filters } = this.state;
    filters[filterKey].value = selectValue;
    this.setState({ filters }, () => this.filterAll());
  };

  onCheckboxChange = (checkEvent, checkboxKey) => {
    const { checkboxes } = this.state;
    const { target } = checkEvent;
    checkboxes[checkboxKey].value = target.checked;
    this.setState({ checkboxes }, () => this.filterAll());
  };

  filterAll = () => {
    const { handleFilterChange } = this.props;
    const {
      filters: { vacancyFilter, statusFilter },
      checkboxes,
    } = this.state;
    const searchParams = new URLSearchParams();
    searchParams.set('page', '1');
    if (statusFilter.value.id) {
      searchParams.append('status', statusFilter.value.id);
    }
    Object.keys(checkboxes).forEach((checkboxKey) => {
      searchParams.append(checkboxes[checkboxKey].title, checkboxes[checkboxKey].value);
    });

    handleFilterChange(vacancyFilter.value.value, searchParams.toString());
  };

  render() {
    const {
      filters,
      checkboxes,
    } = this.state;
    const filtersContainers = Object.keys(filters).map(filterKey => (
      <StyledSelect
        key={filterKey}
        options={filters[filterKey].options}
        value={filters[filterKey].value}
        onChange={selectValue => this.onSelectChange(selectValue, filterKey)}
        isSearchable={false}
      />
    ));
    const checkboxesContainer1 = Object.keys(checkboxes).slice(0, 2).map(checkboxKey => (
      <label className="checkbox-container" key={checkboxKey}>
        {checkboxes[checkboxKey].label}
        <input
          className="checkbox-hidden"
          name="doesNotHaveCv"
          type="checkbox"
          checked={checkboxes[checkboxKey].value}
          onChange={checkEvent => this.onCheckboxChange(checkEvent, checkboxKey)}
        />
        <span className="checkbox-mark" />
      </label>
    ));
    const checkboxesContainer2 = Object.keys(checkboxes).slice(2).map(checkboxKey => (
      <label className="checkbox-container" key={checkboxKey}>
        {checkboxes[checkboxKey].label}
        <input
          className="checkbox-hidden"
          name="doesNotHaveCv"
          type="checkbox"
          checked={checkboxes[checkboxKey].value}
          onChange={checkEvent => this.onCheckboxChange(checkEvent, checkboxKey)}
        />
        <span className="checkbox-mark" />
      </label>
    ));
    return (
      <div className="responses-page-filter-container">
        {filtersContainers}
        <div className="responses-checkboxes">
          <div className="responses-checkboxes-group">
            {checkboxesContainer1}
          </div>
          <div className="responses-checkboxes-group">
            {checkboxesContainer2}
          </div>
        </div>
      </div>
    );
  }
}

ResponsesPageFilterContainer.propTypes = {
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  vacancyId: PropTypes.number.isRequired,
  searchParams: PropTypes.object.isRequired,
  hiringTemplatesStates: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default ResponsesPageFilterContainer;
