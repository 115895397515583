import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';


class CommentItem extends React.Component {
  constructor(props) {
    super(props);
    const { comment } = this.props;
    this.state = {
      message: comment.message,
      editedMessage: '',
      isEditable: false,
    };
    this.commentInputRef = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditable } = this.state;
    if (isEditable && !prevState.isEditable) {
      this.commentInputRef.focus();
      this.commentInputRef.selectionStart = this.commentInputRef.textContent.length;
    }
  }

  handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleEditButton = () => {
    this.setState(state => ({
      isEditable: !state.isEditable,
      editedMessage: state.message,
    }));
  };

  handleDeleteComment = () => {
    const { response, comment, deleteComment } = this.props;
    deleteComment(response, comment);
  };

  saveCommentChanges = () => {
    const { editedMessage } = this.state;
    const { response, comment, editComment } = this.props;
    editComment(response, { ...comment, message: editedMessage });
    this.setState(state => ({
      isEditable: !state.isEditable,
      message: state.editedMessage,
    }));
  };

  render() {
    const { isEditable, message, editedMessage } = this.state;
    const { comment, userId, teamAdmins } = this.props;
    const { author, date_created } = comment;
    const isAuthor = userId === author.id;
    const isAdmin = teamAdmins.includes(userId);

    return (
      <div className="comment comments__item">
        <div className="comment__info">
          <div className="comment__user-icon">
            <FontAwesomeIcon
              className="item-icon"
              icon="user"
            />
          </div>
          <div className="comment__flex">
            <div className="comment__user-name">
              {author.last_name}
              {' '}
              {author.first_name}
            </div>
            <div className="label">
              {dateformat((date_created).toString(), 'dd.mm.yyyy HH:MM')}
            </div>
          </div>
          <div className="comment__icons-container">
            {isAuthor && (
              <FontAwesomeIcon
                onClick={this.handleEditButton}
                className="item-icon"
                icon="pencil-alt"
              />
            )}
            {(isAuthor || isAdmin) && (
              <FontAwesomeIcon
                onClick={this.handleDeleteComment}
                className="item-icon"
                icon="trash-alt"
              />
            )}
          </div>
        </div>
        <div className="item-text">
          {isEditable
            ? (
              <div>
                <Input
                  className="comment-textarea"
                  type="textarea"
                  name="editedMessage"
                  value={editedMessage}
                  onChange={this.handleInputChange}
                  innerRef={(inputRef) => {
                    this.commentInputRef = inputRef;
                  }}
                />
                <div className="buttons-container">
                  <button
                    type="button"
                    className="button"
                    onClick={this.saveCommentChanges}
                  >
                    Сохранить
                  </button>
                  <button
                    type="button"
                    className="button button_color_dark-blue"
                    onClick={this.handleEditButton}
                  >
                    Отмена
                  </button>
                </div>
              </div>
            )
            : message
          }
        </div>
      </div>
    );
  }
}

CommentItem.propTypes = {
  response: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  teamAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CommentItem;
