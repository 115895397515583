import {
  validateErrors,
  required,
  maxLength,
  custom,
} from '../validators';

import store from '../../store';


const validate = (values) => {
  const { fields } = store.getState();
  const isFieldExist = fields.some(field => values.placeholder === field.placeholder && values.type === field.type);
  const validators = {
    placeholder: [
      maxLength(200),
      custom(isFieldExist, 'Поле с таким именем и типом уже существует'),
      required,
    ],
  };
  return validateErrors(values, validators);
};

export default validate;
