import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';


const PaymentSuccessPage = ({ activeTeamShortId }) => (
  <div className="success-block">
    <Helmet>
      <title>Тариф успешно активирован | Jobs Widget</title>
    </Helmet>
    <h2 className="success-block-title">
      Тариф "Бизнес" успешно активирован.
      <br />
      Теперь Вам доступны все возможности сервиса.
    </h2>
    <Link className="success-block-link" to={`/teams/${activeTeamShortId}/vacancies`}>
      Перейти к настройке вакансий
    </Link>
  </div>
);

PaymentSuccessPage.propTypes = {
  activeTeamShortId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  activeTeamShortId: state.app.activeTeamShortId,
});

export default connect(mapStateToProps)(PaymentSuccessPage);
