import React from 'react';
import PropTypes from 'prop-types';


const FieldError = (props) => {
  const { error } = props;
  return (
    <div className="render-field-error">
      <span className="error-text">{error}</span>
    </div>
  );
};

FieldError.defaultProps = {
  error: '',
};

FieldError.propTypes = {
  error: PropTypes.string,
};

export default FieldError;
