import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';


class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  handleClick = () => {
    this.setState(state => ({ displayColorPicker: !state.displayColorPicker }));
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = (newColor) => {
    const { handleColorChange, colorInfo: { name } } = this.props;
    handleColorChange({
      name,
      value: newColor.hex,
    });
  };

  render() {
    const { displayColorPicker } = this.state;
    const { colorInfo: { value } } = this.props;

    return (
      <div className="color-picker">
        <div
          className="color-picker-box"
          onClick={this.handleClick}
          style={{ background: value }}
        />
        {displayColorPicker ? (
          <div className="picker-popover">
            <div className="picker-cover" onClick={this.handleClose} />
            <ChromePicker
              disableAlpha
              color={value}
              onChange={this.handleColorChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  colorInfo: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  handleColorChange: PropTypes.func.isRequired,
};

export default ColorPicker;
