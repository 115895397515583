import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import renderCheckbox from './renderCheckbox';
import { TOOLTIP_TEXTS, TRANSLATE_TO_RUSSIAN } from '../../utils';
import renderSelect from './renderSelect';
import FieldError from '../../components/FieldError';
import Tooltip from '../../components/Tooltip';


const REQUIRED_FIELD_NAME = 'required';
const SERVICE_ONLY_FIELD_NAME = 'is_service_only';
class RenderFieldSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedValue: props.fields.getAll().map(field => field.field_id.value), draggableItemId: '' };
  }

  onDragEnd = (result) => {
    const { moveItem } = this.props;
    if (!result.destination) {
      return;
    }
    const {
      destination: { index: destinationIndex },
      source: { index: sourceIndex },
    } = result;
    if (sourceIndex === destinationIndex) {
      return;
    }
    const { selectedValue } = this.state;
    const fieldsOrder = [...selectedValue];
    const dropedItem = fieldsOrder[sourceIndex];
    fieldsOrder.splice(sourceIndex, 1);
    fieldsOrder.splice(destinationIndex, 0, dropedItem);
    this.setState({ selectedValue: fieldsOrder });
    moveItem(sourceIndex, destinationIndex);
    this.setState({ draggableItemId: '' });
  }

  onDragStart = (result) => {
    this.setState({ draggableItemId: result.draggableId });
  }

  onCheckBoxChange = (fieldName, index, { target: { checked } }) => {
    const { changeFormValue } = this.props;
    if (checked) {
      changeFormValue(`fields[${index}].${fieldName}`, !checked);
    }
  };


  render() {
    const {
      fields,
      vacancyFields,
      meta: { error },
      isDisabled,
    } = this.props;

    const { selectedValue, draggableItemId } = this.state;
    const selectOptions = vacancyFields.map(({ placeholder, type, id }) => ({
      label: `${placeholder} (тип: ${TRANSLATE_TO_RUSSIAN.vacancyFieldTypes[type]})`,
      value: id,
    }));

    const removeField = (index) => {
      fields.splice(index, 1);
      selectedValue.splice(index, 1);
    };

    return (
      <React.Fragment>
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          <Droppable droppableId="fields">
            { provided => (
              <div className="render-field-item-list" {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => {
                  const options = selectOptions.filter(option => (!selectedValue
                    .find(otherField => ((otherField === option.value) && (otherField !== selectedValue[index])))));
                  const isFirst = index === 0;
                  return (
                    <React.Fragment key={field}>
                      {isFirst ? (
                        <div
                          key={field}
                          className="render-field-item"
                        >
                          <div className="render-field-item-select">
                            <span className="render-field-item-num">{`${1})`}</span>
                            <Field
                              name={`${field}.field_id`}
                              type="text"
                              label="Поле"
                              component={renderSelect}
                              options={options}
                              noOptionsMessage="Нет доступных полей"
                              placeholder="Выберите поле"
                              disabled={isFirst}
                            />
                          </div>
                        </div>)
                        : (
                          <Draggable key={field} draggableId={field} index={index}>
                            { provided => (
                              <div
                                key={field}
                                className="render-field-item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="render-field-item-select">
                                  <span className="render-field-item-num">{`${index + 1})`}</span>
                                  <Field
                                    isDragging={field === draggableItemId}
                                    name={`${field}.field_id`}
                                    type="text"
                                    label="Поле"
                                    component={renderSelect}
                                    options={options}
                                    noOptionsMessage="Нет доступных полей"
                                    placeholder="Выберите поле"
                                    onChange={newValue => this.setState({
                                      selectedValue: selectedValue
                                        .map((value, indexValue) => (indexValue === index ? newValue.value : value)),
                                    })}
                                  />
                                </div>
                                <div className="render-field-item-bottom">
                                  <Field
                                    name={`${field}.required`}
                                    type="checkbox"
                                    label="Обязательное"
                                    component={renderCheckbox}
                                    onChange={event => this.onCheckBoxChange(SERVICE_ONLY_FIELD_NAME, index, event)}
                                  />
                                  <div className="d-flex render-field-service-only-field-wrapper">
                                    <Field
                                      name={`${field}.is_service_only`}
                                      type="checkbox"
                                      label="Заметка"
                                      component={renderCheckbox}
                                      onChange={event => this.onCheckBoxChange(REQUIRED_FIELD_NAME, index, event)}
                                    />
                                    <Tooltip
                                      text={TOOLTIP_TEXTS.serviceOnlyField}
                                      wrapperClassName="p-0 ml-1"
                                    />
                                  </div>

                                  <FontAwesomeIcon
                                    onClick={() => removeField(index)}
                                    className="item-icon"
                                    icon="trash-alt"
                                  />
                                </div>
                              </div>)}
                          </Draggable>
                        )}
                    </React.Fragment>);
                })}
                {provided.placeholder}
              </div>)}
          </Droppable>
        </DragDropContext>
        {error && <FieldError error={error} />}
        <button
          type="button"
          className="button-frame"
          onClick={() => {
            fields.push({ required: false });
            selectedValue.push(-1);
          }}
          disabled={isDisabled}
        >
        Добавить поле
        </button>
      </React.Fragment>
    );
  }
}

RenderFieldSet.defaultProps = {
  isDisabled: false,
  fieldSetId: 0,
  moveItem: () => {},
  editFieldSetOrder: () => {},
};

RenderFieldSet.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  vacancyFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool,
  editFieldSetOrder: PropTypes.func,
  fieldSetId: PropTypes.number,
  moveItem: PropTypes.func,
  changeFormValue: PropTypes.func.isRequired,
};

export default RenderFieldSet;
