import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import renderField from './render/renderField';
import validatePromoForm from './validate/validatePromoForm';
import StatusMessage from '../containers/StatusMessage';


const PromoForm = (props) => {
  const { handleSubmit } = props;
  return (
    <div className="promo-code-wrap">
      <form onSubmit={handleSubmit}>
        <Field
          name="promo"
          type="text"
          component={renderField}
          label="Промокод"
          required
        />
        <button
          type="submit"
          className="button button_color_dark-blue"
        >
          Применить промокод
        </button>
      </form>
      <StatusMessage
        status="applyCouponStatus"
        errorMessage="Промокод не действителен"
      />
      <div className="payment-text">
        Нажимая кнопку применить промокод вы принимаете
        {' '}
        <Link to="/license-agreement" className="light-green">условия лицензионного соглашения</Link>
      </div>
    </div>
  );
};

PromoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'promo',
  validate: validatePromoForm,
})(PromoForm);
