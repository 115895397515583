import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import ChangeTeamNameForm from '../forms/ChangeTeamNameForm';
import { editTeamName } from '../actions/teams';


class TeamNameEditor extends Component {
  state = {
    editable: false,
  };

  componentDidUpdate(prevProps) {
    const { editTeamNameStatus } = this.props;
    if (prevProps.editTeamNameStatus !== editTeamNameStatus && editTeamNameStatus === 'success') {
      this.setTeamNameSaveComplete();
    }
  }

  handleChange = () => {
    this.setState(state => ({ editable: !state.editable }));
  };

  handleSubmit = (values) => {
    const { editTeamName, id } = this.props;
    editTeamName(id, values.teamName);
    this.setState({ editable: false });
  };

  submitForm = () => {
    const { submitForm, id } = this.props;
    submitForm(id);
  };

  setTeamNameSaveComplete = () => {
    this.setState({ editable: false });
  };

  render() {
    const { editable } = this.state;
    const { name, id } = this.props;
    return editable
      ? (
        <div className="team-name-editor-container">
          <ChangeTeamNameForm
            form={`changeTeamName-${id}`}
            onSubmit={this.handleSubmit}
            initialValues={{ teamName: name }}
          />
          <div className="buttons-circle-container">
            <button
              type="button"
              className="button-circle button-circle_color_light-green"
              onClick={this.submitForm}
            >
              <FontAwesomeIcon
                icon="check"
              />
            </button>
            <button
              type="button"
              className="button-circle button-circle_color_dark-blue"
              onClick={this.handleChange}
            >
              <FontAwesomeIcon
                icon="times"
              />
            </button>
          </div>
        </div>
      )
      : (
        <div className="team-name-editor-container">
          <h4 className="team-name-editor-container-title">{name}</h4>
          <button
            type="button"
            className="button-circle team-name-editor-container-button-right"
            onClick={this.handleChange}
          >
            <FontAwesomeIcon
              className="item-icon"
              icon="pencil-alt"
            />
          </button>
        </div>
      );
  }
}

TeamNameEditor.defaultProps = {
  editTeamNameStatus: '',
};

TeamNameEditor.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  editTeamName: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  editTeamNameStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  editTeamNameStatus: state.api.editTeamNameStatus,
});

const mapDispatchToProps = dispatch => ({
  editTeamName: (teamId, name) => dispatch(editTeamName(teamId, name)),
  submitForm: id => dispatch(submit(`changeTeamName-${id}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamNameEditor);
