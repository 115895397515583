import { createReducer } from '../utils/reducerUtils';
import {
  CREATE_FIELD_SET_SUCCESS,
  DELETE_FIELD_SET_SUCCESS,
  EDIT_FIELD_SET_SUCCESS,
  GET_FIELD_SETS_SUCCESS,
  EDIT_FIELD_SET_ORDER,
} from '../constants/fieldSetConst';

import {
  EDIT_FIELD_SUCCESS,
  DELETE_FIELD_SUCCESS,
} from '../constants/fieldConst';


const getFieldSets = (state, action) => action.fieldSets;

const createFieldSet = (state, action) => [...state, action.fieldSet];

const editFieldSet = (state, action) => state.map(
  fieldSet => (fieldSet.id === action.fieldSet.id ? action.fieldSet : fieldSet),
);

const editFieldSetUpdate = (state, action) => action.field.fieldSets;

const deleteFieldSetUpdate = (state, action) => action.field.fieldSets;

const deleteFieldSet = (state, action) => state.filter(fieldSet => (fieldSet.id !== action.fieldSetId));

const editFieldSetOrder = (state, action) => {
  const { payload: { fieldSetId, newFields } } = action;
  const setIndex = state.findIndex(fieldSet => fieldSet.id === fieldSetId);
  const newState = [...state];
  if (newFields.length === state[setIndex].fields.length) {
    const newSetFields = newFields
      .map(field => state[setIndex].fields
        .find(stateField => field && stateField && stateField.field_id === field.id));
    newState[setIndex].fields = newSetFields;
  } else {
    return state;
  }
  return newState;
};


const initialState = [];

export default createReducer(initialState, {
  [DELETE_FIELD_SUCCESS]: deleteFieldSetUpdate,
  [EDIT_FIELD_SUCCESS]: editFieldSetUpdate,
  [GET_FIELD_SETS_SUCCESS]: getFieldSets,
  [CREATE_FIELD_SET_SUCCESS]: createFieldSet,
  [EDIT_FIELD_SET_SUCCESS]: editFieldSet,
  [DELETE_FIELD_SET_SUCCESS]: deleteFieldSet,
  [EDIT_FIELD_SET_ORDER]: editFieldSetOrder,
});
