export const validateErrors = (values, fieldValidators) => {
  const errors = {};

  Object.entries(fieldValidators).forEach((field) => {
    const [name, validators] = field;
    validators.forEach((validator) => {
      if (validator(values[name], values)) {
        errors[name] = validator(values[name], values);
      }
    });
  });
  return errors;
};

export const required = value => (
  value ? undefined : 'Обязательное поле'
);

export const maxLength = max => value => (
  value && value.length > max ? `Максимальное число символов: ${max}` : undefined
);

export const minLength = min => value => (
  value && value.length < min ? `Минимальное число символов: ${min}` : undefined
);

export const valueRange = (min, max) => value => (
  value && (value < min || value > max) ? `Допустимо значение от ${min} до ${max}` : undefined
);

export const email = value => (
  // eslint-disable-next-line
  value && !/(?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9](?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9-]*[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9])?\.)+[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9](?:[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9-]*[\u00A0-\uD7FF\uE000-\uFFFF-a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(value) ? 'Неверный формат почты' : undefined
);

export const phone = (value) => {
  if (value) {
    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length < 11) {
      return 'Введите номер полностью';
    }
    if (onlyNums.length > 11) {
      return 'Слишком длинный номер';
    }
  }
  return undefined;
};

export const matchPattern = (pattern, message) => value => (
  value && !pattern.test(value) ? message || 'Неверный формат' : undefined
);

export const equal = (fieldName, message) => (value, values) => (
  value && fieldName && value !== values[fieldName] ? message : undefined
);

export const notEqual = (fieldName, message) => (value, values) => (
  value && fieldName && value === values[fieldName] ? message : undefined
);

export const resume = (value) => {
  if (value) {
    if (value.length) {
      const accept = ['pdf', 'doc', 'docx'];
      const fileExtension = value[0].name.split('.').pop();
      if (!accept.includes(fileExtension)) {
        return `Файл имеет недопустимый формат. Используйте форматы: ${accept.join(', ')}.`;
      }
      if (value[0].size > 10 * 1048576) {
        // SIZE_LIMIT_IN_MEGABYTES * BYTES_IN_MEGABYTE
        return 'Файл превышает максимальный разрешенный размер в 10 МБ';
      }
    }
  }
  return undefined;
};

export const custom = (condition, message) => () => (
  condition ? message : undefined
);
