import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormGroup, Label } from 'reactstrap';

import { getPaymentInfo, updatePaymentInfo } from '../actions/payment';
import StyledSelect from '../components/StyledSelect';


const ITRANSFER_ITEMS = {
  month: {
    name: 'Премиум аккаунт на месяц',
    price: 490,
  },
  year: {
    name: 'Премиум аккаунт на год',
    price: 4900,
  },
};


class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    const { email, activeTeam } = this.props;
    this.state = {
      email,
      teamId: activeTeam,
      paymentPeriod: 'YEAR',
    };
  }

  componentDidMount() {
    const { getPaymentInfo } = this.props;
    getPaymentInfo();
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (data) => {
    this.setState({ teamId: data.value });
  };

  updatePaymentData = () => {
    const { email, paymentPeriod, teamId } = this.state;
    const { payment, updatePaymentInfo } = this.props;
    updatePaymentInfo({
      email,
      payment_period: paymentPeriod,
      itransfer_order_id: payment.itransfer_order_id,
      team_id: teamId,
    });
  };

  render() {
    const {
      email,
      teamId,
      paymentPeriod,
    } = this.state;
    const {
      payment,
      activeTeam,
      teams,
      teamsIds,
    } = this.props;
    const {
      itransfer_url,
      itransfer_participant_id,
      itransfer_order_id,
      itransfer_order_currency_ident,
      itransfer_language_ident,
      itransfer_participant_ident,
      itransfer_body_type,
      itransfer_url_returnsuccess,
      itransfer_testmode,
      itransfer_person_name,
      itransfer_order_description,
      itransfer_item1_quantity,
      itransfer_item1_measure,
      itransfer_item1_vatrate,
      itransfer_item1_vat,
      itransfer_participant_sign_month,
      itransfer_participant_sign_year,
    } = payment;
    const itransfer_participant_sign = paymentPeriod === 'YEAR'
      ? itransfer_participant_sign_year
      : itransfer_participant_sign_month;
    const itransfer_item1_name = paymentPeriod === 'YEAR'
      ? ITRANSFER_ITEMS.year.name
      : ITRANSFER_ITEMS.month.name;
    const itransfer_item1_price = paymentPeriod === 'YEAR'
      ? ITRANSFER_ITEMS.year.price
      : ITRANSFER_ITEMS.month.price;
    const itransfer_order_amount = paymentPeriod === 'YEAR'
      ? ITRANSFER_ITEMS.year.price
      : ITRANSFER_ITEMS.month.price;
    const options = activeTeam
      ? teamsIds.map(teamId => ({ label: teams[teamId].name, value: teamId }))
      : { label: 'Нет компании', value: '' };

    return (
      <React.Fragment>
        <div className="payment-email">
          <Label className="payment-message" for="person_email">На эту почту придет электронный чек</Label>
          <input
            type="email"
            id="person_email"
            name="email"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <div className="payment-company">
          <FormGroup>
            <Label className="payment-message" for="company">Выберите оплачиваемую команду</Label>
            <StyledSelect
              options={options}
              defaultValue={{ label: teams[activeTeam].name, value: activeTeam }}
              isSearchable={false}
              onChange={this.handleSelectChange}
            />
          </FormGroup>
        </div>
        <div className="payment-period">
          <Label className="payment-message">Период оплаты</Label>
          <div className="radio-container">
            <Label className="radio radio-container__item" for="payment-period-year">
              Год
              <input
                className="radio-hidden"
                type="radio"
                name="paymentPeriod"
                value="YEAR"
                id="payment-period-year"
                checked={paymentPeriod === 'YEAR'}
                onChange={this.handleInputChange}
              />
              <span className="radio-mark" />
            </Label>
            <Label className="radio radio-container__item" for="payment-period-month">
              Месяц
              <input
                className="radio-hidden"
                type="radio"
                name="paymentPeriod"
                id="payment-period-month"
                value="MONTH"
                checked={paymentPeriod === 'MONTH'}
                onChange={this.handleInputChange}
              />
              <span className="radio-mark" />
            </Label>
          </div>
        </div>
        <form
          action={itransfer_url}
          method="post"
          id="payment-form"
          name="invoicebox_form"
          target="_blank"
          onSubmit={this.updatePaymentData}
        >
          <input type="hidden" name="teamId" value={teamId} />
          <input type="hidden" name="itransfer_person_email" value={email} />
          <input type="hidden" name="itransfer_participant_sign" value={itransfer_participant_sign} />
          <input type="hidden" name="itransfer_item1_name" value={itransfer_item1_name} />
          <input type="hidden" name="itransfer_item1_price" value={itransfer_item1_price} />
          <input type="hidden" name="itransfer_order_amount" value={itransfer_order_amount} />
          <input type="hidden" name="itransfer_participant_id" value={itransfer_participant_id} />
          <input type="hidden" name="itransfer_participant_ident" value={itransfer_participant_ident} />
          <input type="hidden" name="itransfer_order_id" value={itransfer_order_id} />
          <input type="hidden" name="itransfer_language_ident" value={itransfer_language_ident} />
          <input type="hidden" name="itransfer_url_returnsuccess" value={itransfer_url_returnsuccess} />
          <input type="hidden" name="itransfer_order_currency_ident" value={itransfer_order_currency_ident} />
          <input type="hidden" name="itransfer_order_description" value={itransfer_order_description} />
          <input type="hidden" name="itransfer_body_type" value={itransfer_body_type} />
          <input type="hidden" name="itransfer_person_name" value={itransfer_person_name} />
          <input type="hidden" name="itransfer_testmode" value={itransfer_testmode} />
          <input type="hidden" name="itransfer_item1_vat" value={itransfer_item1_vat} />
          <input type="hidden" name="itransfer_item1_quantity" value={itransfer_item1_quantity} />
          <input type="hidden" name="itransfer_item1_measure" value={itransfer_item1_measure} />
          <input type="hidden" name="itransfer_item1_vatrate" value={itransfer_item1_vatrate} />
          <input type="hidden" name="team_id" value={teamId} />
          <button
            form="payment-form"
            type="submit"
            className="button button_color_dark-blue"
          >
            Оплатить
          </button>
        </form>
        <div className="payment-text text-margin">
          Нажимая кнопку оплатить вы принимаете
          {' '}
          <Link to="/license-agreement" className="light-green">условия лицензионного соглашения</Link>
        </div>
      </React.Fragment>
    );
  }
}

PaymentForm.propTypes = {
  payment: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  getPaymentInfo: PropTypes.func.isRequired,
  updatePaymentInfo: PropTypes.func.isRequired,
  activeTeam: PropTypes.number.isRequired,
  teams: PropTypes.object.isRequired,
  teamsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const mapStateToProps = state => ({
  email: state.entities.users[state.app.user].email,
  payment: state.payment,
  activeTeam: state.app.activeTeam || '',
  teams: state.entities.teams,
  teamsIds: state.entities.teamsIds,
});

const mapDispatchToProps = dispatch => ({
  getPaymentInfo: () => dispatch(getPaymentInfo()),
  updatePaymentInfo: paymentInfo => dispatch(updatePaymentInfo(paymentInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
