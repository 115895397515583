import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { changeActiveTeamOnInit, checkUserAuthorization, loadInitialData } from '../actions/app';


class MainRouter extends Component {
  async componentDidMount() {
    const { checkUserAuthorization, loadInitialData, location } = this.props;

    if (location.pathname === '/login') {
      const searchParams = new URLSearchParams(location.search);
      localStorage.setItem('token', searchParams.get('token'));
    }
    const token = localStorage.getItem('token');

    if (token) {
      await checkUserAuthorization(token);
      await this.checkIfNeedChangeActiveTeamByUrl();
      await loadInitialData();
    }
  }

  checkIfNeedChangeActiveTeamByUrl = async () => {
    const { location, activeTeamShortId, changeActiveTeamOnInit } = this.props;
    const pathSegments = location.pathname.split('/');
    const isPathContainShortId = pathSegments[1] === 'teams' && pathSegments[2] && pathSegments[2].length === 6;
    const shortId = isPathContainShortId ? pathSegments[2] : null;
    if (shortId && activeTeamShortId !== shortId) {
      await changeActiveTeamOnInit(shortId);
    }
  };

  render() {
    const {
      authInProgress,
      authComplete,
      dataLoaded,
      activeTeamShortId,
    } = this.props;
    const token = localStorage.getItem('token');

    if ((token && !authComplete) || authInProgress) {
      return <div />;
    }
    if (authComplete) {
      return <PrivateRoutes dataLoaded={dataLoaded} activeTeamShortId={activeTeamShortId} />;
    }
    return <PublicRoutes />;
  }
}

MainRouter.defaultProps = {
  activeTeamShortId: null,
};

MainRouter.propTypes = {
  authInProgress: PropTypes.bool.isRequired,
  authComplete: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  activeTeamShortId: PropTypes.string,
  checkUserAuthorization: PropTypes.func.isRequired,
  changeActiveTeamOnInit: PropTypes.func.isRequired,
  loadInitialData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: {
      authInProgress,
      authComplete,
      dataLoaded,
      activeTeam,
      activeTeamShortId,
    },
  } = state;
  return ({
    authInProgress,
    authComplete,
    dataLoaded,
    activeTeam,
    activeTeamShortId,
  });
};

const mapDispatchToProps = dispatch => ({
  checkUserAuthorization: token => dispatch(checkUserAuthorization(token)),
  changeActiveTeamOnInit: teamId => dispatch(changeActiveTeamOnInit(teamId)),
  loadInitialData: () => dispatch(loadInitialData()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainRouter),
);
