import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Tooltip from '../components/Tooltip';
import { TOOLTIP_TEXTS } from '../utils';
import SourcesForm from '../forms/SourcesForm';


const SourcesPage = ({ sources }) => (
  <div className="section-grey">
    <Helmet>
      <title>Настройка источников | Jobs Widget</title>
    </Helmet>
    <div className="page-header-container">
      <h1 className="main-page-title">
          Источники
        <Tooltip className="main-page-title__tooltip" text={TOOLTIP_TEXTS.sourcePage} />
      </h1>
    </div>
    <div className="content-container content-container_padding_sm">
      <SourcesForm
        initialValues={sources.reduce(
          (accum, { id, name }) => ({ ...accum, [`source-name-${id}`]: name }),
          {},
        )}
      />
    </div>
  </div>
);

SourcesPage.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ sources, app: { activeTeam: teamId } }) => ({ sources, teamId });

export default connect(mapStateToProps, null)(SourcesPage);
