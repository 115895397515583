import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';


const ResponseChangesHistoryItem = (props) => {
  const { changeNumber, change } = props;
  const {
    user: { email }, status, updated_at,
  } = change;
  return (
    <tr className="justify-content-md-between">
      <td className="content-changes-history">
        {changeNumber}
      </td>
      <td className="content-changes-history">
        {email}
      </td>
      <td className="content-changes-history">
        {status}
      </td>
      <td className="content-changes-history">
        { dateformat(updated_at.toString(), 'dd.mm.yyyy HH:MM') }
      </td>
    </tr>
  );
};

ResponseChangesHistoryItem.propTypes = {
  changeNumber: PropTypes.number.isRequired,
  change: PropTypes.shape({
    user: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResponseChangesHistoryItem;
