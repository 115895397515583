import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import StatusMessage from '../containers/StatusMessage';


class CreateTeamModal extends React.Component {
  state = {
    modal: false,
    teamName: '',
  };

  componentDidUpdate(prevProps) {
    const { createNewTeamStatus } = this.props;
    if (prevProps.createNewTeamStatus === 'request' && createNewTeamStatus === 'success') {
      this.closeModal();
    }
  }

  handleSubmit = (event) => {
    const { teamName } = this.state;
    const { createNewTeam } = this.props;
    event.preventDefault();
    if (teamName) {
      createNewTeam(teamName);
    }
  };

  handleInputChange = (event) => {
    this.setState({
      teamName: event.target.value,
    });
  };

  toggle = () => {
    this.setState(state => ({
      modal: !state.modal,
      teamName: '',
    }));
  };

  closeModal = () => {
    this.setState({
      modal: false,
      teamName: '',
    });
  };

  render() {
    const { teamName, modal } = this.state;
    const { buttonLabel } = this.props;
    return (
      <React.Fragment>
        <button type="button" className="button button_plus" onClick={this.toggle}>
          <span className="button-icon">+</span>
          <span className="button-text">{buttonLabel}</span>
        </button>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Создать компанию</ModalHeader>
          <ModalBody>
            <p className="modal-body-text">
              Введите имя компании
            </p>
            <Input
              value={teamName}
              onChange={this.handleInputChange}
              required
            />
            <StatusMessage
              status="createNewTeamStatus"
              errorMessage="Ошибка создания компании"
            />
          </ModalBody>
          <ModalFooter>
            <div className="buttons-container">
              <button
                type="button"
                className="button"
                onClick={this.handleSubmit}
              >
                Создать компанию
              </button>
              {' '}
              <button
                type="button"
                className="button button_color_dark-blue"
                onClick={this.toggle}
              >
                Отмена
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

CreateTeamModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  createNewTeam: PropTypes.func.isRequired,
  createNewTeamStatus: PropTypes.string.isRequired,
};

export default CreateTeamModal;
