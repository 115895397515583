import { createReducer, updateObject } from '../utils/reducerUtils';
import { GET_ALL_TEAMS_SUCCESS, SEND_INVITE_SUCCESS, DELETE_INVITE_SUCCESS } from '../constants/teamConst';


const extractInvitedUsers = (state, action) => updateObject(state, action.teams.entities.invited_users);

const addInvitedUser = (state, action) => updateObject(
  state,
  {
    [action.data.result.id]: action.data.result,
  },
);

const deleteInvitedUser = (state, action) => {
  const deleteKey = Object.keys(state).filter(key => state[key].email === action.email);
  const newState = { ...state };
  delete newState[`${deleteKey}`];
  return newState;
};
const initialState = {};

const invitedUsersReducer = createReducer(initialState, {
  [GET_ALL_TEAMS_SUCCESS]: extractInvitedUsers,
  [SEND_INVITE_SUCCESS]: addInvitedUser,
  [DELETE_INVITE_SUCCESS]: deleteInvitedUser,
});

export default invitedUsersReducer;
