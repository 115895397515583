import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TeamItems from './TeamItems';
import WidgetCodeModal from './WidgetCodeModal';
import AddEmployeeModal from './AddEmployeeModal';
import TeamNameEditor from './TeamNameEditor';
import TariffText from './TariffText';
import HhModal from '../containers/HhModal';
import ConfirmModal from './ConfirmModal';
import InvitedTeamItems from './InvitedTeamItems';
import appConfig from '../configs/appConfig';


class TeamContainer extends React.Component {
  state = {
    collapse: true,
    copied: false,
  };

  handleDelete = () => {
    const { team, deleteTeam } = this.props;
    deleteTeam(team);
  };

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  handleCopyLink = () => {
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  };

  render() {
    const { collapse, copied } = this.state;
    const {
      currentUser,
      team,
      users,
      invitedUsers,
      sendInvite,
      deleteInvite,
      removeUserFromTeam,
      inviteStatus,
      toggleAdminRights,
    } = this.props;
    const {
      id,
      widget_id,
      name,
      short_id,
    } = team;
    const canUserDeleteInvite = team.admins.includes(currentUser) || currentUser === team.owner;

    let warningMessage;
    if (team.vacancies && team.vacancies.length > 0) {
      const vacanciesIsPublished = team.vacancies.filter(vacancy => vacancy.is_published).map(vacancy => vacancy.title);
      if (vacanciesIsPublished.length > 0) {
        warningMessage = [
          'У данной компании есть следующие опубликованные вакансии:\n', vacanciesIsPublished,
        ].join(' ');
      }
    }

    return (
      <div className="content-container content-container-list__item">
        <div className="team-container-toggle">
          <div className="team-container-toggle-header">
            <TeamNameEditor
              id={id}
              name={name}
            />
            <TariffText team={team} />
          </div>
          {currentUser === team.owner && (
            <ConfirmModal
              type="circle-button-icon"
              headerText="Вы действительно хотите удалить компанию?"
              modalAction={this.handleDelete}
              warningMessage={warningMessage}
              icon="trash-alt"
            />
          )}
          <button
            type="button"
            className="button-circle ml-2"
            onClick={this.toggle}
          >
            {collapse
              ? <FontAwesomeIcon icon="minus" />
              : <FontAwesomeIcon icon="plus" />
            }
          </button>
        </div>
        <Collapse
          isOpen={collapse}
          className="team-container-collapse"
        >
          <div className="team-container">
            <TeamItems
              currentUser={currentUser}
              team={team}
              users={users}
              removeUserFromTeam={removeUserFromTeam}
              toggleAdminRights={toggleAdminRights}
            />
            <InvitedTeamItems
              team={team}
              invitedUsers={invitedUsers}
              deleteInvite={deleteInvite}
              users={users}
              canUserDeleteInvite={canUserDeleteInvite}
            />
            <div className="team-container-buttons-container">
              <AddEmployeeModal
                buttonLabel="+ Добавить сотрудника"
                teamId={id}
                sendInvite={sendInvite}
                inviteStatus={inviteStatus}
              />
              <div className="job-link-button">
                <p className="job-link-content">
                  Страница вакансий
                </p>
                <div className="job-link">
                  <a
                    href={`${appConfig.SITE_URL}/cw/${short_id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {appConfig.SITE_URL}
                    /cw/
                    {short_id}
                  </a>
                </div>
                {copied ? (
                  <FontAwesomeIcon
                    className="item-icon"
                    icon="check"
                    title="Скопировано"
                  />
                ) : (
                  <CopyToClipboard
                    text={`${appConfig.SITE_URL}/cw/${short_id}`}
                    onCopy={this.handleCopyLink}
                  >
                    <FontAwesomeIcon
                      className="item-icon copy-icon"
                      icon="copy"
                      title="Скопировать ссылку"
                    />
                  </CopyToClipboard>
                )}
              </div>
              <WidgetCodeModal buttonLabel="Код виджета" widgetId={widget_id} />
              <HhModal teamId={id} />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

TeamContainer.propTypes = {
  currentUser: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  invitedUsers: PropTypes.object.isRequired,
  sendInvite: PropTypes.func.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  removeUserFromTeam: PropTypes.func.isRequired,
  inviteStatus: PropTypes.string.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  toggleAdminRights: PropTypes.func.isRequired,
};

export default TeamContainer;
