import {
  validateErrors,
  required,
  maxLength,
  valueRange,
} from '../validators';


const validate = (values) => {
  const validators = {
    title: [maxLength(200), required],
    salary_from: [valueRange(0, 10000000)],
    salary_to: [valueRange(0, 10000000)],
    country: [maxLength(200)],
    city: [maxLength(200)],
    address: [maxLength(200)],
    description: [maxLength(10000), required],
    requirements: [maxLength(10000)],
    conditions: [maxLength(10000)],
    sort_order: [valueRange(0, 10000), required],
  };
  const questionsErrors = [];

  if (values && values.questions) {
    values.questions.forEach((question, index) => {
      if (!question || (!question && !question.title)) {
        questionsErrors[index] = { title: 'Выберите или удалите вопрос' };
      }
    });
  }

  return { ...validateErrors(values, validators), questions: questionsErrors };
};

export default validate;
