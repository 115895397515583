import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import '../styles/layout/PublicHeader.css';


const PublicHeader = () => (
  <header className="header">
    <Container className="d-flex justify-content-between header-wrapper">
      <a href="http://www.jobswidget.me" alt="jobswidgets.me" className="logo-wrapper">
        <div className="menu-logo-public navigation-logo">{' '}</div>
      </a>
      <nav className="header-nav">
        <ul className="header-nav-menu p-0">
          <li className="header_nav-menu-item mr-2">
            <Link to="/signup" className="header_nav-menu-item-link ">Регистрация</Link>
          </li>
          <li className="header_nav-menu-item">
            <Link to="/login" className="header_nav-menu-item-link">Войти</Link>
          </li>
        </ul>
      </nav>
    </Container>
  </header>
);


export default PublicHeader;
