import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import ErrorMessage from './ErrorMessage';


class ModalWindow extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  handleActionButtonClick = () => {
    const { handleActionButton } = this.props;
    if (handleActionButton) {
      handleActionButton();
    }
    this.toggle();
  };

  handleCloseButtonClick = () => {
    const { handleCloseButton } = this.props;
    if (handleCloseButton) {
      handleCloseButton();
    }
    this.toggle();
  };

  render() {
    const { isOpen } = this.state;
    const {
      children,
      actionButtonText,
      closeButtonText,
      linkButtonText,
      linkButtonAddress,
      headerText,
      modalButton,
      errorName,
      className,
    } = this.props;

    const button = React.cloneElement(modalButton, { onClick: () => this.toggle() });
    return (
      <React.Fragment>
        {button}
        <Modal
          isOpen={isOpen}
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>
            {headerText}
          </ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
          {actionButtonText || closeButtonText || linkButtonText
            ? (
              <ModalFooter>
                <div className="buttons-container">
                  {actionButtonText && (
                    <button
                      type="button"
                      className="button"
                      onClick={this.handleActionButtonClick}
                    >
                      {actionButtonText}
                    </button>
                  )}
                  {closeButtonText && (
                    <button
                      type="button"
                      className="button button_color_dark-blue"
                      onClick={this.handleCloseButtonClick}
                    >
                      {closeButtonText}
                    </button>
                  )}
                  {linkButtonText && (
                    <LinkContainer
                      type="button"
                      className="button"
                      to={linkButtonAddress}
                      onClick={this.handleCloseButtonClick}
                    >
                      {linkButtonText}
                    </LinkContainer>
                  )}
                  {errorName && <ErrorMessage errorName={errorName} />}
                </div>
              </ModalFooter>
            )
            : <div className="modal-window__footer" />}
        </Modal>
      </React.Fragment>
    );
  }
}

ModalWindow.defaultProps = {
  actionButtonText: '',
  closeButtonText: '',
  linkButtonText: '',
  errorName: '',
  className: '',
  linkButtonAddress: '',
  handleActionButton: null,
  handleCloseButton: null,
};

ModalWindow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  modalButton: PropTypes.node.isRequired,
  headerText: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  errorName: PropTypes.string,
  className: PropTypes.string,
  handleActionButton: PropTypes.func,
  handleCloseButton: PropTypes.func,
  linkButtonText: PropTypes.string,
  linkButtonAddress: PropTypes.string,
};

export default ModalWindow;
