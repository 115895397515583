const getAddressOptionsFromTeam = ({ vacancies }) => vacancies
  .map(({ country_name, city_name, address }) => ({
    label: `${
      country_name ? `${country_name}` : ''}${city_name ? `, ${city_name}` : ''}${address ? `, ${address}` : ''
    }`,
    value: {
      country: country_name,
      city: city_name,
      address,
    },
  })).sort((a, b) => ((a.label > b.label) ? 1 : -1))
  .filter((adds, index, arr) => (index > 0 ? adds.label !== arr[index - 1].label : arr[index]));

export default getAddressOptionsFromTeam;
