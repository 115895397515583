import React from 'react';
import PropTypes from 'prop-types';

import StyledSelect from './StyledSelect';


const SIZES = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 30, 32, 36, 40, 44, 48, 54, 60, 66, 72];

class SizeSelector extends React.Component {
  constructor(props) {
    super(props);
    const { sizeInfo: { value } } = this.props;
    this.state = {
      currentSize: value,
    };
  }

  handleSizeChange = (data) => {
    const { handleSizeChange, sizeInfo: { name } } = this.props;
    this.setState({
      currentSize: data.value,
    });
    handleSizeChange({ name, value: data.value });
  };

  render() {
    const { currentSize } = this.state;
    const { sizeInfo } = this.props;
    const options = SIZES.map(size => ({ label: size, value: size }));
    return sizeInfo
      ? (
        <StyledSelect
          className="size-selector-container"
          options={options}
          defaultValue={{ label: currentSize, value: currentSize }}
          isSearchable={false}
          onChange={this.handleSizeChange}
        />
      )
      : null;
  }
}

SizeSelector.defaultProps = {
  sizeInfo: {
    name: '',
    value: 14,
  },
};

SizeSelector.propTypes = {
  sizeInfo: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }),
  handleSizeChange: PropTypes.func.isRequired,
};

export default SizeSelector;
