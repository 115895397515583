import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';


class CompanyDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  render() {
    const { modal } = this.state;
    return (
      <React.Fragment>
        <div
          className="payment-info-bottom"
          onClick={this.toggle}
        >
          <div className="icon-wrap">
            <FontAwesomeIcon
              className="item-icon"
              icon="file-alt"
            />
          </div>
          <span className="payment-info-requisites">
            Реквизиты организации,
            <br />
            оказывающей услуги сервиса
          </span>
        </div>
        <Modal isOpen={modal} toggle={this.toggle} className="modal-payment">
          <ModalHeader toggle={this.toggle}>ООО «Webim»</ModalHeader>
          <ModalBody>
            <p>ИНН 7814411789</p>
            <p>КПП 781401001</p>
            <p>ОГРН 1089847258016</p>
            <p>
              Юридический адрес: 197082,
              <br />
              г. Санкт-Петербург, Богатырский пр.,
              <br />
              д. 53, к. 3, кв. 64
            </p>
            <p>
              Р/с 40702810905080000421
              <br />
              в Филиале Северо-Западный
              <br />
              ПАО Банка "ФК Открытие"
            </p>
            <p>К/с 30101810540300000795</p>
            <p>БИК 044030795</p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CompanyDetailsModal;
