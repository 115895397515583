import { createReducer } from '../utils/reducerUtils';
import {
  CREATE_FIELD_SUCCESS,
  EDIT_FIELD_SUCCESS,
  DELETE_FIELD_SUCCESS,
  GET_FIELDS_SUCCESS,
} from '../constants/fieldConst';


const getFields = (state, action) => action.fields.sort((firstField, secondField) => firstField.id - secondField.id);

const createField = (state, action) => [...state, action.field];

const editField = (state, action) => action.field.fields;

const deleteField = (state, action) => action.field.fields;

const initialState = [];

export default createReducer(initialState, {
  [GET_FIELDS_SUCCESS]: getFields,
  [CREATE_FIELD_SUCCESS]: createField,
  [EDIT_FIELD_SUCCESS]: editField,
  [DELETE_FIELD_SUCCESS]: deleteField,
});
