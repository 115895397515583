import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';

import VacanciesPageFilterContainer from '../components/VacanciesPageFilterContainer';
import ArchiveList from '../components/ArchiveList';
import {
  deleteVacancy, editVacancy, editVacancies, deleteVacancies, restrictPublishVacancy,
} from '../actions/teams';
import history from '../routes/history';
import { resetSelection } from '../actions/selection';


class ArchivePage extends React.Component {
  constructor(props) {
    super(props);
    const { vacancies } = this.props;


    this.state = {
      showFilter: false,
      filteredVacancies: vacancies,
      sortValue: { label: 'по дате', value: 'date' },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filteredVacancies, sortValue,
    } = this.state;
    const { location: { pathname, search }, vacancies } = this.props;
    const searchParams = new URLSearchParams(search);

    if (
      prevState.filteredVacancies.length !== filteredVacancies.length
      || prevState.sortValue !== sortValue
    ) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }

    if (prevProps.vacancies !== vacancies) {
      this.handleFilterChange(vacancies);
    }
  }

  handleFilterChange = (filteredVacancies) => {
    this.setState({
      filteredVacancies,
    });
  };

  handleFilterChangeArchive = (filteredVacancies) => {
    this.setState({
      filteredVacancies,
    });
  };

  handleSortChange = (sortValue) => {
    this.setState({
      sortValue,
    });
  };

  sortFunction = (a, b) => {
    const { sortValue } = this.state;
    if (sortValue.value === 'archiveDate') {
      return Date.parse(b.archive_date) - Date.parse(a.archive_date);
    }
    return Date.parse(b.publish_date) - Date.parse(a.publish_date);
  };

  render() {
    const {
      vacancies,
      editVacancies,
      deleteVacancies,
      editVacancy,
      deleteVacancy,
      teamId,
      lastEditFailedVacancy,
      location,
      activeTeamShortId,
      resetSelection,
      paymentStatus,
      restrictPublishVacancy,
    } = this.props;

    const {
      showFilter,
      filteredVacancies,
      sortValue,
    } = this.state;

    const filteredAndSortedVacancies = filteredVacancies.sort(this.sortFunction).slice();
    return (
      <div className="vacancies-wrap section-grey">
        <Helmet>
          <title>Архив вакансий | Jobs Widget</title>
        </Helmet>
        <div className="page-header-container">
          <h1 className="main-page-title">
            Архив вакансий
          </h1>
          <div
            className="vacancies-filter-open-box archive"
            onClick={() => {
              this.setState({ showFilter: !showFilter });
            }}
          >
            <FontAwesomeIcon
              className="item-icon vacancies-filter-icon"
              icon="filter"
            />
            Показать фильтры
            { showFilter ? (
              <FontAwesomeIcon
                className="item-icon vacancies-filter-chevron-icon"
                icon="chevron-up"
              />
            ) : (
              <FontAwesomeIcon
                className="item-icon vacancies-filter-chevron-icon"
                icon="chevron-down"
              />
            )}
          </div>
        </div>
        <Collapse
          isOpen={showFilter}
        >
          <VacanciesPageFilterContainer
            vacancies={vacancies}
            handleFilterChange={this.handleFilterChange}
            pageName="VacanciesPage"
          />
        </Collapse>
        <ArchiveList
          editVacancy={editVacancy}
          editVacancies={editVacancies}
          deleteVacancies={deleteVacancies}
          deleteVacancy={deleteVacancy}
          teamId={teamId}
          lastEditFailedVacancy={lastEditFailedVacancy}
          items={filteredAndSortedVacancies}
          location={location}
          resetSelection={resetSelection}
          handleSortChange={this.handleSortChange}
          sortValue={sortValue}
          allVacancies={vacancies}
          paymentStatus={paymentStatus}
          restrictPublishVacancy={restrictPublishVacancy}
          shortId={activeTeamShortId}
        />
      </div>
    );
  }
}

ArchivePage.defaultProps = {
  teamId: null,
  vacancies: [],
  lastEditFailedVacancy: null,
  activeTeamShortId: null,
  paymentStatus: null,
};

ArchivePage.propTypes = {
  teamId: PropTypes.number,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  editVacancy: PropTypes.func.isRequired,
  editVacancies: PropTypes.func.isRequired,
  deleteVacancies: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string,
  lastEditFailedVacancy: PropTypes.number,
  resetSelection: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string,
  restrictPublishVacancy: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    api: { importHhVacanciesStatus },
    app: { activeTeam, activeTeamShortId, lastEditFailedVacancy },
    entities: { teams },
  } = state;
  const team = teams[activeTeam] || {};
  return {
    teamId: activeTeam,
    vacancies: team.vacancies && team.vacancies
      .filter(vacancy => !vacancy.deleted)
      .filter(vacancy => vacancy.hidden),
    lastEditFailedVacancy,
    importHhVacanciesStatus,
    activeTeamShortId,
    paymentStatus: team.payment_status,
  };
};

const mapDispatchToProps = dispatch => ({
  editVacancy: vacancy => dispatch(editVacancy(vacancy, false)),
  editVacancies: (teamId, vacancies) => dispatch(editVacancies(teamId, vacancies, false)),
  deleteVacancies: vacancy => dispatch(deleteVacancies(vacancy, false)),
  deleteVacancy: vacancy => dispatch(deleteVacancy(vacancy)),
  resetSelection: name => dispatch(resetSelection(name)),
  restrictPublishVacancy: id => dispatch(restrictPublishVacancy(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivePage);
