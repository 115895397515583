import React from 'react';
import { Field, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRANSLATE_TO_RUSSIAN } from '../utils';
import renderField from '../forms/render/renderField';
import renderSelectField from '../forms/render/renderSelectField';
import StatusMessage from '../containers/StatusMessage';
import ConfirmModal from './ConfirmModal';


class EditField extends React.Component {
  constructor(props) {
    super(props);
    const { id, type, placeholder } = props;
    const isNew = id === -1;
    this.state = {
      isNew,
      id: id !== -1 ? id : `custom_field_${uuid()}`,
      fieldType: isNew ? 'text' : type,
      fieldName: placeholder || '',
      isValid: false,
      fieldAlreadyExistError: false,
    };
  }

  isFieldExist = () => {
    const { customVacancyFields } = this.props;
    const { fieldType, fieldName } = this.state;

    return customVacancyFields.find(
      ({ placeholder, type }) => placeholder === fieldName && fieldType === type,
    );
  }

  createHandler = () => {
    const { createItem, tempId, teamId } = this.props;
    const { fieldType, fieldName, id } = this.state;

    if (this.isFieldExist()) {
      this.setState({ fieldAlreadyExistError: true });
      return;
    }

    if (fieldName !== '') {
      this.setState({ isValid: true, fieldAlreadyExistError: false });
      createItem({
        name: id,
        type: fieldType,
        placeholder: fieldName,
        tempId,
        team: teamId,
      });
    }
  }

  editHandler = () => {
    const { editItem, unsavedFieldsFromForm, teamId } = this.props;
    const { id, fieldType, fieldName } = this.state;
    const indexInForm = unsavedFieldsFromForm.findIndex(field => field.field_id.value === id);
    const newValue = {
      ...unsavedFieldsFromForm[indexInForm],
    };
    newValue.field_id = {
      label: `${fieldName}  (тип: ${TRANSLATE_TO_RUSSIAN.vacancyFieldTypes[fieldType]})`,
      value: id,
    };

    if (this.isFieldExist()) {
      this.setState({ fieldAlreadyExistError: true });
      return;
    }

    editItem({
      id,
      team: teamId,
      type: fieldType,
      placeholder: fieldName,
      indexInForm,
      newValue: indexInForm === -1 ? null : newValue,
    });
    this.setState({ isValid: false, fieldAlreadyExistError: false });
  }

  deleteHandler = () => {
    const { deleteItem, unsavedFieldsFromForm, teamId } = this.props;
    const { id } = this.state;
    let indexInForm = -1;
    if (isDirty('createFieldSet')) {
      indexInForm = unsavedFieldsFromForm.findIndex(field => field.field_id.value === id);
    }
    deleteItem({ id, indexInForm, team: teamId });
  }

  cancelCreateHandler = () => {
    const { cancelCreateItem, tempId } = this.props;
    cancelCreateItem(tempId);
  }

  onSelectChange = (newSelect) => {
    const { fieldName } = this.state;
    const keys = Object.keys(newSelect).filter(key => !Number.isNaN(Number.parseInt(key, 10)));
    const type = keys.map(key => newSelect[key]).join('');
    this.setState({ fieldType: type, isValid: fieldName !== '' });
  }

  onNameChange = evt => this.setState({ fieldName: evt.target.value, isValid: evt.target.value !== '' });

  render() {
    const {
      typeOptions,
      labelName,
      placeholder,
      isLabelNeed,
      newLabelName,
      requestStatus,
      fieldSets,
      unsavedFieldsFromForm,
      editeSetName,
      type,
    } = this.props;
    const {
      id,
      isNew,
      isValid,
      fieldAlreadyExistError,
    } = this.state;

    const label = isNew ? newLabelName : labelName;
    let setsWithField = fieldSets
      .filter(set => !!set.fields.find(field => field && field.field_id === id))
      .filter(set => set.name !== editeSetName)
      .map(set => set.name);
    if (unsavedFieldsFromForm.find(field => field.field_id && field.field_id.value === id)) {
      setsWithField = [...setsWithField, editeSetName];
    }
    let deleteFieldMessage = '';
    if (setsWithField.length > 0) {
      const setNumbersText = setsWithField.length === 1 ? 'наборе' : 'следующих наборах:';
      deleteFieldMessage = `Данное поле содержится в ${setNumbersText} ${
        setsWithField.map(setName => `"${setName}"`).join(', ')
      }, вы точно хотите удалить поле?`;
    } else {
      deleteFieldMessage = 'Удалить выбранное поле?';
    }

    return (
      <React.Fragment>
        <div className="create-field-layout">
          <div className="create-field-form">
            <Field
              name={`field-name-${id}`}
              label={isLabelNeed ? label : ''}
              placeholder={placeholder}
              type="text"
              onChange={this.onNameChange}
              component={renderField}
            />
            <div className="create-field-form-type-select">
              <Field
                name={`type-${id}`}
                label={isLabelNeed ? 'Тип' : ''}
                options={typeOptions}
                component={renderSelectField}
                defaultValue={typeOptions.filter(option => option.value === type)[0] || typeOptions[0]}
                onChange={this.onSelectChange}
              />
            </div>
            <div className="buttons-circle-container">
              {!isNew
                ? (
                  <React.Fragment>
                    <button
                      type="button"
                      className={`button-circle ${isValid
                        ? 'button-circle_color_light-green'
                        : 'button-circle_color_gray'}`}
                      onClick={this.editHandler}
                      disabled={!isValid}
                    >
                      <FontAwesomeIcon
                        icon="check"
                      />
                    </button>
                    <ConfirmModal
                      modalAction={this.deleteHandler}
                      type="circle-button-icon"
                      buttonText="Удалить поле"
                      headerText={deleteFieldMessage}
                    />
                  </React.Fragment>)
                : (
                  <React.Fragment>
                    <button
                      type="button"
                      className={`button-circle ${isValid
                        ? 'button-circle_color_light-green'
                        : 'button-circle_color_gray'}`}
                      onClick={this.createHandler}
                      disabled={!isValid}
                    >
                      <FontAwesomeIcon
                        icon="check"
                      />
                    </button>
                    <button
                      type="button"
                      className="button-circle"
                      onClick={this.cancelCreateHandler}
                    >
                      <FontAwesomeIcon
                        icon="trash-alt"
                      />
                    </button>
                  </React.Fragment>
                )
              }
            </div>
          </div>
          <React.Fragment>
            <StatusMessage
              status={requestStatus.createFieldStatusDescription
              && requestStatus.createFieldStatusDescription.id === id ? 'createFieldStatus' : ''}
              errorMessage="Произошла ошибка, поле не изменено"
            />
            <StatusMessage
              status={requestStatus.editFieldStatusDescription
              && requestStatus.editFieldStatusDescription.id === id ? 'editFieldStatus' : ''}
              successMessage="Поле успешно изменено"
              errorMessage="Произошла ошибка, поле не изменено"
            />
            <StatusMessage
              status={requestStatus.deleteFieldStatusDescription
              && requestStatus.deleteFieldStatusDescription.id === id ? 'deleteFieldStatus' : ''}
              errorMessage="Произошла ошибка, поле не удалено"
            />
            {fieldAlreadyExistError && <p className="mt-2 ml-3 text-danger">Поле уже существует</p>}
          </React.Fragment>
        </div>
      </React.Fragment>);
  }
}

EditField.defaultProps = {
  labelName: 'название',
  createItem: () => {},
  deleteItem: () => {},
  editItem: () => {},
  placeholder: '',
  isLabelNeed: true,
  id: -1,
  type: 'default',
  newLabelName: 'название',
  requestStatus: {},
  fieldSets: [],
  tempId: '',
  cancelCreateItem: () => {},
  teamId: null,
};

EditField.propTypes = {
  labelName: PropTypes.string,
  createItem: PropTypes.func,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
  typeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  isLabelNeed: PropTypes.bool,
  id: PropTypes.number,
  type: PropTypes.string,
  newLabelName: PropTypes.string,
  requestStatus: PropTypes.object,
  fieldSets: PropTypes.arrayOf(PropTypes.object),
  tempId: PropTypes.string,
  cancelCreateItem: PropTypes.func,
  unsavedFieldsFromForm: PropTypes.arrayOf(PropTypes.object).isRequired,
  editeSetName: PropTypes.string.isRequired,
  customVacancyFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  teamId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    app: { activeTeam },
    fieldSets,
    form: { createFieldSet: { values: { fields }, initial: { name } } },
    fields: vacancyFields,
  } = state;

  const customVacancyFields = vacancyFields.filter(({ name }) => name.includes('custom'));

  return {
    teamId: activeTeam,
    fieldSets,
    customVacancyFields,
    unsavedFieldsFromForm: fields,
    editeSetName: name,
  };
};

export default connect(mapStateToProps)(EditField);
