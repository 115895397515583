import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';

import DemoWidget from '../components/DemoWidget';
import CustomizationContainer from '../components/CustomizationContainer';
import { editStyle, getDefaultStyle, editTeamCaptcha } from '../actions/teams';


const WidgetCustomizationPage = (props) => {
  const {
    activeTeam,
    style,
    defaultStyle,
    editStyle,
    editStyleStatus,
    getDefaultStyle,
    policyUrl,
    widgetId,
    isCaptchaOn,
    changeCaptchaStatus,
  } = props;
  const { language } = style;
  return (
    <div className="widget-setup-page section-grey">
      <Helmet>
        <title>Настройка виджета | Jobs Widget</title>
      </Helmet>
      <h1 className="main-page-title widget-setup-page__title">Настройка виджета</h1>
      <Row>
        <Col lg="6" xl="7" className="customization-setting">
          <CustomizationContainer
            style={style}
            defaultStyle={defaultStyle}
            editStyle={editStyle}
            teamId={activeTeam}
            policyUrl={policyUrl}
            editStyleStatus={editStyleStatus}
            getDefaultStyle={getDefaultStyle}
            isCaptchaOn={isCaptchaOn}
            changeCaptchaStatus={changeCaptchaStatus}
          />
        </Col>
        <Col lg="6" xl="5" className="customization-widget">
          <div className="customization-widget-content">
            <DemoWidget widgetId={widgetId} language={language} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

WidgetCustomizationPage.propTypes = {
  activeTeam: PropTypes.number,
  widgetId: PropTypes.string,
  style: PropTypes.object.isRequired,
  defaultStyle: PropTypes.object,
  editStyle: PropTypes.func.isRequired,
  getDefaultStyle: PropTypes.func.isRequired,
  editStyleStatus: PropTypes.string,
  policyUrl: PropTypes.string.isRequired,
  isCaptchaOn: PropTypes.bool.isRequired,
  changeCaptchaStatus: PropTypes.func.isRequired,
};

WidgetCustomizationPage.defaultProps = {
  widgetId: '',
  activeTeam: null,
  editStyleStatus: '',
  defaultStyle: {},
};

const mapStateToProps = (state) => {
  const { activeTeam } = state.app;
  const team = state.entities.teams[activeTeam] || {};
  const {
    style = { size: {}, color: {}, language: 'ru' },
    policy_url_not_empty: policyUrl,
    is_captcha_on: isCaptchaOn,
  } = team;
  return {
    activeTeam,
    widgetId: team.widget_id,
    style,
    policyUrl,
    isCaptchaOn,
    defaultStyle: state.app.defaultStyle,
    editStyleStatus: state.api.editStyleStatus,
  };
};

const mapDispatchToProps = dispatch => ({
  editStyle: (teamId, style) => dispatch(editStyle(teamId, style)),
  getDefaultStyle: () => dispatch(getDefaultStyle()),
  changeCaptchaStatus: (teamId, isCaptchaOn) => dispatch(editTeamCaptcha(teamId, isCaptchaOn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCustomizationPage);
