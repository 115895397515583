import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { signUpUser } from '../actions/app';
import SignUpForm from '../forms/SignUpForm';
import ErrorMessage from '../components/ErrorMessage';


const SignUp = (props) => {
  const handleSubmit = (values) => {
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      password,
    } = values;
    const { signUpUser } = props;
    signUpUser(first_name, last_name, email, password);
  };

  const { location } = props;
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const team = searchParams.get('team');
  return (
    <div className="full-height-wrap section-grey">
      <Helmet>
        <title>Регистрация | Jobs Widget</title>
      </Helmet>
      <h2 className="auth-title">Регистрация</h2>
      <div className="auth-content">
        {team && (
          <div>
            <h2 className="auth-title ">
              Вас пригласили в команду
              {' '}
              {team}
            </h2>
            <div className="auth-text">Зарегистрируйтесь в сервисе, чтобы принять приглашение</div>
          </div>
        )}
        <SignUpForm
          onSubmit={handleSubmit}
          initialValues={{ email }}
        />
        <div className="auth-license-text">
          Нажимая кнопку зарегистрироваться вы принимаете
          {' '}
          <Link to="/contest-to-personal-data-processing">соглашение на обработку персональных данных</Link>
        </div>
        <br />
        <ErrorMessage errorName="registerUserError" />
      </div>
    </div>
  );
};

SignUp.propTypes = {
  signUpUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  signUpUser: (first_name, last_name, email, password) => dispatch(signUpUser(first_name, last_name, email, password)),
});

export default withRouter(
  connect(null, mapDispatchToProps)(SignUp),
);
