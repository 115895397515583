export const GET_FIELD_SETS_REQUEST = 'GET_FIELD_SETS_REQUEST';
export const GET_FIELD_SETS_SUCCESS = 'GET_FIELD_SETS_SUCCESS';
export const GET_FIELD_SETS_FAIL = 'GET_FIELD_SETS_FAIL';

export const CREATE_FIELD_SET_REQUEST = 'CREATE_FIELD_SET_REQUEST';
export const CREATE_FIELD_SET_SUCCESS = 'CREATE_FIELD_SET_SUCCESS';
export const CREATE_FIELD_SET_FAIL = 'CREATE_FIELD_SET_FAIL';

export const EDIT_FIELD_SET_REQUEST = 'EDIT_FIELD_SET_REQUEST';
export const EDIT_FIELD_SET_SUCCESS = 'EDIT_FIELD_SET_SUCCESS';
export const EDIT_FIELD_SET_FAIL = 'EDIT_FIELD_SET_FAIL';

export const DELETE_FIELD_SET_REQUEST = 'DELETE_FIELD_SET_REQUEST';
export const DELETE_FIELD_SET_SUCCESS = 'DELETE_FIELD_SET_SUCCESS';
export const DELETE_FIELD_SET_FAIL = 'DELETE_FIELD_SET_FAIL';

export const EDIT_FIELD_SET_ORDER = 'EDIT_FIELD_SET_ORDER';
