export const GET_QUESTIONS_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUESTION_FAIL';

export const CREATE_QUESTION_REQUEST = 'CREATE_QUESTION_REQUEST';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL';

export const EDIT_QUESTION_REQUEST = 'EDIT_QUESTION_REQUEST';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAIL = 'EDIT_QUESTION_FAIL';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';
