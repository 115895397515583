import { GET_REPORT_SUCCESS } from '../constants/responsesConst';
import { createReducer } from '../utils/reducerUtils';


const initialState = [];

const getReport = (state, { data }) => [...data];

export default createReducer(initialState, {
  [GET_REPORT_SUCCESS]: getReport,
});
