import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app';
import responses from './responses';
import entities from './entities';
import api from './api';
import payment from './payment';
import hiringTemplatesStates from './hiringTemplatesStates';
import fieldSets from './fieldSets';
import fields from './fields';
import sources from './sources';
import errors from './errors';
import questions from './questions';
import selection from './selection';
import report from './report';
import { LOGOUT_USER_SUCCESS } from '../constants/appConst';


const appReducer = combineReducers({
  app,
  entities,
  responses,
  api,
  payment,
  hiringTemplatesStates,
  fields,
  fieldSets,
  errors,
  sources,
  questions,
  selection,
  report,
  form: formReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER_SUCCESS) {
    return appReducer(
      {
        app: {
          authInProgress: false,
          authComplete: false,
          dataLoaded: false,
        },
      },
      action,
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
