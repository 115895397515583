import React from 'react';
import PropTypes from 'prop-types';
import withPagination from '../containers/withPagination';
import withLoading from './withLoading';
import { IMPORT_HH_VACANCIES } from '../constants/pageNames';


function ImportVacanciesList({ items }) {
  return (
    <div className="vacancies-list content-container">
      <ul className="entities-container-edit pl-3 w-100">
        {items && items.length
          ? items
          : 'Нет доступных вакансий'
        }
      </ul>
    </div>
  );
}

ImportVacanciesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withPagination(withLoading(ImportVacanciesList), IMPORT_HH_VACANCIES);
