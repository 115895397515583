import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';


class WidgetCodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  render() {
    const { modal } = this.state;
    const { buttonLabel, widgetId } = this.props;
    const widgetCode = `<div id="hr_services"></div>
<script type="text/javascript">
  (function () {
    window.JW_ID = '${widgetId}';
    let hrs = document.createElement('script');
    hrs.type = 'text/javascript';
    hrs.async = true;
    hrs.src = 'https://app.jobswidget.me/static/js/embed.js';
    hrs.charset = 'utf-8';
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(hrs);
  })();
</script>
<noscript>Please enable JavaScript to view the jobs powered by JobsWidget.</noscript>`;
    return (
      <React.Fragment>
        <button
          type="button"
          className="button button_size_sm button_color_dark-blue"
          onClick={this.toggle}
        >
          {buttonLabel}
        </button>
        <Modal isOpen={modal} toggle={this.toggle} className="widget-code-modal">
          <ModalHeader toggle={this.toggle}>Получить код виджета</ModalHeader>
          <ModalBody>
            <div>
              Вставьте код виджета на сайт внутри тега
              {' '}
              <code>&lt;body&gt;</code>
            </div>
            <pre>
              {widgetCode}
            </pre>
          </ModalBody>
          <ModalFooter>
            <CopyToClipboard text={widgetCode}>
              <button
                type="button"
                className="button widget-code-modal__copy-button"
                onClick={this.toggle}
              >
                Скопировать код в буфер обмена
              </button>
            </CopyToClipboard>
            <button
              type="button"
              className="button button_color_dark-blue"
              onClick={this.toggle}
            >
              Готово
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

WidgetCodeModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  widgetId: PropTypes.string.isRequired,
};

export default WidgetCodeModal;
