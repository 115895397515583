import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import CreateVacancyForm from '../forms/CreateVacancyForm';
import { getCountries } from '../actions/app';
import { createVacancy } from '../actions/teams';
import Breadcrumbs from '../components/Breadcrumbs';
import getAddressOptionsFromTeam from '../utils/vacancyUtils';


class CreateVacancy extends Component {
  state = {
    is_published: false,
    isAddAddress: false,
    vacancyImage: null,
  };

  componentDidMount() {
    const { getCountries, countries, team } = this.props;
    if (!countries.length) {
      getCountries();
    }
    if (!team.vacancies.length) {
      this.setState({ isAddAddress: true });
    }
  }

  toggleAddAddress = () => {
    this.setState(state => ({ isAddAddress: !state.isAddAddress }));
  };

  isSubmitWithPublish = (isPublished) => {
    this.setState({ is_published: isPublished });
  };

  onVacancyImageChange = (vacancyImage) => {
    this.setState({ vacancyImage });
  }

  handleSubmit = (values) => {
    const { is_published, isAddAddress, vacancyImage } = this.state;
    const { createVacancy, activeTeam, team } = this.props;
    const questions = values.questions && values.questions.map(
      question => question && question.title && question.title.value,
    );

    const addressOptions = getAddressOptionsFromTeam(team);

    const updatedValues = {
      ...values,
      team: activeTeam,
      country: (isAddAddress || !addressOptions.length) ? (values.country && values.country.value)
        : (values.addressSelect && values.addressSelect.country),
      city: (isAddAddress || !addressOptions.length) ? (values.city && values.city.value)
        : (values.addressSelect && values.addressSelect.city),
      address: (isAddAddress || !addressOptions.length) ? (values.address && values.address.value)
        : (values.addressSelect && values.addressSelect.address),
      questions,
      is_published,
    };

    createVacancy(updatedValues, vacancyImage);
  };

  render() {
    const {
      team,
      countries,
      fieldSets,
      questions,
      activeTeamShortId,
    } = this.props;
    const { isAddAddress } = this.state;
    const initialValues = {
      currency: 'rubles',
      schedule: 'fullDay',
      experience: 'noExperience',
      hiring_template: team.hiring_templates[0].id,
      jwfield_set: fieldSets[0].id,
      sort_order: 100,
    };
    const breadcrumbs = [
      { title: 'Вакансии', link: `/teams/${activeTeamShortId}/vacancies` },
      { title: 'Создать вакансию', link: '' },
    ];
    const notDeletedQuestions = questions.filter(question => question.deleted === null);
    return (
      <React.Fragment>
        <Helmet>
          <title>Создание вакансии | Jobs Widget</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <CreateVacancyForm
          onSubmit={this.handleSubmit}
          isSubmitWithPublish={this.isSubmitWithPublish}
          onVacancyImageChange={this.onVacancyImageChange}
          team={team}
          fieldSets={fieldSets}
          countries={countries}
          initialValues={initialValues}
          questions={notDeletedQuestions}
          toggleAddAddress={this.toggleAddAddress}
          isAddAddress={isAddAddress}
        />
      </React.Fragment>
    );
  }
}

CreateVacancy.defaultProps = {
  countries: [],
};

CreateVacancy.propTypes = {
  activeTeam: PropTypes.number.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  getCountries: PropTypes.func.isRequired,
  createVacancy: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  fieldSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: { activeTeam, activeTeamShortId, countries },
    entities: { teams },
    fieldSets,
    questions,
  } = state;
  const team = teams[activeTeam];
  return {
    team,
    activeTeam,
    activeTeamShortId,
    countries,
    fieldSets,
    questions,
  };
};

const mapDispatchToProps = dispatch => ({
  createVacancy: (vacancy, vacancyImage) => dispatch(createVacancy(vacancy, vacancyImage)),
  getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateVacancy);
