import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { loginUser } from '../actions/app';
import LoginForm from '../forms/LoginForm';


class Login extends React.Component {
  handleSubmit = (values) => {
    const { loginUser } = this.props;
    const { email, password } = values;
    loginUser(email, password);
  };

  render() {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const team = searchParams.get('team');

    return (
      <div className="full-height-wrap login-page section-grey">
        <Helmet>
          <title>Войти | Jobs Widget</title>
        </Helmet>
        <h2 className="auth-title">Вход</h2>
        <div className="auth-content">
          {team && (
            <div>
              <h2>
                Вас пригласили в команду
                {' '}
                {team}
              </h2>
              <div>Авторизуйтесь, чтобы продолжить</div>
              <br />
            </div>
          )}
          <LoginForm
            onSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password) => dispatch(loginUser(email, password)),
});

export default connect(null, mapDispatchToProps)(Login);
