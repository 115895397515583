import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import VacanciesPageFilterContainer from '../components/VacanciesPageFilterContainer';
import '../styles/layout/AllVacanciesPage.css';
import AllVacanciesList from '../components/AllVacanciesList';
import { getAllVacancies } from '../actions/vacancies';
import transparent_preloader from '../assets/img/transparent_preloader.gif';
import ErrorMessage from '../components/ErrorMessage';
import PublicHeader from '../components/PublicHeader';
import Footer from '../components/Footer';
import StyledSelect from '../components/StyledSelect';
import history from '../routes/history';


class AllVacanciesPage extends Component {
  constructor(props) {
    super(props);
    const { vacancies } = this.props;

    this.state = {
      showFilter: false,
      filteredVacancies: vacancies || null,
      timeFilterValue: { label: 'в этом году', value: 'byYear' },
    };
  }

  componentDidMount() {
    const { getAllVacancies } = this.props;
    getAllVacancies();
  }

  componentDidUpdate(prevProps, prevState) {
    const { vacancies } = this.props;

    if (vacancies !== prevProps.vacancies) {
      this.onVacanciesUpdate(vacancies);
    }

    const { timeFilterValue: { value }, filteredVacancies } = this.state;

    const { location: { pathname, search } } = this.props;
    const searchParams = new URLSearchParams(search);

    if (value !== prevState.timeFilterValue.value) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }

    if (prevState.filteredVacancies !== filteredVacancies) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }
  }

  onVacanciesUpdate(newVacancies) {
    this.setState({ filteredVacancies: newVacancies });
  }

  isTimePeriod = (vacancy) => {
    const { timeFilterValue: { value } } = this.state;

    const vacancyPublishDate = new Date(vacancy.publish_date);
    const currentDate = new Date();

    switch (value) {
      case 'byAllTime':
        return true;
      case 'byYear':
        return vacancyPublishDate.getFullYear() === currentDate.getFullYear();
      case 'byMonth':
        return vacancyPublishDate.getMonth() === currentDate.getMonth();
      default:
        return true;
    }
  }

  handleFilterChange = (filteredVacancies) => {
    this.setState({
      filteredVacancies,
    });
  };

  handleTimeFilterChange = (timeFilterValue) => {
    this.setState({
      timeFilterValue,
    });
  };

  render() {
    const {
      showFilter,
      filteredVacancies,
      requiredFilters,
      timeFilterValue,
    } = this.state;

    const { vacancies } = this.props;

    const timeFilterOptions = [
      { label: 'в этом году', value: 'byYear' },
      { label: 'в этом месяце', value: 'byMonth' },
      { label: 'за всё время', value: 'byAllTime' },
    ];

    const { location, errors } = this.props;
    const isVacanciesLoadFail = errors.hasOwnProperty('getAllVacanciesFail');

    const filterVacancies = filteredVacancies && filteredVacancies.filter(
      this.isTimePeriod,
    );

    return (
      <>
        <Helmet>
          <title>Все вакансии | Jobs Widget</title>
        </Helmet>
        <PublicHeader />
        <div className="all-vacancies-page section-grey">
          <div className="all-vacancies-content container">
            <div className="main-page-title-wrapper col-8 p-0">
              <h1 className="main-page-title all-vacancies-title">
                Доступные вакансии
              </h1>
              <div
                className="vacancies-filter-open-box"
                onClick={() => {
                  this.setState({ showFilter: !showFilter });
                }}
              >
                <FontAwesomeIcon
                  className="item-icon vacancies-filter-icon"
                  icon="filter"
                />
                Показать фильтры
                {showFilter ? (
                  <FontAwesomeIcon
                    className="item-icon vacancies-filter-chevron-icon"
                    icon="chevron-up"
                  />
                ) : (
                  <FontAwesomeIcon
                    className="item-icon vacancies-filter-chevron-icon"
                    icon="chevron-down"
                  />
                )}
              </div>
            </div>
            <Row className="time-filters-wrapper">
              <Col md="6" sm="6" className="vacancy-counter text-color-dark-blue">
                Найдено вакансий:&nbsp;
                { filterVacancies && filterVacancies.length }
              </Col>
              <Col
                lg="3" sm="6"
                col="12"
                className="time-filter"
              >
                <StyledSelect
                  options={timeFilterOptions}
                  value={timeFilterValue}
                  isSearchable={false}
                  onChange={this.handleTimeFilterChange}
                />
              </Col>
            </Row>
            <ErrorMessage errorName="getAllVacanciesFail" />
            <>
              {(filteredVacancies) ? (
                <>
                  <Collapse isOpen={showFilter}>
                    {vacancies.length > 1 ? (
                      <VacanciesPageFilterContainer
                        vacancies={vacancies}
                        filteredVacancies={filterVacancies}
                        handleFilterChange={this.handleFilterChange}
                        requiredFilters={requiredFilters}
                        pageName="AllVacanciesPage"
                      />
                    ) : (
                      <p>Слишком мало вакансий для фильтрации</p>
                    )}
                  </Collapse>
                  <AllVacanciesList
                    items={filterVacancies}
                    location={location}
                  />
                </>
              ) : (
                !isVacanciesLoadFail && (
                <div className="loader">
                  <img
                    className="loader__gif"
                    src={transparent_preloader}
                    alt="Загрузка..."
                  />
                </div>)
              )}
            </>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

AllVacanciesPage.propTypes = {
  getAllVacancies: PropTypes.func.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

AllVacanciesPage.defaultProps = {
  vacancies: null,
};

const mapDispatchToProps = dispatch => ({
  getAllVacancies: () => dispatch(getAllVacancies()),
});

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const { entities: { allVacancies: { vacancies } } } = state;
  const { errors } = state;
  return {
    vacancies,
    location,
    errors,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllVacanciesPage);
