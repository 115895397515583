import { getFormInitialValues } from 'redux-form';
import store from '../../store';


const hasNotUniqueFieldSetName = (name) => {
  const { fieldSets } = store.getState();
  const initialValues = getFormInitialValues('createFieldSet')(store.getState());
  return initialValues.name !== name && fieldSets.some(fieldSet => name === fieldSet.name);
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Обязательное поле';
  } else if (hasNotUniqueFieldSetName(values.name)) {
    errors.name = 'Набор полей с таким именем уже существует';
  }
  if (values.fields) {
    const errorFields = [];
    values.fields.forEach((field) => {
      if (!field.field_id) {
        errorFields.push({ field_id: 'Выберите поле' });
      } else {
        errorFields.push(null);
      }
    });
    errors.fields = errorFields;
  }
  return errors;
};

export default validate;
