import { createErrorsReducer } from '../utils/reducerUtils';
import {
  CHANGE_PASSWORD_FAIL,
  CREATE_VACANCY_FAIL,
  DELETE_HIRING_TEMPLATE_FAIL,
  EDIT_HH_ID_FAIL,
  EDIT_TEAM_URL_FAIL,
  EDIT_VACANCY_FAIL,
  IMPORT_HH_VACANCIES_FAIL,
  DELETE_TEAM_FAIL,
  DELETE_VACANCY_FAIL,
  REMOVE_USER_FROM_TEAM_FAIL,
  TOGGLE_ADMIN_RIGHTS_FAIL,
  PUBLISH_VACANCY_RESTRICT,
} from '../constants/teamConst';

import {
  MANAGE_HIRING_STATE_FAIL,
} from '../constants/hiringTemplateConst';

import {
  GET_ALL_VACANCIES_FAIL,
  GET_VACANCY_FAIL,
} from '../constants/vacanciesConst';

import {
  LOGIN_USER_FAIL,
  REGISTER_USER_FAIL,
  RESET_ERROR,
  RESTORE_PASSWORD_FAIL,
  CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
} from '../constants/appConst';


import {
  DELETE_FIELD_FAIL,
} from '../constants/fieldConst';

import {
  GET_RESPONSE_FAIL,
  MOVE_RESPONSE_FAIL,
} from '../constants/responsesConst';
import { CREATE_SOURCES_FAIL, EDIT_SOURCES_FAIL } from '../constants/sourcesConst';


const resetError = (state, action) => {
  const newState = { ...state };
  delete newState[action.name];
  return newState;
};

const initialState = {};

export default createErrorsReducer(initialState, [
  {
    type: REGISTER_USER_FAIL,
    name: 'registerUserError',
  },
  {
    type: LOGIN_USER_FAIL,
    name: 'loginUserError',
  },
  {
    type: CREATE_VACANCY_FAIL,
    name: 'createVacancyError',
  },
  {
    type: CHANGE_PASSWORD_FAIL,
    name: 'changePasswordFail',
  },
  {
    type: CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
    name: 'changePasswordAfterRestoreError',
  },
  {
    type: RESTORE_PASSWORD_FAIL,
    name: 'restorePasswordError',
  },
  {
    type: DELETE_HIRING_TEMPLATE_FAIL,
    name: 'deleteHiringTemplateError',
  },
  {
    type: EDIT_HH_ID_FAIL,
    name: 'editHhIdError',
  },
  {
    type: IMPORT_HH_VACANCIES_FAIL,
    name: 'importHhVacanciesError',
  },
  {
    type: EDIT_VACANCY_FAIL,
    name: 'editVacancyError',
  },
  {
    type: PUBLISH_VACANCY_RESTRICT,
    name: 'editVacancyError',
  },
  {
    type: DELETE_TEAM_FAIL,
    name: 'deleteTeamError',
  },
  {
    type: DELETE_VACANCY_FAIL,
    name: 'deleteVacancyError',
  },
  {
    type: EDIT_TEAM_URL_FAIL,
    name: 'editTeamUrlError',
  },
  {
    type: REMOVE_USER_FROM_TEAM_FAIL,
    name: 'removeUserFromTeamError',
  },
  {
    type: TOGGLE_ADMIN_RIGHTS_FAIL,
    name: 'toggleAdminRighsError',
  },
  {
    type: DELETE_FIELD_FAIL,
    name: 'deleteField',
  },
  {
    type: MANAGE_HIRING_STATE_FAIL,
    name: 'managmentHiringState',
  },
  {
    type: GET_ALL_VACANCIES_FAIL,
    name: 'getAllVacanciesFail',
  },
  {
    type: GET_VACANCY_FAIL,
    name: 'getVacancyError',
  },
  {
    type: GET_RESPONSE_FAIL,
    name: 'getResponseError',
  },
  {
    type: MOVE_RESPONSE_FAIL,
    name: 'getResponseError',
  },
  {
    type: CREATE_SOURCES_FAIL,
    name: 'createSourceError',
  },
  {
    type: EDIT_SOURCES_FAIL,
    name: 'editSourceError',
  },
], {
  [RESET_ERROR]: resetError,
});
