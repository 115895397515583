import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import StatusMessage from '../containers/StatusMessage';


class AddEmployeeModal extends React.Component {
  state = {
    modal: false,
    isValid: true,
    email: '',
    isUserAlreadyInvited: false,
  };

  componentDidUpdate(prevProps) {
    const { inviteStatus } = this.props;
    if (prevProps.inviteStatus === 'request' && inviteStatus === 'success') {
      this.closeModal();
    }
  }

  validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = (event) => {
    const { email } = this.state;
    const { teamId, sendInvite } = this.props;
    event.preventDefault();
    if (this.validateEmail(email)) {
      this.setState({ isValid: true });
      sendInvite(email, teamId);
    } else {
      this.setState({ isValid: false });
    }
    this.closeModal();
  };

  handleInputChange = (event) => {
    const { invitedUsersEmails } = this.props;
    const { target: { value } } = event;
    const isUserAlreadyInvited = invitedUsersEmails.includes(value);
    this.setState({
      isUserAlreadyInvited,
      email: value,
    });
  };

  toggle = () => {
    this.setState(state => ({
      modal: !state.modal,
      email: '',
    }));
  };

  closeModal = () => {
    this.setState({
      modal: false,
      isValid: true,
      email: '',
    });
  };

  render() {
    const {
      className,
      email,
      modal,
      isValid,
      isUserAlreadyInvited,
    } = this.state;
    const { buttonLabel } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          className="button-add-item team-name-editor-container-button-right button-add-worker"
          onClick={this.toggle}
        >
          {buttonLabel}
        </button>
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={className}
        >
          <ModalHeader toggle={this.toggle}>Добавить сотрудника</ModalHeader>
          <ModalBody>
            <p className="modal-body-text">
              Введите почту, на которую будет отправлено приглашение
            </p>
            <Form
              id="invite-employee-form"
              onSubmit={this.handleSubmit}
            >
              <Input
                type="email"
                value={email}
                onChange={this.handleInputChange}
                required
              />
              {isUserAlreadyInvited && (
                <div className="invite-employee-error">
                  Данный пользователь уже приглашен или является сотрудником компании.
                </div>
              )}
              {!isValid && <div style={{ color: 'red' }}>Введите почту в правильном формате</div>}
              <StatusMessage
                status="inviteStatus"
                errorMessage="Ошибка отправки сообщения"
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <div className="buttons-container">
              <button
                type="submit"
                className="button"
                form="invite-employee-form"
                onClick={this.handleSubmit}
                disabled={isUserAlreadyInvited}
              >
                Отправить приглашение
              </button>
              {' '}
              <button
                type="button"
                className="button button_color_dark-blue"
                onClick={this.toggle}
              >
                Отмена
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

AddEmployeeModal.propTypes = {
  teamId: PropTypes.number.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  sendInvite: PropTypes.func.isRequired,
  inviteStatus: PropTypes.string.isRequired,
  invitedUsersEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentTeamKey = ownProps.teamId;
  const teamUserIds = state.entities.teams[`${currentTeamKey}`].users;
  const teamUsersEmails = teamUserIds.map(id => state.entities.users[`${id}`].email);
  const invitedUsersIds = state.entities.teams[`${currentTeamKey}`].invited_users;
  const invitedUsersEmails = invitedUsersIds.map(id => state.entities.invitedUsers[`${id}`].email);
  return {
    invitedUsersEmails: [...teamUsersEmails, ...invitedUsersEmails],
  };
};
export default connect(mapStateToProps)(AddEmployeeModal);
