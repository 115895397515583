export const GET_HIRING_TEMPLATES_STATES_REQUEST = 'GET_HIRING_TEMPLATES_STATES_REQUEST';
export const GET_HIRING_TEMPLATES_STATES_SUCCESS = 'GET_HIRING_TEMPLATES_STATES_SUCCESS';
export const GET_HIRING_TEMPLATES_STATES_FAIL = 'GET_HIRING_TEMPLATES_STATES_FAIL';

export const CREATE_HIRING_STATE_REQUEST = 'CREATE_HIRING_STATE_REQUEST';
export const CREATE_HIRING_STATE_SUCCESS = 'CREATE_HIRING_STATE_SUCCESS';
export const CREATE_HIRING_STATE_FAIL = 'CREATE_HIRING_STATE_FAIL';

export const EDIT_HIRING_STATE_SUCCESS = 'EDIT_HIRING_STATE_SUCCESS';
export const EDIT_HIRING_STATE_REQUEST = 'EDIT_HIRING_STATE_REQUEST';
export const EDIT_HIRING_STATE_FAIL = 'EDIT_HIRING_STATE_FAIL';

export const DELETE_HIRING_STATE_REQUEST = 'DELETE_HIRING_STATE_REQUEST';
export const DELETE_HIRING_STATE_SUCCESS = 'DELETE_HIRING_STATE_SUCCESS';
export const DELETE_HIRING_STATE_FAIL = 'DELETE_HIRING_STATE_FAIL';

export const MANAGE_HIRING_STATE_FAIL = 'MANAGE_HIRING_STATE_FAIL';
