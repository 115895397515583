import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/lib/Async';


class StyledAsyncSelect extends Component {
  state = {
    menuIsOpen: false,
  };

  handleOnMenuOpen = () => {
    this.setState({ menuIsOpen: true });
  };

  handleOnMenuClose = () => {
    this.setState({ menuIsOpen: false });
  };

  render() {
    const { menuIsOpen } = this.state;
    const { className, defaultValue } = this.props;
    return (
      <Async
        {...this.props}
        className={`${className} ${menuIsOpen ? 'react-select-menu-is-open' : 'react-select-menu-is-close'}`}
        classNamePrefix="react-select"
        onMenuOpen={this.handleOnMenuOpen}
        onMenuClose={this.handleOnMenuClose}
        loadingMessage={() => 'Загрузка...'}
        defaultValue={defaultValue && { label: defaultValue, value: defaultValue }}
      />
    );
  }
}

StyledAsyncSelect.defaultProps = {
  className: '',
  defaultValue: null,
};

StyledAsyncSelect.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default StyledAsyncSelect;
