import React from 'react';
import PropTypes from 'prop-types';

import CommentsList from './CommentsList';
import AddComment from './AddComment';


const CommentsContainer = (props) => {
  const {
    response,
    comments,
    addComment,
    editComment,
    deleteComment,
    userId,
    teamAdmins,
  } = props;
  return (
    <div className="comments">
      <div className="title comments__title">Комментарии</div>
      <CommentsList
        userId={userId}
        teamAdmins={teamAdmins}
        response={response}
        comments={comments}
        editComment={editComment}
        deleteComment={deleteComment}
      />
      <AddComment
        response={response}
        addComment={addComment}
      />
    </div>
  );
};

CommentsContainer.propTypes = {
  response: PropTypes.object.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  addComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  teamAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CommentsContainer;
