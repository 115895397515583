import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import ChangeTeamUrlForm from '../forms/ChangeTeamUrlForm';
import { editTeamUrl } from '../actions/teams';


class TeamUrlEditor extends Component {
  state = {
    editable: false,
  };

  componentDidUpdate(prevProps) {
    const { editTeamUrlStatus } = this.props;
    if (prevProps.editTeamUrlStatus === 'request' && editTeamUrlStatus === 'success') {
      this.closeUrlEditor();
    }
  }

  closeUrlEditor = () => {
    this.setState({ editable: false });
  };

  toggleEditable = () => {
    this.setState(state => ({ editable: !state.editable }));
  };

  handleSubmit = (values) => {
    const { editTeamUrl, id } = this.props;
    editTeamUrl(id, values.teamUrl);
  };

  submitForm = () => {
    const { submitForm, id } = this.props;
    submitForm(id);
  };

  render() {
    const { editable } = this.state;
    const { policyUrl, id } = this.props;
    return editable
      ? (
        <React.Fragment>
          <div className="team-url-editor-container">
            <ChangeTeamUrlForm
              form={`changeTeamUrl-${id}`}
              onSubmit={this.handleSubmit}
              initialValues={{ teamUrl: policyUrl }}
            />
            <div className="buttons-circle-container">
              <button
                type="button"
                className="button-circle button-circle_color_light-green"
                onClick={this.submitForm}
              >
                <FontAwesomeIcon icon="check" />
              </button>
              <button
                type="button"
                className="button-circle button-circle_color_dark-blue"
                onClick={this.toggleEditable}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            </div>
          </div>
          <div className="team-url-editor-container-description">
            Изначально используется соглашение с сайта
            {' '}
            <a href="https://jobswidget.me/static/pdf/contest-to-personal-data-processing.pdf">jobswidget.me</a>
          </div>
        </React.Fragment>
      )
      : (
        <div className="team-url-editor-container">
          <h4 className="team-url-editor-container-title">
            Ссылка на соглашение о персональных данных
          </h4>
          <button
            type="button"
            className="button-circle team-url-editor-container-button-right"
            onClick={this.toggleEditable}
          >
            <FontAwesomeIcon
              className="item-icon"
              icon="pencil-alt"
            />
          </button>
        </div>
      );
  }
}

TeamUrlEditor.defaultProps = {
  editTeamUrlStatus: '',
};

TeamUrlEditor.propTypes = {
  id: PropTypes.number.isRequired,
  policyUrl: PropTypes.string.isRequired,
  editTeamUrl: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  editTeamUrlStatus: PropTypes.string,
};

const mapStateToProps = state => ({
  editTeamUrlStatus: state.api.editTeamUrlStatus,
});

const mapDispatchToProps = dispatch => ({
  editTeamUrl: (teamId, policyUrl) => dispatch(editTeamUrl(teamId, policyUrl)),
  submitForm: id => dispatch(submit(`changeTeamUrl-${id}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamUrlEditor);
