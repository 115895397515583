import React from 'react';
import PropTypes from 'prop-types';

import HiringProcessItem from './HiringProcessItem';
import withPagination from '../containers/withPagination';


const HiringTemplatesList = (props) => {
  const {
    items: templates, deleteHiringTemplate, defaultTemplate, teamId,
  } = props;
  const templatesContainer = templates.map(template => (
    <HiringProcessItem
      key={template.id}
      teamId={teamId}
      template={template}
      defaultTemplate={defaultTemplate}
      deleteHiringTemplate={deleteHiringTemplate}
    />
  ));
  return (
    <div className="content-container content-container_padding_sm">
      {templatesContainer.length
        ? templatesContainer
        : <div className="content-container__empty">Нет доступных сценариев найма</div>
      }
    </div>
  );
};

HiringTemplatesList.defaultProps = {
  defaultTemplate: null,
  teamId: null,
};


HiringTemplatesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteHiringTemplate: PropTypes.func.isRequired,
  defaultTemplate: PropTypes.number,
  teamId: PropTypes.number,
};

export default withPagination(HiringTemplatesList, 'hiring_templates');
