import React from 'react';
import PropTypes from 'prop-types';
import {
  isDirty,
  reduxForm,
} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import validateHiringTemplateForm from './validate/validateHiringTemplateForm';
import EntitiesEditContainer from '../containers/EntitiesEditContainer';
import { TOOLTIP_TEXTS } from '../utils';
import EditSources from '../components/EditSources';
import { createSource, deleteSource, editSource } from '../actions/sources';


const SourcesForm = (props) => {
  const {
    handleSubmit,
    dirty,
    submitSucceeded,
    responses,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={dirty && !submitSucceeded}
        message="Изменения источников отклика не сохранены. Вы уверены, что хотите покинуть страницу?"
      />
      <EntitiesEditContainer
        responses={responses}
        labelName="Название источника"
        newLabelName="Название нового источника"
        fieldName="sources"
        ItemComponent={EditSources}
        title="Редактирование источника"
        addText="Добавить новый источник"
        onEdit={{
          createItem: createSource,
          editItem: editSource,
          deleteItem: deleteSource,
        }}
        filterFunction={value => value}
        tooltipText={TOOLTIP_TEXTS.editingHiringTemplateState}
        isAlwaysOpen
      />
    </form>
  );
};

SourcesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const {
    responses: { responses },
  } = state;
  const dirty = isDirty('editSourcesForm')(state);
  return {
    responses,
    dirty,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'editSourcesForm',
    validate: validateHiringTemplateForm,
    enableReinitialize: true,
  }),
)(SourcesForm);
