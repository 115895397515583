import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import VacancyItem from './VacancyItem';
import withPagination from '../containers/withPagination';
import withSelection from '../containers/withSelection';
import ConfirmModal from './ConfirmModal';
import StyledSelect from './StyledSelect';


const FREE_PLAN_LIMIT = 3;

class VacanciesList extends React.Component {
  state = {
    activatedMenu: false,
  };

  componentDidUpdate = (prevProps) => {
    const { selectedItems } = this.props;
    const { activatedMenu } = this.state;

    if (activatedMenu && (!selectedItems || selectedItems.length !== prevProps.selectedItems.length)) {
      this.handleActivatedMenu();
    }
  };

    handleActivatedMenu = () => {
      this.setState(state => ({ activatedMenu: !state.activatedMenu }));
    };

  resetSelection = () => {
    const { resetSelection, selectedItems } = this.props;
    if (selectedItems && selectedItems.length) {
      resetSelection('selectedVacancies');
    }
  };

  deleteMultipleVacancies = () => {
    const {
      deleteVacancies,
      resetSelection,
      selectedItems,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      deleteVacancies(selectedItems);
      resetSelection('selectedVacancies');
    }
  };

  showInAllVacanciesSwitcher = ({ currentTarget: { checked } }) => {
    const { showInAllVacancies, teamId } = this.props;
    showInAllVacancies(teamId, checked ? (new Date().toISOString()) : null);
  }

  archiveMultipleVacancies = () => {
    const {
      editVacancies,
      resetSelection,
      selectedItems,
      allVacancies,
      teamId,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      const archiveVacancies = selectedItems
        .map(vacancyId => ({
          id: vacancyId,
          hidden: !allVacancies
            .find(vacancy => vacancyId === vacancy.id).hidden,
          is_published: false,
        }));
      editVacancies(teamId, archiveVacancies);
      resetSelection('selectedVacancies');
    }
  };

  publishMultipleVacancies = () => {
    const {
      editVacancies,
      resetSelection,
      selectedItems,
      allVacancies,
      paymentStatus,
      restrictPublishVacancy,
      teamId,
    } = this.props;
    if (selectedItems && selectedItems.length) {
      const publishedVacancies = allVacancies.filter(vacancy => vacancy.is_published);
      const selectedForPublish = allVacancies.filter(
        vacancy => selectedItems.includes(vacancy.id) && !vacancy.is_published,
      );
      const publishRestrict = paymentStatus === 'NOT_PAYED'
          && (publishedVacancies.length + selectedForPublish.length) > FREE_PLAN_LIMIT;
      if (!publishRestrict) {
        const vacanciesList = selectedItems.map((selectedVacancy) => {
          const currentVacancy = allVacancies.find(vacancy => vacancy.id === selectedVacancy);
          return { id: currentVacancy.id, is_published: !currentVacancy.is_published };
        });
        editVacancies(teamId, vacanciesList);
      } else {
        const vacancyErrorId = allVacancies[0].id;
        restrictPublishVacancy(vacancyErrorId);
      }
      resetSelection('selectedVacancies');
    }
  };

  publishLimitHandler = (vacancyId) => {
    const {
      paymentStatus,
      allVacancies,
      editVacancy,
      restrictPublishVacancy,
      teamId,
    } = this.props;
    const publishedVacancies = allVacancies.filter(vacancy => vacancy.is_published);
    const publishRestrict = paymentStatus === 'NOT_PAYED'
          && (publishedVacancies.length + 1) > FREE_PLAN_LIMIT;
    if (!publishRestrict) {
      const id = vacancyId;
      editVacancy({ id, is_published: true, team: teamId });
    } else {
      restrictPublishVacancy(vacancyId);
    }
  }

  render() {
    const {
      editVacancy,
      deleteVacancy,
      teamId,
      lastEditFailedVacancy,
      items: vacancies,
      toggleSelection,
      selectedItems,
      onlyPublished,
      handleSortChange,
      onCheckboxChange,
      sortValue,
      shortId,
      includeInAllVacancies,
      isLoading,
    } = this.props;

    const { activatedMenu } = this.state;

    const selectAllItemsOnPage = () => {
      const vacanciesIds = vacancies.map(vacancy => vacancy.id);
      const notSelectedItemsOnPage = vacanciesIds.filter(id => !selectedItems.includes(id));
      if (notSelectedItemsOnPage.length) {
        notSelectedItemsOnPage.forEach(id => toggleSelection(id));
      } else {
        vacanciesIds.forEach(id => toggleSelection(id));
      }
    };

    const publishVacanciesButton = (
      <>
        <label className="checkbox-container">
          <input
            className="checkbox-hidden"
            name="only-published"
            type="checkbox"
            checked={includeInAllVacancies}
            onChange={this.showInAllVacanciesSwitcher}
            disabled={isLoading || !teamId}
          />
          <span className="checkbox-mark" />


        публиковать на странице всех вакансий
        </label>
      </>
    );

    const selectAllButton = (
      <button
        type="button"
        className="button-add-item span vacancies-icons-wrap-button"
        onClick={selectAllItemsOnPage}
      >
        <span className="button-text">Выбрать все</span>
      </button>
    );

    const multipleChoicePanel = (
      <React.Fragment>
        <ConfirmModal
          type="button-icon"
          buttonText="удалить"
          icon="trash-alt"
          headerText={`При удалении вакансий (${selectedItems.length}), отклики данных вакансий
          также будут удалены. Для сохранения откликов необходимо отправить
          вакансии в архив.`}
          modalAction={this.deleteMultipleVacancies}
        />
        <ConfirmModal
          type="button-icon"
          icon="share"
          buttonText="опубликовать"
          headerText={`Опубликовать выбранные вакансии (${selectedItems.length})?`}
          modalAction={this.publishMultipleVacancies}
        />
        <ConfirmModal
          type="button-icon"
          icon="archive"
          buttonText="архивировать"
          headerText={`Архивировать выбранные вакансии (${selectedItems.length})?`}
          modalAction={this.archiveMultipleVacancies}
        />
      </React.Fragment>
    );
    const sortOptions = [
      { label: 'по дате', value: 'date' },
      { label: 'по количеству откликов', value: 'responses' },
    ];
    const vacanciesPageSort = (
      <React.Fragment>
        <label className="checkbox-container">
          <input
            className="checkbox-hidden"
            name="only-published"
            type="checkbox"
            checked={onlyPublished}
            onChange={onCheckboxChange}
          />
          <span className="checkbox-mark" />
          только опубликованные
        </label>
        <StyledSelect
          options={sortOptions}
          value={sortValue}
          onChange={handleSortChange}
          isSearchable={false}
        />
      </React.Fragment>
    );

    const isVacanciesNotEmpty = vacancies && vacancies.length;

    const vacancyItems = isVacanciesNotEmpty
      ? vacancies
        .filter(vacancy => !vacancy.deleted)
        .map(vacancy => (
          <VacancyItem
            key={vacancy.id}
            vacancy={vacancy}
            editVacancy={editVacancy}
            publishVacancy={this.publishLimitHandler}
            deleteVacancy={deleteVacancy}
            lastEditFailedVacancy={lastEditFailedVacancy}
            toggleSelection={toggleSelection}
            isSelected={selectedItems.includes(vacancy.id)}
            teamId={teamId}
          />
        ))
      : (
        <div className="content-container__empty">
          {teamId
            ? 'На данный момент нет открытых вакансий'
            : 'Создайте компанию для которой вы хотите создать вакансии в разделе "Компании"'}
        </div>
      );

    return (
      <React.Fragment>
        <div className="vacancies-page-sort">
          <div className="d-flex align-items-center vacancies-page-selected-actions">
            <div className="button-select-all">
              {selectAllButton}
            </div>
            {selectedItems.length !== 0 && (
              <React.Fragment>
                <span className="icons-wrap-button multiple-select">
                  {selectedItems.length}
                  {' '}
                выбрано
                </span>
                <button
                  type="button"
                  className="button-add-item span button-multiple"
                  onClick={this.resetSelection}
                >
                  <FontAwesomeIcon
                    className="button-text"
                    icon="times"
                  />
                </button>
                <div className="mobile-button-multiple-menu">
                  <button
                    type="button"
                    className="button-add-item span"
                    onClick={this.handleActivatedMenu}
                  >
                    <span className="vacancy-item-description-elem">действия</span>
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="ellipsis-v"
                    />
                  </button>
                  <Collapse
                    isOpen={activatedMenu}
                    className="mobile-multiple-menu"
                  >
                    {selectAllButton}
                    {multipleChoicePanel}
                  </Collapse>
                </div>
              </React.Fragment>
            )}
          </div>
          {publishVacanciesButton}
          <div className="vacancies-page-sort-container">
            {vacanciesPageSort}
          </div>
        </div>
        {selectedItems.length !== 0 && (
          <div className="vacancies-page-multiple">
            {multipleChoicePanel}
          </div>
        )}
        <div className="vacancies-list content-container content-container_padding_sm">
          {vacancyItems}
        </div>
        <Link to={`/teams/${shortId}/archive/`}>
          <button
            type="button"
            className="button-add-item span vacancies-icons-wrap-button archive-vacancy-button"
          >
            <span className="button-text">Архив вакансий</span>
          </button>
        </Link>
      </React.Fragment>
    );
  }
}

VacanciesList.defaultProps = {
  lastEditFailedVacancy: null,
  teamId: null,
  shortId: null,
  paymentStatus: null,
};

VacanciesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  editVacancies: PropTypes.func.isRequired,
  deleteVacancies: PropTypes.func.isRequired,
  editVacancy: PropTypes.func.isRequired,
  deleteVacancy: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  lastEditFailedVacancy: PropTypes.number,
  toggleSelection: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  resetSelection: PropTypes.func.isRequired,
  onlyPublished: PropTypes.bool.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  sortValue: PropTypes.object.isRequired,
  allVacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  paymentStatus: PropTypes.string,
  restrictPublishVacancy: PropTypes.func.isRequired,
  shortId: PropTypes.string,
  showInAllVacancies: PropTypes.func.isRequired,
  includeInAllVacancies: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withPagination(withSelection(VacanciesList, { name: 'selectedVacancies' }), 'vacancies');
