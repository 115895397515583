import {
  CREATE_SOURCES_SUCCESS,
  DELETE_SOURCES_SUCCESS,
  EDIT_SOURCES_SUCCESS,
  GET_SOURCES_SUCCESS,
} from '../constants/sourcesConst';
import { createReducer } from '../utils/reducerUtils';


const initialState = [];

const getSources = (state, { data }) => [...data];

const createSource = (state, { data }) => [...state, { ...data }];

const deleteSource = (state, { id: deletedSourceId }) => state.filter(({ id }) => id !== deletedSourceId);

const editSource = (state, { data }) => {
  const { id: editedSourceId } = data;
  const editedSources = state.map(source => (source.id === editedSourceId ? data : source));
  return [...editedSources];
};

export default createReducer(initialState, {
  [GET_SOURCES_SUCCESS]: getSources,
  [CREATE_SOURCES_SUCCESS]: createSource,
  [DELETE_SOURCES_SUCCESS]: deleteSource,
  [EDIT_SOURCES_SUCCESS]: editSource,
});
