import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderTeamNameField from './render/renderTeamNameField';


const ChangeTeamNameForm = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="teamName"
        type="text"
        component={renderTeamNameField}
        label="Название компании"
      />
    </form>
  );
};

ChangeTeamNameForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm()(ChangeTeamNameForm);
