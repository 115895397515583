import PropTypes from 'prop-types';
import React from 'react';
import transparent_preloader from '../assets/img/transparent_preloader.gif';


const Spinner = ({ spinnerType }) => (
  <div className={`${spinnerType === 'center'
    ? 'loader'
    : 'transparent_loader'
  }`}
  >
    <img
      className="loader__gif"
      src={transparent_preloader}
      alt="Загрузка..."
    />
  </div>
);

Spinner.defaultProps = {
  spinnerType: '',
};

Spinner.propTypes = {
  spinnerType: PropTypes.string,
};

export default Spinner;
