import { arrayRemove, arrayInsert } from 'redux-form';
import axios from '../api';

import {
  CREATE_FIELD_FAIL,
  CREATE_FIELD_REQUEST,
  CREATE_FIELD_SUCCESS,
  EDIT_FIELD_SUCCESS,
  EDIT_FIELD_FAIL,
  EDIT_FIELD_REQUEST,
  DELETE_FIELD_SUCCESS,
  DELETE_FIELD_FAIL,
  DELETE_FIELD_REQUEST,
  GET_FIELDS_FAIL,
  GET_FIELDS_REQUEST,
  GET_FIELDS_SUCCESS,
} from '../constants/fieldConst';


export const getFields = team => ({
  name: 'fields',
  types: [GET_FIELDS_REQUEST, GET_FIELDS_SUCCESS, GET_FIELDS_FAIL],
  callApi: () => axios.get(`/team/${team}/fields/`),
});

export const createField = field => ({
  name: 'field',
  types: [CREATE_FIELD_REQUEST, CREATE_FIELD_SUCCESS, CREATE_FIELD_FAIL],
  payload: { id: -1 },
  callApi: () => axios.post(`/team/${field.team}/fields/`, field),
});

export const editField = field => ({
  name: 'field',
  types: [EDIT_FIELD_REQUEST, EDIT_FIELD_SUCCESS, EDIT_FIELD_FAIL],
  payload: { id: field.id },
  callApi: () => axios.patch(`/team/${field.team}/fields/`, field),
  afterSuccessCall: field.newValue === null
    ? () => {}
    : (_, dispatch) => {
      dispatch(arrayRemove('createFieldSet', 'fields', field.indexInForm));
      dispatch(arrayInsert('createFieldSet', 'fields', field.indexInForm, field.newValue));
    },
});

export const deleteField = field => ({
  name: 'field',
  types: [DELETE_FIELD_REQUEST, DELETE_FIELD_SUCCESS, DELETE_FIELD_FAIL],
  payload: { id: field.id },
  callApi: () => axios.delete(`/team/${field.team}/fields/`, { data: { ...field } }),
  afterSuccessCall: field.indexInForm === -1
    ? () => {}
    : (_, dispatch) => dispatch(arrayRemove('createFieldSet', 'fields', field.indexInForm)),
});
