import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withPagination from '../containers/withPagination';
import AllVacanciesItem from './AllVacanciesItem';


class AllVacanciesList extends Component {
  shouldComponentUpdate(nextProps) {
    const { items } = this.props;
    if (items !== nextProps.items) {
      return true;
    }

    return false;
  }

  render() {
    const { items } = this.props;

    const renderVacancies = items
      ? items.map((vacancy) => {
        const {
          id,
        } = vacancy;

        return (
          <AllVacanciesItem
            key={id}
            vacancy={vacancy}
          />
        );
      }) : (
        <div>
          Вакансий не найдено
        </div>
      );

    return (
      <>
        <div className="center">
          {renderVacancies}
        </div>
      </>
    );
  }
}

AllVacanciesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withPagination(AllVacanciesList, 'allVacancies');
