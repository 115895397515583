import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import renderSelect from './renderSelect';
import { TOOLTIP_TEXTS } from '../../utils';


const renderQuestions = (props) => {
  const {
    fields,
    change,
    questions,
    questionsData,
  } = props;

  const questionAdded = questionsData
    .filter(question => question)
    .map(({ title: { value } }) => value);

  const questionOptions = questions
    .filter(({ id }) => !questionAdded.includes(id))
    .map(({ title, id }) => ({ label: title, value: id }));

  const handleSelectorOnChange = (field, event) => {
    const { message } = questions.find(question => event.value === question.id);
    change(`${field}.message`, message);
  };

  return (
    <div className="vacancy-questions vacancy-form__questions">
      <div className="title vacancy-questions__title">Вопросы</div>
      {fields.map((field, index) => (
        <div className="vacancy-questions__item" key={field}>
          <div className="vacancy-questions__field">
            <Field
              type="text"
              label="Вопрос"
              tooltipText={TOOLTIP_TEXTS.question}
              name={`${field}.title`}
              component={renderSelect}
              options={questionOptions}
              noOptionsMessage="Нет доступных вопросов"
              placeholder="Выберите вопрос"
              onChange={event => handleSelectorOnChange(field, event)}
            />
            <div className="vacancy-questions__delete-icon">
              <FontAwesomeIcon
                onClick={() => fields.splice(index, 1)}
                className="item-icon"
                icon="trash-alt"
              />
            </div>
          </div>
          <div className="vacancy-questions__description">
            {questionsData[index] && questionsData[index].message && (
              <div
                className="frame-text"
                dangerouslySetInnerHTML={{ __html: questionsData[index] && questionsData[index].message }}
              />
            )}
          </div>
        </div>
      ))}
      <button
        type="button"
        className="button-add-item vacancy-questions__button"
        onClick={() => fields.push()}
      >
        <span>+</span>
        Добавить вопрос
      </button>
    </div>
  );
};

renderQuestions.propTypes = {
  fields: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default renderQuestions;
