import { combineReducers } from 'redux';

import userReducer from './users';
import invitedUsersReducer from './invitedUsers';
import teamsIdsReducer from './teamsIds';
import teamsReducer from './teams';
import vacanciesReducer from './vacancies';


const entitiesReducer = combineReducers({
  teams: teamsReducer,
  users: userReducer,
  invitedUsers: invitedUsersReducer,
  teamsIds: teamsIdsReducer,
  allVacancies: vacanciesReducer,
});

export default entitiesReducer;
