import {
  validateErrors,
  required,
  maxLength,
  matchPattern,
} from '../validators';


const validate = (values) => {
  const validators = {
    teamUrl: [
      maxLength(300),
      matchPattern(/^(http|https)/, 'Ссылка должна начинаться на http или https'),
      required],
  };
  return validateErrors(values, validators);
};

export default validate;
