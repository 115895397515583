import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  isDirty,
  reduxForm,
  arrayMove,
  formValueSelector,
  change,
} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import renderFieldSet from './render/renderFieldSet';
import renderField from './render/renderField';
import validateFieldSetForm from './validate/validateFieldSetForm';
import StatusMessage from '../containers/StatusMessage';
import history from '../routes/history';
import FieldForm from './FieldForm';
import { TOOLTIP_TEXTS } from '../utils';


const FieldSetForm = (props) => {
  const {
    handleSubmit,
    vacancyFields,
    dirty,
    submitSucceeded,
    initialValuesField,
    onSubmitCreateFieldForm,
    createFieldStatus,
    activeTeamShortId,
    fieldSetId,
    moveItem,
    fieldsValue,
    change,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={!submitSucceeded && dirty}
        message="Изменения набора не сохранены. Вы уверены, что хотите покинуть страницу?"
      />
      <div className="render-field-head">
        <Field
          name="name"
          type="text"
          component={renderField}
          label="Название набора полей"
          tooltipText={TOOLTIP_TEXTS.fieldSetName}
        />
      </div>
      <FieldArray
        name="fields"
        component={renderFieldSet}
        changeFormValue={change}
        vacancyFields={vacancyFields}
        activeTeamShortId={activeTeamShortId}
        fieldSetId={fieldSetId}
        moveItem={moveItem}
        fieldsValue={fieldsValue}
      />
      <div className="buttons-container">
        <button
          type="submit"
          className="button"
          disabled={!dirty}
        >
          Сохранить изменения
        </button>
        {' '}
        <button
          type="button"
          className="button button_color_dark-blue"
          onClick={() => history.push(`/teams/${activeTeamShortId}/field-sets`)}
        >
          Отмена
        </button>
      </div>
      <StatusMessage
        status="editFieldSetStatus"
        successMessage="Набор полей успешно изменен"
        errorMessage="Ошибка, изменения не сохранены"
      />
      <FieldForm
        initialValues={initialValuesField}
        onSubmit={onSubmitCreateFieldForm}
        createFieldStatus={createFieldStatus}
      />
      <StatusMessage
        status="createFieldSetStatus"
        successMessage="Набор полей успешно сохранен"
        errorMessage="Ошибка, набор полей не сохранен"
      />
    </form>
  );
};

FieldSetForm.defaultProps = {
  createFieldStatus: '',
  fieldSetId: 0,
  fieldsValue: null,
};

FieldSetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  vacancyFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  initialValuesField: PropTypes.object.isRequired,
  onSubmitCreateFieldForm: PropTypes.func.isRequired,
  createFieldStatus: PropTypes.string,
  activeTeamShortId: PropTypes.string.isRequired,
  fieldSetId: PropTypes.number,
  moveItem: PropTypes.func.isRequired,
  fieldsValue: PropTypes.arrayOf(PropTypes.object),
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    api: { createFieldStatus },
    app: { activeTeamShortId },
  } = state;

  const selector = formValueSelector('createFieldSet');
  const fieldsValue = selector(state, 'fields');

  const dirty = isDirty('createFieldSet')(state);
  return {
    fieldsValue,
    dirty,
    createFieldStatus,
    activeTeamShortId,
  };
};

const mapDispatchToProps = dispatch => ({
  moveItem: (fromIndex, toIndex) => {
    if (fromIndex > toIndex) {
      dispatch(arrayMove('createFieldSet', 'fields', fromIndex, toIndex));
    } else {
      for (let index = fromIndex; index < toIndex; index += 1) {
        dispatch(arrayMove('createFieldSet', 'fields', index, index + 1));
      }
    }
  },
  change: (fieldName, newValue) => dispatch(change('createFieldSet', fieldName, newValue)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'createFieldSet',
    validate: validateFieldSetForm,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
)(FieldSetForm);
