import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { isEqual } from 'lodash';
import history from '../routes/history';

import StyledSelect from '../components/StyledSelect';
import ReportTable from '../components/ReportTable';
import {
  ASC_SORT_TYPE,
  DESC_SORT_TYPE,
  REPORT_BY_MANAGERS,
  REPORT_BY_SOURCES,
  REPORT_BY_VACANCIES,
} from '../utils/reportUtils';
import { loadReport } from '../actions/responses';
import { sourceTypeTranslator } from '../utils/responsesUtils';


class ReportPage extends Component {
  constructor(props) {
    super(props);
    const initialFromDate = new Date();
    initialFromDate.setDate(1);

    const initialToDate = new Date();
    const {
      searchParams: {
        date_from,
        date_to,
      },
      report,
    } = this.props;

    this.state = {
      report,
      sortType: ASC_SORT_TYPE,
      startInputDate: date_from || initialFromDate.toISOString().split('T')[0],
      endInputDate: date_to || initialToDate.toISOString().split('T')[0],
      reportTypeOptions: [
        { label: 'рекрутерам', value: REPORT_BY_MANAGERS },
        { label: 'вакансиям', value: REPORT_BY_VACANCIES },
        { label: 'источникам', value: REPORT_BY_SOURCES },
      ],
      reportType: { label: 'рекрутерам', value: REPORT_BY_MANAGERS },
    };
  }

  componentDidMount = () => {
    const { teamId, loadReport } = this.props;
    const { reportType: { value } } = this.state;
    loadReport(teamId, value, this.getFilters());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      startInputDate,
      endInputDate,
      reportType,
    } = this.state;

    const { loadReport, teamId, report } = this.props;
    const { report: prevReport } = prevProps;

    const {
      startInputDate: prevStartInputDate,
      endInputDate: prevEndInputDate,
      reportType: prevReportType,
    } = prevState;

    if (!isEqual(report, prevReport)) {
      this.onReportUpdate(report);
    }

    if ((startInputDate !== prevStartInputDate)
      || (endInputDate !== prevEndInputDate)) {
      const searchParams = new URLSearchParams();
      searchParams.append('date_from', startInputDate);
      searchParams.append('date_to', endInputDate);
      history.push({ pathname: '/report', search: `?${searchParams.toString()}` });
      loadReport(teamId, reportType.value, this.getFilters());
    }

    if (reportType.value !== prevReportType.value) {
      loadReport(teamId, reportType.value, this.getFilters());
    }
  }

  removeInitialStatesFromReport = (report) => {
    if (report.length === 0) {
      return report;
    }

    const responseInitialStates = report
      .reduce((acc, { items }) => [...acc, ...items], [])
      .filter(item => item.is_initial_state);

    const initialStatesCounter = responseInitialStates.reduce((acc, { name, amount }) => {
      if (acc.hasOwnProperty(name)) {
        acc[name] += amount;
      } else {
        acc[name] = amount;
      }
      return acc;
    }, {});

    Object.keys(initialStatesCounter).forEach((key) => {
      if (initialStatesCounter[key]) {
        delete initialStatesCounter[key];
      }
    });

    const reportWithoutInitialStates = report.map((item) => {
      const updatedItems = item.items.filter(({ name }) => !Object.keys(initialStatesCounter).includes(name));
      return { ...item, items: updatedItems };
    });

    return reportWithoutInitialStates;
  }

  onReportUpdate = (report) => {
    this.setState({ report: this.removeInitialStatesFromReport(report) });
  }

  getFilters = () => {
    const { startInputDate, endInputDate } = this.state;
    return {
      created__date__gte: startInputDate,
      created__date__lte: endInputDate,
    };
  }

  onSort = (columnName) => {
    const { report, sortType } = this.state;

    const newSortType = sortType === ASC_SORT_TYPE ? DESC_SORT_TYPE : ASC_SORT_TYPE;
    const sortFactor = sortType === ASC_SORT_TYPE ? 1 : -1;

    report.sort((firstUser, secondUser) => {
      const firstUserAmount = firstUser.items.find(({ name }) => name === columnName).amount;
      const secondUserAmount = secondUser.items.find(({ name }) => name === columnName).amount;
      return (firstUserAmount < secondUserAmount ? -1 : 1) * sortFactor;
    });

    this.setState({
      report,
      sortType: newSortType,
    });
  }

  getSourceName = ({ source, internal_source }) => {
    if (source) {
      return source.name;
    }

    if (internal_source) {
      return sourceTypeTranslator[internal_source];
    }

    return 'Не указано';
  }

  getSourceId = source => (source && source.id) || null;

  onInputChange = (newDate, name) => {
    const dateValue = newDate.toISOString().split('T')[0];
    this.setState({ [`${name}InputDate`]: dateValue });
  }

  onDateSelect = (newDate, name) => {
    const dateValue = newDate.toISOString().split('T')[0];
    this.setState({ [`${name}InputDate`]: dateValue });
  }

  onInputDateFinished = () => {
    const { startInputDate: startValue, endInputDate: endValue } = this.state;

    const [startInputDate] = new Date(startValue).toISOString().split('T');
    const [endInputDate] = new Date(endValue).toISOString().split('T');

    if (endInputDate < startInputDate) {
      this.setState({
        startInputDate: endInputDate,
        endInputDate: startInputDate,
      });
    } else {
      this.setState({
        startInputDate,
        endInputDate,
      });
    }
  }

  onReportTypeChange = (newType) => {
    const { teamId } = this.props;
    loadReport(teamId, newType, this.getFilters());
  }

  render() {
    const {
      startInputDate,
      endInputDate,
      reportTypeOptions,
      reportType,
      report,
      sortType,
    } = this.state;

    const {
      activeTeamShortId,
    } = this.props;

    return (
      <div className="responses-wrap section-grey report-page">
        <Helmet>
          <title>Отчётность | Jobs Widget</title>
        </Helmet>
        <div className="page-header-container">
          <h1 className="main-page-title">Отчётность по</h1>
          <StyledSelect
            options={reportTypeOptions}
            isSearchable={false}
            value={reportType}
            className="report-page-filter-type-selector"
            onChange={(newType) => {
              this.setState({ reportType: newType });
            }}
          />
        </div>
        <div className="responses-page-filter-container">
          <div className="date-filter-container">
            <h4>
              Период:
            </h4>
            <div className="date-filter-container-decorator">
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                className="form-control date-response-filter"
                selected={new Date(startInputDate)}
                onChange={newDate => this.onInputChange(newDate, 'start')}
                onBlur={() => this.onInputDateFinished('start')}
                onSelect={newDate => this.onDateSelect(newDate, 'start')}
              />
              <span className="date-filter-dash">
                &#8212;
              </span>
              <DatePicker
                locale={ru}
                dateFormat="dd.MM.yyyy"
                className="form-control date-response-filter"
                selected={new Date(endInputDate)}
                onChange={newDate => this.onInputChange(newDate, 'end')}
                onBlur={() => this.onInputDateFinished('end')}
                onSelect={newDate => this.onDateSelect(newDate, 'end')}
              />
            </div>
          </div>
        </div>
        {activeTeamShortId
          ? (
            <ReportTable
              report={report}
              activeTeamShortId={activeTeamShortId}
              startInputDate={new Date(startInputDate)}
              endInputDate={new Date(endInputDate)}
              onSort={this.onSort}
              reportType={reportType.value}
              sortType={sortType}
            />
          ) : (
            <div className="content-container content-container_padding_sm">
              <div className="content-container__empty">
                Создайте компанию для которой вы хотите создать отчётность в разделе "Компании"
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

ReportPage.defaultProps = {
  teamId: null,
};

ReportPage.propTypes = {
  searchParams: PropTypes.object.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
  teamId: PropTypes.number,
  loadReport: PropTypes.func.isRequired,
  report: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    report,
    app: { activeTeamShortId, isLoading, activeTeam },
  } = state;
  const { location } = ownProps;
  const locationSearch = new URLSearchParams(location.search);
  const searchParams = {};
  searchParams.status = +locationSearch.get('status') || 0;
  searchParams.date_from = locationSearch.get('date_from') || null;
  searchParams.date_to = locationSearch.get('date_to') || null;

  return {
    report,
    searchParams,
    teamId: activeTeam,
    activeTeamShortId,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  loadReport: (teamId, reportType, filters) => dispatch(loadReport(teamId, reportType, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
