import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import renderField from './renderField';
import renderSelect from './renderSelect';
import FieldError from '../../components/FieldError';
import { TOOLTIP_TEXTS } from '../../utils';


const renderActions = (props) => {
  const {
    fields,
    meta: { error },
    options,
    actionSetupIndex,
  } = props;

  const removeItem = (index) => {
    if (index) {
      fields.splice(index, 1);
    }
  };

  const changeItem = (index) => {
    if (index) {
      fields.getAll().filter((field, fieldIndex) => fieldIndex === index || fieldIndex === index - 1)
        .forEach((field, fieldIndex) => {
          fields.splice(index - fieldIndex, 1, field);
        });
    }
  };

  return (
    <div>
      <div>
        {fields.map((action, index) => (
          <div
            key={action}
            className="render-actions-container"
          >
            <Field
              name={`${action}.to_state`}
              type="text"
              label="Куда"
              tooltipText={actionSetupIndex === 0 && index === 0 ? TOOLTIP_TEXTS.hiringTemplateTo : undefined}
              component={renderSelect}
              options={options}
              placeholder="Выберите состояние"
            />
            <Field
              name={`${action}.button_name`}
              type="text"
              label="Название кнопки"
              tooltipText={actionSetupIndex === 0 && index === 0 ? TOOLTIP_TEXTS.hiringTemplateButton : undefined}
              component={renderField}
            />
            {index !== 0 && (
              <React.Fragment>
                <span className="icon-wrap render-actions-change">
                  <FontAwesomeIcon
                    onClick={() => changeItem(index)}
                    className="item-icon"
                    icon="exchange-alt"
                    title="Поменять кнопки местами"
                  />
                </span>
                <span className="icon-wrap render-actions-delete">
                  <FontAwesomeIcon
                    onClick={() => removeItem(index)}
                    className="item-icon"
                    icon="trash-alt"
                  />
                </span>
              </React.Fragment>
            )}
          </div>
        ))}
      </div>
      {error && <FieldError error={error} />}
      <button type="button" className="button-add-item" onClick={() => fields.push({})}>
        <span>+</span>
        Добавить переход
      </button>
    </div>
  );
};

renderActions.propTypes = {
  fields: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta: PropTypes.object.isRequired,
  actionSetupIndex: PropTypes.number.isRequired,
};

export default renderActions;
