import React from 'react';
import PropTypes from 'prop-types';

import withPagination from '../containers/withPagination';
import TeamContainer from './TeamContainer';


const TeamsList = (props) => {
  const {
    currentUser,
    teams,
    users,
    invitedUsers,
    items: teamsIds,
    sendInvite,
    removeUserFromTeam,
    inviteStatus,
    deleteTeam,
    toggleAdminRights,
    deleteInvite,
  } = props;
  const teamsContainer = teamsIds.map(teamId => (
    <TeamContainer
      key={teamId}
      currentUser={currentUser}
      team={teams[teamId]}
      users={users}
      invitedUsers={invitedUsers}
      sendInvite={sendInvite}
      deleteInvite={deleteInvite}
      removeUserFromTeam={removeUserFromTeam}
      inviteStatus={inviteStatus}
      deleteTeam={deleteTeam}
      toggleAdminRights={toggleAdminRights}
    />
  ));
  return (
    <div className="content-container-list">
      {teamsContainer.length
        ? teamsContainer
        : (
          <div className="content-container">
            <div className="content-container__empty">Список компаний пуст</div>
          </div>
        )
      }
    </div>
  );
};

TeamsList.propTypes = {
  currentUser: PropTypes.number.isRequired,
  teams: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  invitedUsers: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.number).isRequired,
  sendInvite: PropTypes.func.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  removeUserFromTeam: PropTypes.func.isRequired,
  inviteStatus: PropTypes.string.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  toggleAdminRights: PropTypes.func.isRequired,
};

export default withPagination(TeamsList, 'teams');
