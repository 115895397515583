import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import dateformat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ChangeStatusButtons from './ChangeStatusButtons';
import StyledSelect from './StyledSelect';
import { HAND_WRITTEN_SOURCE_TYPE, sourceTypeTranslator } from '../utils/responsesUtils';


const ApplicantStatus = (props) => {
  const {
    response: {
      status,
      created,
      id: responseId,
      source,
      internal_source,
    },
    vacancy: { id: currentVacancyId, title: currentVacancyTitle },
    hiringTemplate,
    vacancies,
    changeStatus,
    moveResponse,
    teamId,
  } = props;

  const vacanciesOptions = vacancies
    .map(({ id, title }) => ({ label: title, value: id }))
    .filter(({ value }) => value !== currentVacancyId);

  const [selectedVacancy, setSelectedVacancy] = useState(vacanciesOptions[0]);
  const [vacancyChangeMenuStatus, setVacancyChangeMenuStatus] = useState(false);

  const getCurrentSource = (source, internal_source) => {
    if (internal_source === HAND_WRITTEN_SOURCE_TYPE && source) {
      return source.name;
    }

    return sourceTypeTranslator[internal_source];
  };

  const editResponseHandler = () => {
    moveResponse(teamId, responseId, currentVacancyId, selectedVacancy.value);
  };

  const currentSource = getCurrentSource(source, internal_source);

  const isCurrentVacancySelected = selectedVacancy && currentVacancyId === selectedVacancy.value;
  return (
    <React.Fragment>
      <div className="response-info">
        <Row className="response-info__item">
          <Col lg="4" className="response-info__label label vacancy-title">
            <div className="application-status">
              <div>
                Вакансия
              </div>
              <FontAwesomeIcon
                className="item-icon application-status__reset-button align-self-center"
                icon="pencil-alt"
                title="Изменить вакансию отклика"
                onClick={() => setVacancyChangeMenuStatus(!vacancyChangeMenuStatus)}
              />
            </div>
          </Col>
          <Col lg="8" className="response-info__value vacancy-title">
            {vacancyChangeMenuStatus
              ? (
                <div className="response-info__vacancy-change-menu-wrapper">
                  <FontAwesomeIcon
                    icon={`${isCurrentVacancySelected ? 'check' : 'angle-double-right'}`}
                    color={`${isCurrentVacancySelected && 'rgb(38, 185, 161)'}`}
                    onClick={editResponseHandler}
                    className={`response-info__move-response-btn ${isCurrentVacancySelected ? 'icon-green' : ''}`}
                    title={`${isCurrentVacancySelected
                      ? 'Выбрана текущая вакансия отклика'
                      : 'Переместить вакансию'
                    }`}
                  />
                  <StyledSelect
                    options={vacanciesOptions}
                    value={selectedVacancy}
                    defaultValue={{ value: null, label: 'Вакансии отсутствуют' }}
                    className="response-info__vacancy-change-menu"
                    onChange={newValue => setSelectedVacancy(newValue)}
                  />
                </div>
              ) : (
                currentVacancyTitle
              )
            }
          </Col>
        </Row>
        <Row className="response-info__item">
          <Col lg="4" className="response-info__label label">Дата отклика</Col>
          <Col lg="8" className="response-info__value">
            {dateformat(created.toString(), 'dd.mm.yyyy')}
          </Col>
        </Row>
        {internal_source && (
        <Row className="response-info__item">
          <Col lg="4" className="response-info__label label">Источник</Col>
          <Col lg="8" className="response-info__value">{currentSource}</Col>
        </Row>
        )}
        <Row className="response-info__item">
          <Col lg="4" className="response-info__label label">
            <div className="application-status">
              <div>
                Текущий статус кандидата
              </div>
              <FontAwesomeIcon
                onClick={() => changeStatus(hiringTemplate.initial_state)}
                className="item-icon application-status__reset-button align-self-center"
                icon="undo"
                title="Сбросить статус"
              />
            </div>
          </Col>
          <Col lg="8" className="response-info__value align-self-center">
            {status}
          </Col>
        </Row>
      </div>
      <div className="response-tags">
        <ChangeStatusButtons
          status={status}
          hiringTemplate={hiringTemplate}
          changeStatus={changeStatus}
        />
      </div>
    </React.Fragment>
  );
};

ApplicantStatus.propTypes = {
  vacancy: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  response: PropTypes.shape({
    created: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  hiringTemplate: PropTypes.object.isRequired,
  changeStatus: PropTypes.func.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
  moveResponse: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default ApplicantStatus;
