import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';


class FieldSetItem extends React.Component {
  handleDelete = () => {
    const {
      deleteFieldSet,
      fieldSet: { id },
      activeTeam,
    } = this.props;
    deleteFieldSet(activeTeam, id);
  };

  render() {
    const {
      fieldSet: { id, name },
      defaultFieldSet,
      activeTeamShortId,
    } = this.props;
    return (
      <div key={id} className={`content-container__item content-container__flex-between-center scroll-position-${id}`}>
        <Link className="field-sets-item-link" to={`/teams/${activeTeamShortId}/field-sets/${id}`}>{name}</Link>
        {' '}
        {id !== defaultFieldSet && (
          <ConfirmModal
            type="icon"
            headerText="Удалить набор полей?"
            modalAction={this.handleDelete}
            icon="trash-alt"
          />
        )}
      </div>
    );
  }
}

FieldSetItem.propTypes = {
  fieldSet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  deleteFieldSet: PropTypes.func.isRequired,
  defaultFieldSet: PropTypes.number.isRequired,
  activeTeam: PropTypes.number.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
};

export default FieldSetItem;
