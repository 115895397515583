import React from 'react';
import PropTypes from 'prop-types';

import FieldSetItem from './FieldSetItem';
import withPagination from '../containers/withPagination';


const FieldSetsList = (props) => {
  const {
    items: fieldSets,
    deleteFieldSet,
    defaultFieldSet,
    activeTeam,
    activeTeamShortId,
  } = props;
  const fieldSetsContainer = fieldSets.map(fieldSet => (
    <FieldSetItem
      key={fieldSet.id}
      fieldSet={fieldSet}
      deleteFieldSet={deleteFieldSet}
      defaultFieldSet={defaultFieldSet}
      activeTeam={activeTeam}
      activeTeamShortId={activeTeamShortId}
    />
  ));
  return (
    <div className="content-container content-container_padding_sm">
      {fieldSetsContainer.length
        ? fieldSetsContainer
        : <div className="content-container__empty">Нет доступных наборов полей</div>
      }
    </div>
  );
};

FieldSetsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteFieldSet: PropTypes.func.isRequired,
  defaultFieldSet: PropTypes.number.isRequired,
  activeTeam: PropTypes.number.isRequired,
  activeTeamShortId: PropTypes.string.isRequired,
};

export default withPagination(FieldSetsList, 'fieldsets');
