import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const TooltipComponent = (props) => {
  const {
    text,
    name,
    className,
    wrapperClassName,
    toolTipWindowSize,
    toolTipArrowPlacement,
  } = props;

  return (
    <div className={`tooltip-custom ${wrapperClassName}`}>
      <FontAwesomeIcon
        id={name}
        className={`item-icon tooltip-custom__icon ${className}`}
        icon="question-circle"
      />
      <UncontrolledTooltip
        autohide={false}
        placement={toolTipArrowPlacement}
        target={name}
        innerClassName={`tooltip-custom__window tooltip-custom__window_${toolTipWindowSize}`}
        arrowClassName={`tooltip-custom__arrow_${toolTipArrowPlacement}`}
      >
        {text}
      </UncontrolledTooltip>
    </div>
  );
};

TooltipComponent.defaultProps = {
  className: '',
  wrapperClassName: '',
  toolTipWindowSize: '',
  toolTipArrowPlacement: '',
};

TooltipComponent.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  toolTipWindowSize: PropTypes.string,
  toolTipArrowPlacement: PropTypes.string,
};

export default TooltipComponent;
